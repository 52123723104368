import axios from 'axios';
import store from '@/store';

export default class {
  baseUrl = '/api/statuses';

  get = () => new Promise(() => {
    axios.get(this.baseUrl)
      .then(({ data }) => store.dispatch('setStatuses', data.statuses));
  });
}
