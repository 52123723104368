import axios from 'axios';

export default class {
    baseUrl = '/api/dpd-pop';

    getDpdPops = (searchQuery) => new Promise((resolve) => {
        axios.get(this.baseUrl, {
            params: { searchQuery }
        }).then(({ data }) => {
            resolve(
                data.map(el => ({
                        id: el.pudoId,
                        label: `${el.address1} ${el.city}, ${el.zipCode} ${el.city} ${el.name} (${el.pudoId})`
                    })
                )
            );
        });
    });
}
