// @/mixins/PaymentsMixin
import ApiPayments from '@/api/Payments';

const apiPayments = new ApiPayments();

import { mapGetters } from 'vuex';

export default {
  data: () => ({
    loaderPayments: false
  }),

  computed: {
    ...mapGetters([
      'PAYMENTS',
      'PAYMENT'
    ]),
  },

  created () {
    if (this.PAYMENTS.length < 1) {
      this.getPayments();
    }
  },

  methods: {
    getPayments () {
      this.loaderPayments = true;
      apiPayments.getActive().finally(() => this.loaderPayments = false);
    }
  }
};
