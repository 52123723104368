// @/mixins/StatusesMixin

import ApiStatuses from '@/api/Statuses';

const apiStatuses = new ApiStatuses();

import { mapGetters } from 'vuex';

export default {
    data: () => ({
        loaderStatuses: false
    }),

    computed: {
        ...mapGetters([
            'STATUSES',
            'STATUS'
        ]),

        isStatusSale: function () {
            return (status) => {
                if (!status) return false;
                if (!status._id) return false;
                return this.STATUS(status._id).isSale;
            };
        },
        isStatusContactLater: function () {
            return (status) => {
                if (!status) return false;
                if (!status._id) return false;
                const currentStatus = this.STATUS(status._id);
                return currentStatus.contactLater || currentStatus.contactLaterNotRequired;
            };
        },

        statusColor: () => function (statusId) {
            const status = this.STATUS(statusId);
            if (!status) return '';

            if (status.isSale) return 'green--text';
            if (status.closeCustomer) return 'red--text';
            if (status.contactLater) return 'black--text';
            if (status.closeRecord) return 'blue--text';

            return '';
        }
    },

    created () {
        if (this.STATUSES.length < 1) {
            this.getStatuses();
        }
    },

    methods: {
        getStatuses () {
            this.loaderStatuses = true;
            apiStatuses.get().finally(() => this.loaderStatuses = false);
        }
    }
};
