export default {
  state: {
    error: null
  },
  getters: {
    GET_ERROR: state => state.error,
  },
  mutations: {
    ASSIGN_ERROR: (state, payload) => {
      state.error = payload;
    },
  },
  actions: {
    assignError: ({ commit }, payload) => {
      commit('ASSIGN_ERROR', payload);
    }
  }
};
