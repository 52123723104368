<template>
  <div class="_wrap">
    <h5>
      pobierz zamówienia <br> do akceptacji
    </h5>

    <v-text-field label="iłość"
                  v-model.number="quantity"/>

    <v-btn @click.prevent="download()"
           :loading="loading"
           :disabled="quantity < 1">
      pobierz
    </v-btn>

    <v-snackbar v-model="hasErrors"
                :multi-line="true">

      <p v-for="(error, index) in errors"
         :key="`error${index}`"
         :class="{'mb-0': index===errors.length-1}"
         v-html="`${error.key} - ${error.msg}`"/>

      <template v-slot:action="{ attrs }">
        <v-btn color="red"
               text
               v-bind="attrs"
               @click="errors = []">
          pobierz
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import ReportsApi from '@/api/Reports';
  import { prepareCsvToDownload } from '@/utils/csvWriter';
  import ErrorsMixin from '@/mixins/ErrorsMixin';

  export default {
    name: 'AdminDownloadOrdersToAccept',
    mixins: [
      ErrorsMixin,
    ],
    data: () => ({
      loading: false,
      quantity: 100,
    }),
    methods: {
      download () {
        if (this.quantity < 1) {
          this.errors.push({
            key: 'błąd',
            msg: 'wystąpił błąd z pobraniem raportu <br> wpisz iłość'
          });
          return;
        }

        const reportsApi = new ReportsApi();

        reportsApi.get('orders-to-accept', { quantity: this.quantity })
            .then(response => prepareCsvToDownload(response, 'zamowienia-do-akceptacji.csv'))
            .catch(() => {
              this.errors.push({
                key: 'błąd',
                msg: 'wystąpił błąd z pobraniem raportu'
              });
            })
            .finally(() => this.loading = false);
      }
    },
  };
</script>

<style scoped lang="scss">
  ._wrap {
    display: flex;
    gap: 15px;
    align-items: center;
  }
</style>