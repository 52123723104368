import axios from 'axios';
import store from '@/store';

export default class {
  baseUrl = '/api/countries';

  get = () => new Promise(() => {
    axios.get(this.baseUrl)
      .then(({ data }) => store.dispatch('setCountries', data.countries));
  });

  getActive = () => new Promise(() => {
    axios.get(this.baseUrl, {params: { isActive: true }})
      .then(({ data }) => store.dispatch('setCountries', data.countries));
  });
}
