import { render, staticRenderFns } from "./UserTemplate.vue?vue&type=template&id=a03c2830&scoped=true&"
import script from "./UserTemplate.vue?vue&type=script&lang=js&"
export * from "./UserTemplate.vue?vue&type=script&lang=js&"
import style0 from "./UserTemplate.vue?vue&type=style&index=0&id=a03c2830&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a03c2830",
  null
  
)

export default component.exports