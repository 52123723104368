import _ from 'lodash';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { checkUserData } from '@/helpers/user';
import store from '@/store';

Vue.use(VueRouter);
/* *** import routes *** */
import Auth from './_auth';
import User from './user';
import Orders from './orders';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
    meta: {
      requiresAuth: false
    }
  },
  ...Auth,
  ...User,
  ...Orders,
  {
    path: '*',
    component: () => import('@/views/errors/NotFound.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  await checkUserData(next);

  if (to.meta.requiresAuth) {
    if (localStorage.getItem('authToken')) return next();
    localStorage.clear();
    next({
      name: 'Login',
      params: { message: 'You are unauthorized, Please login to access' }
    });
  } else if (!_.isEmpty(to.meta) && !to.meta.requiresAuth) {
    if (localStorage.getItem('authToken') === null) return next();
    next({
      name: 'UserDashboard',
      params: { handle: store.getters.AUTH_USER.id }
    });
  }

  next();
});

export default router;
