<template>
  <v-overlay
      :value="isVisible"
      z-index="9"
  >
    <v-card light class="mw-80wv-i">
      <v-card-title
          class="lighten-3 pt-2 pb-0 blue white--text"
      >
        Zmiana statusu klienta
      </v-card-title>

      <v-card-subtitle
          class="blue-grey lighten-3 pt-2 pr-2 pb-1 mt-0  d-flex justify-space-between"
      >
        <h4>{{ customer.firstName }} {{ customer.lastName }}</h4>
        <span class="white--text" v-if="!!customerMainEmail">
          {{ customerMainEmail.email }}
        </span>
        <span class="white--text" v-if="!!customerMainPhone">
          {{ customerMainPhone.phone }}
        </span>
      </v-card-subtitle>

      <v-card-text class="mh-80vh">
        <CustomerStatusChanger
            :customer="customer"
            :campaign="campaign"
            :set-comment-data="setCommentData"
            :set-selected-campaign="setSelectedCampaign"
        />
      </v-card-text>

      <v-card-actions class="justify-space-between">
        <v-btn
            color="gray lighten-2"
            @click="setIsVisible(false)"
            small
        >
          zamkńij
        </v-btn>

        <v-btn
            dark
            color="teal"
            @click.prevent="save()"
            small
            :loading="loadingChangingStatus"
        >
          zapisz
        </v-btn>

      </v-card-actions>
    </v-card>


    <v-snackbar
        v-model="hasErrors"
        :multi-line="true"
    >
      <p v-for="(error, index) in errors"
         :key="`error${index}`"
         :class="{'mb-0': index===errors.length-1}">
        {{ error.key }} - {{ error.msg }}
      </p>

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="errors = []"
        >
          zamknij
        </v-btn>
      </template>
    </v-snackbar>
    <!--<pre>{{parentOrderId}}</pre>-->
  </v-overlay>
</template>

<script>
  import CustomersMixin from '@/mixins/CustomersMixin';
  import CustomerStatusChanger from '@/components/CustomerStatusChanger';
  import ErrorsMixin from '@/mixins/ErrorsMixin';
  import ApiCustomers from '@/api/Customers';

  const apiCustomers = new ApiCustomers;
  export default {
    name: 'CustomerStatusChangerOverlay',
    mixins: [
      ErrorsMixin,
      CustomersMixin
    ],
    components: {
      CustomerStatusChanger
    },
    props: {
      customer: {
        type: Object,
        required: true
      },
      isVisible: {
        type: Boolean,
        required: true,
      },
      setIsVisible: {
        type: Function,
        required: true,
      },
      campaign: {
        type: Object,
        required: false
      },
      parentOrderId: {
        type: String,
        required: false
      },
    },
    data: () => ({
      commentData: {},
      loadingChangingStatus: false,
      selectedCampaign: null
    }),
    mounted () {
      // console.log('sd')
    },
    methods: {
      save () {
        this.errors = [];
        if (!this.commentData.comment) {
          this.errors.push({
            key: 'błąd',
            msg: 'komentarz jest wymagany'
          });
          return;
        }
        if (!this.commentData.status) {
          this.errors.push({
            key: 'błąd',
            msg: 'status jest wymagany'
          });
          return;
        }

        if (this.commentData.status.isSale) {
          const newOrder = this.commentData.newOrder;
          if (typeof newOrder.address !== 'string' && typeof newOrder.address !== 'object') {
            if (!newOrder.newAddress || !newOrder.newAddress.email
                || !newOrder.newAddress.phone
                || !newOrder.newAddress.firstName
                || !newOrder.newAddress.lastName
                || !newOrder.newAddress.address
                || !newOrder.newAddress.postCode
                || !newOrder.newAddress.city) {

              this.errors.push({
                key: 'błąd',
                msg: 'adres jest niepoprawny'
              });
              return;
            }
          }
          if (!newOrder.carrier) {
            this.errors.push({
              key: 'błąd',
              msg: 'wybierz metodę dostawy'
            });
            return;
          }

          if (!newOrder.payment) {
            this.errors.push({
              key: 'błąd',
              msg: 'wybierz płatność'
            });
            return;
          }
        }

        if (this.parentOrderId) {
          this.commentData.parentOrderId = this.parentOrderId;
        }

        const campaignId = this.selectedCampaign && this.selectedCampaign._id
            ? this.selectedCampaign._id : this.campaign._id;

        this.loadingChangingStatus = true;
        // console.log(campaignId);
        //
        // if (this.loadingChangingStatus) return;
        apiCustomers.updateCustomerStatus(this.customerId, {
          ...this.commentData,
          campaignId
        })
            .then(() => console.log('sad'))
            .finally(() => {
              this.loadingChangingStatus = false;
              this.setIsVisible(false);
            });

      },

      setCommentData (data) {
        this.commentData = data;
      },

      setSelectedCampaign (payload) {
        this.selectedCampaign = payload;
      }
    }
  };
</script>

<style scoped>

</style>
