import axios from 'axios';
import store from '@/store';

export default class {
    baseUrl = '/api/orders-upsell/';

    get = async (orderId) => {
        await axios.get(`${this.baseUrl}${orderId}`)
            .then(({ data }) => {
                store.dispatch('setOrderUpsell', {
                    _id: orderId,
                    products: data.orderUpsell
                });
            });
    };
}