// @/mixins/CountriesMixin
import ApiCountries from '@/api/Countries';

const apiCountries = new ApiCountries();

import { mapGetters } from 'vuex';

export default {
  data: () => ({
    loaderCountries: false
  }),

  computed: {
    ...mapGetters([
      'COUNTRIES',
      'COUNTRY'
    ]),
  },

  created () {
    if (this.COUNTRIES.length < 1) {
      this.getCountries();
    }
  },

  methods: {
    getCountries () {
      this.loaderCountries = true;
      apiCountries.getActive()
        .finally(() => this.loaderCountries = false);
    }
  }
};
