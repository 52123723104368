<template>
  <v-app class="full-screen login-wrap"
         :style="{backgroundImage}">
    <v-card
        elevation="1"
        tile
        class="self-center login-card"
    >
      <DefaultLogo class="default-logo mb-3"/>
      <v-form ref="form" @submit.prevent="handleSubmit">
        <h1>logowanie</h1>
        <v-text-field
            v-model="email"
            :rules="emailRules"
            label="email"
            required
        />

        <v-text-field
            v-model="password"
            :rules="passwordRules"
            label="hasło"
            type="password"
            required
        />

        <v-btn
            color="blue-grey"
            class="mt-4 float-right white--text"
            :loading="loading"
            type="submit"
        >
          zaloguj się

          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-form>

      <v-snackbar
          :timeout="-1"
          :value="errors.length>0"
          absolute
          center
          bottom
          color="red"
      >
        <template v-for="(error, index) in errors">
          <div :key="`error${index}`">
            <strong>{{ error.key }}</strong>
            {{ error.value }}
          </div>
        </template>
        <template v-slot:action="{ attrs }">
          <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="removeError"
          >
            zamknij
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-app>
</template>

<script>
  import { mapActions } from 'vuex';
  import DefaultLogo from '@/components/imgs/DefaultLogo';
  import Auth from '@/api/auth';

  export default {
    name: 'Login',
    // todo
    //    mixins:[
    //      ErrorsMixin
    //    ],
    components: {
      DefaultLogo,
    },
    data: () => ({
      errors: [],
      /* *** ui *** */
      loading: false,
      /* *** data *** */
      email: '',
      password: '',

      /* *** validators *** */
      valid: true,
      emailRules: [
        v => !!v || 'wpisz email',
        v => /.+@.+\..+/.test(v) || 'niepoprawny email',
      ],
      passwordRules: [
        v => !!v || 'wpisz hasło',
      ],
    }),

    computed: {
      backgroundImage () {
        const x = Math.floor(Math.random() * 20) + 1;
        const y = x % 3 + 1;
        return `url('/img/login-bg-${y}.jpg')`;
      }
    },

    methods: {
      ...mapActions(['saveUserData', 'toggleAuthState']),

      removeError () {
        this.errors = [];
      },

      validate () {
        return this.$refs.form.validate();
      },

      handleSubmit () {
        this.loading = true;
        if (!this.validate()) {
          this.errors.push({
            key: 'Błąd',
            value: 'nie wszystkie dane są uzupełnione'
          });
          this.loading = false;
          return;
        }

        Auth.login(this.email, this.password)
            .catch(e => this.errors = e)
            .finally(() => this.loading = false);
      },
    },
  };
</script>


<style lang="scss">
  .v-application.login-wrap {
    background-image: url('/img/login-bg-1.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .v-application--wrap {
      width: 100vw;
      height: 100vh;
      display: grid;
    }
  }

  .self-center {
    justify-self: center;
    align-self: center;
  }

  .login-card {
    padding: 130px 0 120px;
    width: 66%;
    min-width: 320px;
  }

  form {
    width: 320px;
    margin: 0 auto;
  }

  .default-logo {
    width: 100%;
    height: 40px;
  }

  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }

  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
