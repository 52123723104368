import axios from 'axios';
import store from '@/store';

export default class {
  baseUrl = '/api/carriers';

  get = () => new Promise(() => {
    axios.get(this.baseUrl)
      .then(({ data }) => store.dispatch('setCarriers', data.carriers));
  });
  getActive = () => new Promise(() => {
    axios.get(this.baseUrl,{ params: { isActive: true } })
      .then(({ data }) => store.dispatch('setCarriers', data.carriers));
  });
}
