<template>
  <div>
    <div v-if="order && customer">
      <v-row>
        <v-col cols="12" class="pb-0">
          <CustomerBaseActions
              :customer="customer"
              :campaign="campaign"
              :parent-order-id="orderId"
          />
        </v-col>
        <v-col cols="9">
          <OrderDetails
              :key="`${customer._id}order${order._id}`"
              :order-id="order._id"
              v-if="order && order._id"
              class="mb-2"
          />
        </v-col>

        <v-col cols="3">
          <CustomerBaseData :customer="customer"/>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import ErrorsMixin from '@/mixins/ErrorsMixin';

  import ApiOrders from '@/api/Orders';
  import ApiCalls from '@/api/Calls';
  import CustomersMixin from '@/mixins/CustomersMixin';
  import StatusesMixin from '@/mixins/StatusesMixin';
  import OrderDetails from '@/components/OrderDetails';
  import CustomerBaseData from '@/components/CustomerBaseData';
  import CustomerBaseActions from '@/components/CustomerBaseActions';
  import CampaignsMixin from '@/mixins/CampaignsMixin';

  const apiOrders = new ApiOrders();
  const apiCalls = new ApiCalls();

  export default {
    name: 'Order',
    mixins: [
      ErrorsMixin,
      CustomersMixin,
      StatusesMixin,
      CampaignsMixin
    ],
    components: {
      OrderDetails,
      CustomerBaseData,
      CustomerBaseActions,
    },
    data: () => ({
      loading: false,
    }),
    computed: {
      ...mapGetters([
        'ORDERS',
        'IS_ACTIVE_USER',
        'ORDER',
      ]),
      campaign () {
        return this.CAMPAIGN_FROM_MIXED_ID(
            this.$route.params.campaignUuid
        );
      },

      /* *** route params *** */
      isInfluencerCoupon () {
        if (!this.order.couponRuleName) return '';
        if (this.order.couponRuleName.indexOf('.{callcenter}') > -1) return 'success--text';
        if (this.order.couponRuleName.indexOf('.[influencer]') > -1) return 'danger--text';

        return '';
      },
      orderId () {
        return this.$route.params.orderId;
      },
      campaignExternalId () {
        return this.$route.params.campaignUuid;
      },
      /* *** *** */
      order () {
        return this.ORDER(this.orderId) ?? {};
      },
      customer () {
        if (!this.order) return {};
        if (!this.order.customer) return {};
        if (this.order.customer._id) return this.CUSTOMER(this.order.customer._id);
        return this.CUSTOMER(this.order.customer);
      },
    },

    watch: {
      '$route.params.orderId' () {
        this.refreshSelf();
      }
    },

    created () {
      this.refreshSelf();
    },

    methods: {
      refreshSelf () {
        setTimeout(() => {
          if (!this.ORDER(this.orderId) || !this.ORDER(this.orderId).customer) {// todo check
            setTimeout(() => {
              this.getOrder().then(() => {
                this.getCustomer(this.ORDER(this.orderId).customer).then(() => {
                  this.startCall();
                });
              });
            }, 500);
            return;
          }

          setTimeout(() => {
            this.getCustomer(this.ORDER(this.orderId).customer).then(() => {
              this.startCall();
            });
          }, 500);

        }, 500);
      },
      startCall () {
        if (this.CURRENT_CALL) return;
        if (!this.IS_ACTIVE_USER) return;

        if (!this.ORDER(this.orderId) || !this.customerMainPhone || !this.customerMainPhone._id) {
          setTimeout(() => {
            this.startCall();
          }, 500);

          return;
        }

        apiCalls.post({
          order: this.orderId,
          campaignExternalId: this.campaignExternalId,
          customer: this.order.customer,
          customerPhone: this.customerMainPhone._id
        });
      },
      /* *** *** */
      async getOrder () {
        return await apiOrders
            .getOrder({ id: this.orderId, campaignExternalId: this.campaignExternalId })
            .then(r => r)
            .catch(e => this.errors = e)
            .finally(() => this.loading = false);
      },
    }
  };
</script>

<style scoped>

</style>
