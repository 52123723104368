import { isEmpty } from '@/utils/isEmpty';

const checkIsSet = (data, object, keyForCheck = '_id') => {
  if (undefined === data || data === null || data.length < 1) {
    return -1;
  }

  for (const [key, datum] of data.entries()) {
    if (datum[keyForCheck] !== object[keyForCheck]) {
      continue;
    }
    return key;
  }
  return -1;
};

const parseMultipleData = (data, payload) => {
  if (isEmpty(payload)) return data;
  if (isEmpty(data)) data = [];
  for (const datum of payload) {
    let isSetKey = checkIsSet(data, datum, '_id');
    if (isSetKey === -1) {
      data.push(datum);
      continue;
    }

    let test = Object.assign({}, data[isSetKey]);
    test = Object.assign(test, datum);

    data.splice(isSetKey, 1, test);
  }

  return data;
};

const parseSingleInMultipleData = (data, datum) => {
  if (isEmpty(datum)) return data;
  if (isEmpty(data)) data = [];
  let isSetKey = checkIsSet(data, datum, '_id');
  if (isSetKey === -1) {
    data.push(datum);
    return data;
  }

  let test = Object.assign({}, data[isSetKey]);
  test = Object.assign(test, datum);

  data.splice(isSetKey, 1, test);

  return data;
};

export {
  checkIsSet,
  parseMultipleData,
  parseSingleInMultipleData
};
