import axios from 'axios';

export default class {
  baseUrl = '/api/promo-codes';

  /* *** get *** */
  getPerCode = async (code) => {
    return await axios.get(`${this.baseUrl}/${code}`)
      .then(({ data })=>data)
      .catch(e => {
        if (e?.response?.data?.errors) return { errors: e.response.data.errors };
        return e;
      });
  };
}
