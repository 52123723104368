<template>
  <div class="home">
    ladowanie
  </div>
</template>

<script>
  export default {
    name: 'Home',
    created () {
      this.$router.push({ name: 'UserDashboard' });
    }
  };
</script>
