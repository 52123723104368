// @/mixins/ProductsMixin
import ApiProducts from '@/api/Products';

const apiProducts = new ApiProducts();

import { mapGetters } from 'vuex';

export default {
  data: () => ({
    loaderProducts: false
  }),

  computed: {
    ...mapGetters([
      'PRODUCTS',
      'PRODUCT'
    ]),
  },

  created () {
    if (this.PRODUCTS.length < 1) {
      this.getProducts();
    }
  },

  methods: {
    getProducts () {
      this.loaderProducts = true;
      apiProducts.get().finally(() => this.loaderProducts = false);
    }
  }
};
