import axios from 'axios';

export default class {
    baseUrl = '/api/stocks';

    get = async (showAllStocks) => {
        const url = showAllStocks ? `${this.baseUrl}/${showAllStocks}` : this.baseUrl;
        const { data } = await axios.get(url);
        return data;
    };
}

