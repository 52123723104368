<template>
  <div class="main-wrap blue-grey lighten-5">

    <v-app-bar dense class="_header">
      <v-btn
          icon
          :to="{name:'UserDashboard'}"
          class="logo-btn"
          :disabled="isDisabledDashboard"
      >
        <DefaultLogo class="_logo"/>
      </v-btn>
      <!--      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>-->

      <v-spacer></v-spacer>

      <!--      <v-btn icon>-->
      <!--        <v-icon>mdi-heart</v-icon>-->
      <!--      </v-btn>-->

      <!--      <v-btn icon>-->
      <!--        <v-icon>mdi-magnify</v-icon>-->
      <!--      </v-btn>-->

      <v-switch
          class="mt-6 mx-2"
          color="green"
          v-model="userIsActive"
          :label="userIsActive ? 'gotowy' : 'niegotowy'"
      />

      {{ AUTH_USER.email }}

      <v-btn
          class="ml-2"
          outlined
          color="red"
          @click="logout"
          x-small
      >
        wyloguj się
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <!--

        <v-navigation-drawer
            v-model="drawer"
            bottom
            absolute
            temporary
        >
          <v-list
              nav
              dense
              fixed
          >
            <v-list-item-group
                v-model="group"
                active-class="deep-purple&#45;&#45;text text&#45;&#45;accent-4"
            >
              <v-list-item>
                <v-list-item-title>Foo</v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title>Bar</v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title>Fizz</v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title>Buzz</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
    -->
    <v-snackbar
        v-model="hasCurrentError"
        :multi-line="true"
    >
      {{ currentError }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="assignError(null)"
        >
          zamknij
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
        v-model="localOrderExternalId"
    >
      zamówienie zostało złożone #{{ LOCAL_ORDER_EXTERNAL_ID }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="green"
            text
            v-bind="attrs"
            @click="setLocalOrderExternalId(null)"
        >
          zamknij
        </v-btn>
      </template>
    </v-snackbar>

    <v-main class="px-4 py-4">
      <router-view/>
    </v-main>
    <CallSummary/>
  </div>
</template>

<script>
  import DefaultLogo from '@/components/imgs/DefaultLogo';
  import { mapActions, mapGetters } from 'vuex';
  import CallSummary from '@/components/CallSummary';

  import ApiCalls from '@/api/Calls';
  import UserActivity from '@/api/UserActivity';
  import Users from '@/api/Users';

  const apiCalls = new ApiCalls();
  const apiUserActivity = new UserActivity();
  const apiUsers = new Users();

  export default {
    name: 'UserTemplate',
    components: {
      DefaultLogo,
      CallSummary
    },
    data: () => ({
      drawer: false,
      group: null,
    }),

    computed: {
      ...mapGetters([
        'AUTH_USER',
        'CURRENT_CALL',
        'USER_ACTIVITY',
        'IS_ACTIVE_USER',
        'GET_SOCKET',
        'GET_ERROR',
        'LOCAL_ORDER_EXTERNAL_ID',
      ]),
      userIsActive: {
        get () {
          return this.IS_ACTIVE_USER;
        },
        set (isActive) {
          if (isActive) {
            apiUserActivity.post();
          } else {
            apiUserActivity.put();
          }
        }
      },
      currentError () {
        return this.GET_ERROR;
      },
      hasCurrentError: {
        get () {
          return !!this.GET_ERROR;
        },
        set (error) {
          this.assignError(error);
        }
      },
      localOrderExternalId: {
        get () {
          return !!this.LOCAL_ORDER_EXTERNAL_ID;
        },
        set () {
          this.setLocalOrderExternalId(null);
        }
      },
      isDisabledDashboard () {
        if (!this.userIsActive) return false;

        return this.$route.name === 'UserOrder';
      }
    },

    watch: {
      group () {
        this.drawer = false;
      },
    },
    created () {
      if (!this.CURRENT_CALL) {
        apiCalls.getCurrent();
      }

      apiUserActivity.getCurrent();
      apiUsers.get();
      this.getBlockedCustomers();
    },
    mounted () {
      apiUserActivity.getCurrent();
    },
    methods: {
      ...mapActions([
        'toggleAuthState',
        'addCustomers',
        'assignError',
        'setLocalOrderExternalId'
      ]),

      logout () {
        localStorage.clear();
        this.toggleAuthState(false);
        this.$router.push({ name: 'Login' });
      },

      getBlockedCustomers () {
        this.GET_SOCKET.emit('getBlockedCustomerList', this.AUTH_USER._id);
        this.GET_SOCKET.on('receivedBlockedCustomerList',
            (customers) => this.addCustomers(JSON.parse(customers))
        );
      }
    },

  };
</script>

<style scoped lang="scss">
  header {
    z-index: 8;
  }

  .main-wrap {
    min-height: 100vh;
  }

  .logo-btn {
    margin: 0 !important;
    width: auto !important;
    height: auto !important;
    border-radius: 0;
    color: #fff;

    &:before {
      content: unset;
    }
  }

  body {
    ._header {
      background: #fff;
      position: sticky;
      top: 0;
      box-shadow: 0 0 3px 1px rgba(0, 0, 0, .15) !important;
    }
  }
  ._logo{
    height: 18px;
  }
</style>
