import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';

Vue.use(Vuex);

export default {
  state: {
    userActivity: {},
  },
  getters: {
    USER_ACTIVITY: state => state.userActivity,
    IS_ACTIVE_USER(state){
      if (!state.userActivity) return false;
      if (state.userActivity.finished) return false;
      return moment().diff(moment(state.userActivity.dateEnd)) < 0;
    }
  },
  mutations: {
    SET_USER_ACTIVITY: (state, payload) => state.userActivity = payload
  },
  actions: {
    setUserActivity ({ commit }, payload) {
      commit('SET_USER_ACTIVITY', payload);
    },
  }
};
