// import Vue from 'vue';
// import Vuex from 'vuex';
import { parseMultipleData, parseSingleInMultipleData } from '@/utils/arrayParser';

// Vue.use(Vuex);

export default {
  state: {
    calls: [],
    currentCall: null,
  },
  getters: {
    CALLS: state => state.calls,
    CALL: state => (id) => state.calls.find(el => el._id === id),
    CURRENT_CALL: state => state.currentCall,
    IS_CURRENT_CALL_IN_PROGRESS: state => {
      if (state.currentCall === null || !state.currentCall) return false;

      if (!state.currentCall.dateEnd || !state.currentCall.status) return true;
      return false;
    }
  },
  mutations: {
    SET_ALL_CALL: (state, payload) => state.calls = payload,
    SET_CALLS: (state, payload) => {
      state.calls = parseMultipleData(state.calls, payload);
    },
    SET_CALL: (state, payload) => state.calls = parseSingleInMultipleData(state.calls, payload),

    SET_CURRENT_CALL: (state, payload) => state.currentCall = payload,
  },
  actions: {
    setCalls ({ commit }, payload) {
      if (payload) commit('SET_ALL_CALL', payload);
    },
    setCurrentCall ({ commit, getters }, payload) {
      if (!payload || !payload.user) return;
      if (payload.user !== getters.AUTH_USER._id && payload.user._id !== getters.AUTH_USER._id) return;

      commit('SET_CURRENT_CALL', payload);
    },
    clearCurrentCall ({ commit }) {
      commit('SET_CURRENT_CALL', null);
    },
  }
};
