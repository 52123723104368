// @/mixins/CarriersMixin
import ApiCarriers from '@/api/Carriers';

const apiCarriers = new ApiCarriers();

import { mapGetters } from 'vuex';

export default {
  data: () => ({
    loaderCarriers: false
  }),

  computed: {
    ...mapGetters([
      'CARRIERS',
      'CARRIER'
    ]),
  },

  created () {
    if (this.CARRIERS.length < 1) {
      this.getCarriers();
    }
  },

  methods: {
    getCarriers () {
      this.loaderCarriers = true;
      apiCarriers.getActive().finally(() => this.loaderCarriers = false);
    }
  }
};
