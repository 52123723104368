<template>
  <div class="_wrap">
    <v-btn
        class="_nav_link"
        :to="{name:'UserDashboard'}"
        color="warning"
        :class="{'_active': $route.name==='UserDashboard'}"
        text>
      Panel Kostultanta
    </v-btn>

    <v-btn
        class="_nav_link"
        :to="{name:'AdminReports'}"
        color="warning"
        :class="{'_active': $route.name==='AdminReports'}"
        text>
      Raporty
    </v-btn>

    <v-btn
        class="_nav_link"
        :to="{name:'AdminStocks'}"
        color="warning"
        :class="{'_active': $route.name==='AdminStocks'}"
        text>
      Stany Magazynowe
    </v-btn>

    <v-btn
        class="_nav_link"
        :to="{name:'AdminNewOrder'}"
        color="warning"
        :class="{'_active': $route.name==='AdminNewOrder'}"
        text>
      Nowe zamówienie
    </v-btn>

  </div>
</template>

<script>
  export default {
    name: 'AdminNavigation',
  };
</script>

<style scoped lang="scss">
  ._nav {
    &_link {
      border-bottom: 1px solid rgb(118, 118, 118);
      border-radius: 0;
      margin: 0 16px 16px;
      transition: border-bottom-color ease-in-out .3s;

      &:before{
        content: unset;
      }
      &:first-child {
        margin-left: 0;
      }

      &:hover {
        border-color: #000;
      }

      &._active {
        background: rgba(251,140,0,.18);
      }
    }
  }
</style>
