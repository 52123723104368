const routes = [
  {
    path: '/user/:id',
    name: 'UserProfile',
    component: () => import('@/views/user/UserDashboard.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      transitionName: 'router-anim',
      enterActive: 'animated fadeIn'
    }
  },
  // {
  //   path: '/user/:id/edit',
  //   name: 'UserEditUserProfile',// todo check wtf
  //   component: () => import('@/views/user/UserEditUserProfile.vue'),
  //   props: true,
  //   meta: {
  //     requiresAuth: true,
  //     transitionName: 'router-anim',
  //     enterActive: 'animated fadeIn'
  //   }
  // },
  // {
  //   path: '/profile/:id',
  //   name: 'Profile',// todo check wtf
  //   component: () => import('@/views/profile/Profile.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     transitionName: 'router-anim',
  //     enterActive: 'animated fadeIn'
  //   }
  // },
];

export default routes;
