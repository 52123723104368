import axios from 'axios';
import store from '@/store';

export default class {
  baseUrl = '/api/user-activities';

  getCurrent = () => new Promise(() => {
    axios.get(`${this.baseUrl}/current`)
      .then(({ data }) => store.dispatch('setUserActivity', data.userActivity));
  });
  post = () => new Promise(() => {
    axios.post(this.baseUrl)
      .then(({ data }) => store.dispatch('setUserActivity', data.userActivity));
  });
  put = () => new Promise(() => {
    axios.put(this.baseUrl)
      .then(({ data }) => store.dispatch('setUserActivity', data.userActivity));
  });
}
