// @/mixins/ScoredOrdersMixin
import ApiOrders from '@/api/Orders';

const apiOrders = new ApiOrders();
export default {
  data: () => ({
    loadingNextOrder: false,
    errors: []
  }),
  methods: {
    redirectToFirstActiveScoredOrder (ninId = null) {
      this.loadingNextOrder = true;
      apiOrders.getAndRedirectToFirstActiveScoredOrder(ninId)
        .catch(e => this.errors = e)
        .finally(() => this.loadingNextOrder = false);
    }
  }
};
