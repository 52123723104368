<template>
  <div class="wrap">
    <div v-if="!campaignRequired" class="my-3 py-3">

    </div>

    <v-combobox
        v-if="selectType"
        v-model="reportType"
        :items="reportTypes"
        label="typ połączenia"
        item-text="title"
        deletable-chips
        class="mt-4 mx-3"
    />
    <v-combobox
        v-if="campaignRequired && CAMPAIGNS"
        v-model="campaign"
        :items="CAMPAIGNS"
        label="kampania"
        item-text="title"
        item-value="_id"
        deletable-chips
        :rules="notEmptyFieldRules"
        class="mx-2 px-2"
    />
    <div class="px-2">
      <v-date-picker
          v-model="dates"
          range
          locale="pl"
          full-width
      />
    </div>
    <div class="d-flex justify-end pa-2">
      <v-btn
          @click.prevent="downloadReport"
          :loading="loading"
          color="success"
      >
        pobierz
      </v-btn>
    </div>
    <v-snackbar
        :timeout="-1"
        :value="errors.length>0"
        absolute
        center
        bottom
        color="red"
    >
      <template v-for="(error, index) in errors">
        <div :key="`error${index}`">
          <strong>{{ error.key }}</strong>
          {{ error.msg }}
        </div>
      </template>
      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="removeError"
        >
          zamknij
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import CampaignsMixin from '@/mixins/CampaignsMixin';
  import FormValidatorMixin from '@/mixins/FormValidatorMixin';
  import ReportsApi from '@/api/Reports';
  import ErrorsMixin from '@/mixins/ErrorsMixin';
  import { prepareCsvToDownload } from '@/utils/csvWriter';

  const reportsApi = new ReportsApi();

  export default {
    name: 'ReportForm',
    mixins: [
      CampaignsMixin,
      FormValidatorMixin,
      ErrorsMixin
    ],
    props: {
      reportUuid: {
        type: String,
        required: true,
      },
      reportTitle: {
        type: String,
        required: true,
        default: 'raport'
      },
      campaignRequired: {
        type: Boolean,
        default: true,
        required: false
      },
      selectType: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data: () => ({
      loading: false,
      // errors: [],
      dates: [],
      campaign: null,
      reportType: {
        title: 'wszystkie',
        value: 0
      },
      reportTypes: [
        {
          title: 'wszystkie',
          value: 0
        },
        {
          title: 'inbound',
          value: 1
        },
        {
          title: 'outbound',
          value: 2
        },
      ]
    }),
    methods: {
      prepareFileToDownload (content) {
        let csvContent = 'data:text/csv;charset=utf-8,' + content;
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', this.reportTitle + '.csv');
        document.body.appendChild(link); // Required for FF
        link.click();
      },

      downloadReport () {
        this.errors = [];
        if (this.campaignRequired && !this.campaign) {
          this.errors.push({
            key: 'błąd',
            msg: 'wybierz kampanie'
          });
          return;
        }
        this.loading = true;

        let params = {
          dates: this.dates,
        };
        if (this.campaignRequired && this.campaign) {
          params.campaignId = this.campaign._id;
        }
        if (this.reportType.value) {
          params.type = this.reportType.value;
        }
        reportsApi.get(
            this.reportUuid,
            params,
        ).then(response => prepareCsvToDownload(response, this.reportTitle))
            .catch(() => {
              this.errors.push({
                key: 'błąd',
                msg: 'wystąpił błąd z pobraniem raportu'
              });
            })
            .finally(() => this.loading = false);
      }
    }
  };
</script>

<style scoped>

</style>
