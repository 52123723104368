<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15.269" height="12" viewBox="0 0 15.269 12">
    <g data-name="Group 231">
      <path data-name="Path 941"
            d="M15.269 1.636A1.636 1.636 0 0013.633 0H6.816A1.636 1.636 0 005.18 1.636v.545H3a3 3 0 00-3 3V9a.818.818 0 00.818.818h.872a2.727 2.727 0 005.344 0h1.745a2.727 2.727 0 005.344 0h.6a.545.545 0 00.545-.545zM1.909 5.453a.273.273 0 01-.273-.273A1.365 1.365 0 013 3.817h1.09a.273.273 0 01.273.273v1.09a.273.273 0 01-.273.273zm2.454 4.908a1.091 1.091 0 111.09-1.091 1.091 1.091 0 01-1.091 1.091zm7.089 0a1.091 1.091 0 111.091-1.091 1.091 1.091 0 01-1.092 1.091z"></path>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'CarIcon'
  };
</script>