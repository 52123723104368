import moment from 'moment';

const x = [

    {
        id: 66,
        days: 50,
        sku: 'HLSDOJSMA0250/PL/01', name: 'SmartMe-250 ml'
    },
    {
        id: 68,
        days: 25,
        sku: 'HLOL4UHEA0250/016/01', name: 'HealMe-250 ml'
    },
    {
        id: 127,
        days: 60,
        sku: 'HLSDWIVBC0060/PL/02', name: 'Vitamin B Complex-60'
    },
    {
        id: 139,
        days: 40,
        sku: 'HLSDGRRCO0090/PL/01', name: 'ShroomMe Reishi &amp; Cordyceps-450'
    },
    {
        id: 140,
        days: 40,
        sku: 'HLSDGRLMC0090/PL/01', name: 'ShroomMe Lion\'s Mane &amp; Chaga-450'
    },
    {
        id: 148,
        days: 60,
        sku: 'HLSDMPBMN0060/PL/01', name: 'BoostMe Now-60'
    },
    {
        id: 172,
        days: 60,
        sku: 'HLSDSGOME0060/PL/01', name: 'OmegaMe 60 kapsułek-60'
    },
    {
        id: 174,
        days: 120,
        sku: 'HLSDSGOME0120/PL/01', name: 'OmegaMe 120 kapsułek-120'
    },
    {
        id: 204,
        days: 60,
        sku: 'HLSDWIVEG0060/PL/01', name: 'VegeMe-60'
    },
    {
        id: 206,
        days: 60,
        sku: 'HLSDSGOMV0060/PL/02', name: 'OmegaMe Vege-60'
    },
    {
        id: 217,
        days: 180,
        sku: 'HLSDWIVDN0015/PL/01', name: 'Vitamin D Natural+-15 ml'
    },
    {
        id: 221,
        days: 50,
        sku: 'HLSDOJRIS0250/CZ/01', name: 'RiseMe-250 ml'
    },
    {
        id: 227,
        days: 60,
        sku: 'HLSDWIVBC0060/CZ/01', name: 'Vitamin B Complex-60'
    },
    {
        id: 237,
        days: 25,
        sku: 'HLSDOJHEA0250/CZ/01', name: 'HealMe-250 ml'
    },
    {
        id: 239,
        days: 25,
        sku: 'HLSDOJHEA0250/PL/01', name: 'HealMe-250 ml'
    },
    {
        id: 242,
        days: 60,
        sku: 'HLSDSGOMP0060/PL/01', name: 'OmegaMe Prenatal-60'
    },
    {
        id: 246,
        days: 50,
        sku: 'my_basic_care_pl_v2', name: 'My Basic Care'
    },
    {
        id: 246,
        days: 50,
        sku: 'my_basic_care_pl_v2', name: 'My Basic Care'
    },
    {
        id: 248,
        days: 90,
        sku: 'HLSDOJWDK0096/PL/01', name: '"MyKids Vitamin D3-9,6 ml"'
    },
    {
        id: 250,
        days: 15,
        sku: 'HLSDMPPMK0014/PL/01', name: 'MyKids Protect-14'
    },
    {
        id: 258,
        days: 50,
        sku: 'HLSDOJRIS0250/RO/01', name: 'RiseMe-250 ml'
    },
    {
        id: 262,
        days: 60,
        sku: 'HLSDWIVBC0060/RO/02', name: 'Vitamin B Complex-60'
    },
    {
        id: 264,
        days: 25,
        sku: 'HLSDOJHEA0250/RO/01', name: 'HealMe-250 ml'
    },
    {
        id: 266,
        days: 180,
        sku: 'HLSDWIVDN0015/RO/01', name: 'Vitamin D Natural+-15 ml'
    },
    {
        id: 284,
        days: 60,
        sku: 'HLSDSGOMV0060/RO/02', name: 'OmegaMe Vege-60'
    },
    {
        id: 290,
        days: 180,
        sku: 'HLSDWIVDN0015/CZ/01', name: 'Vitamin D Natural+-15 ml'
    },
    {
        id: 310,
        days: 15,
        sku: 'HLPRSPMCW0015/PL/01', name: 'ShapeMe o smaku czekolady z wiśnią opakowanie-15'
    },
    {
        id: 312,
        days: 15,
        sku: 'HLPRSPMOL0015/PL/01', name: 'ShapeMe o smaku owoców leśnych opakowanie-15'
    },
    {
        id: 314,
        days: 15,
        sku: 'HLPRSPMTS0015/PL/01', name: 'ShapeMe o smaku truskawki ze śmietanką opakowanie-15'
    },
    {
        id: 316,
        days: 15,
        sku: 'HLPRSPMMI0015/PL/01', name: 'ShapeMe mix smaków  opakowanie-15'
    },
    {
        id: 318,
        days: 1,
        sku: 'HLPRSPMCW0001/PL/01', name: 'ShapeMe o smaku czekolady z wiśnią saszetka-15'
    },
    {
        id: 320,
        days: 1,
        sku: 'HLPRSPMOL0001/PL/01', name: 'ShapeMe o smaku owoców leśnych saszetka-15'
    },
    {
        id: 322,
        days: 1,
        sku: 'HLPRSPMTS0001/PL/01', name: 'ShapeMe o smaku truskawki ze śmietanką saszetka-15'
    },
    {
        id: 324,
        days: 60,
        sku: 'HLSDWIVEG0060/CZ/01', name: 'VegeMe-60'
    },
    {
        id: 328,
        days: 60,
        sku: 'HLSDSGOMV0060/CZ/02', name: 'OmegaMe Vege-60'
    },
    {
        id: 336,
        days: 90,
        sku: 'HLKOKRGON0050/PL/01', name: 'Krem nawilżający Glow On  50 ml'
    },
    {
        id: 338,
        days: 90,
        sku: 'HLKOKRGOR0050/PL/01', name: 'Krem regenerujący Glow On  50 ml'
    },
    {
        id: 342,
        days: 90,
        sku: 'HLKOKRGOO0015/PL/01', name: 'Rozświetlający krem pod oczy Glow On  15 ml'
    },
    {
        id: 344,
        days: 90,
        sku: 'HLKOLOGOP0100/PL/01', name: 'Płynne serum złuszczające Glow On 100 ml'
    },
    {
        id: 346,
        days: 50,
        sku: 'HLSDMPGLO0001/PL/02', name: 'GlowMe - 5 saszetek'
    },
    {
        id: 348,
        days: 5,
        sku: 'HLSDMPSHI0001/PL/01', name: 'ShineMe - 5 saszetek'
    },
    {
        id: 353,
        days: 90,
        sku: 'my_glow_and_illuminated_skin', name: 'My Glow & Illuminated Skin'
    },
    {
        id: 354,
        days: 90,
        sku: 'my_glow_and_bright_look', name: 'My Glow & Bright Look'
    },
    {
        id: 355,
        days: 90,
        sku: 'my_glow_and_daily_routine', name: 'My Glow & Daily Routine'
    },
    {
        id: 356,
        days: 90,
        sku: 'my_glow_and_regenerated_skin', name: 'My Glow & Regenerated Skin'
    },
    {
        id: 370,
        days: 90,
        sku: 'HLKOMAGML0050/PL/01', name: 'Maska Liftingująco-Wygładzająca Glow On 50 ml'
    },
    {
        id: 372,
        days: 90,
        sku: 'HLKOMAGMO0050/PL/01', name: 'Maska Oczyszczająco-Rozjaśniająca Glow On 50 ml'
    },
    {
        id: 374,
        days: 60,
        sku: 'HLKOMAGMR0050/PL/01', name: 'Maska Regenerująco-Nawilżająca Glow On 50 ml'
    },
    {
        id: 376,
        days: 90,
        sku: 'my_glow_and_perfect_skin_pl', name: 'My Glow & Perfect Skin'
    },
    {
        id: 377,
        days: 90,
        sku: 'my_glow_and_flawless_skin_pl', name: 'My Glow & Flawless Skin'
    },
    {
        id: 378,
        days: 90,
        sku: 'my_glow_and_firm_skin_pl', name: 'My Glow & Firm Skin'
    },
    {
        id: 379,
        days: 90,
        sku: 'my_glow_and_radiant_skin_pl', name: 'My Glow & Radiant Skin'
    },
    {
        id: 381,
        days: 90,
        sku: 'HLSDWIMKC0060PL/01', name: 'MyKids Vitamin C-60'
    },
    {
        id: 385,
        days: 120,
        sku: 'HLKOEMPOE0100/PL/01', name: 'Emulsja do demakijażu Pure On-100 ml'
    },
    {
        id: 387,
        days: 90,
        sku: 'HLKOMGPOM0100/PL/01', name: 'Mgiełka odświeżająco-nawilżająca Pure On-100 ml'
    },
    {
        id: 389,
        days: 120,
        sku: 'HLKOZEPOZ0200/PL/01', name: 'Łagodny żel myjący Pure On-200 ml'
    },
    {
        id: 391,
        days: 90,
        sku: 'my_pure_skin_pl', name: 'My Pure Skin'
    },
    {
        id: 392,
        days: 50,
        sku: 'HLSDOJSMM0250/PL/01', name: 'SmartMe smak malinowy-250 ml'
    },
    {
        id: 406,
        days: 90,
        sku: 'HLKOKRSOW0050/PL/01', name: 'Krem Wygładzający Slow On-50 ml'
    },
    {
        id: 408,
        days: 90,
        sku: 'HLKOKRSOO0050/PL/01', name: 'Krem Odbudowujący Barierę Lipidową Slow On-50 ml'
    },
    {
        id: 410,
        days: 90,
        sku: 'HLKOSESOP0030/PL/01', name: 'Serum Peptydowe Slow On-30 ml'
    },
    {
        id: 412,
        days: 90,
        sku: 'HLKOSESOR0030/PL/01', name: 'Retinoserum na Noc Slow On-30 ml'
    },
    {
        id: 414,
        days: 90,
        sku: 'my_skin_complete_repair_pl', name: 'My Skin Complete Repair'
    },
    {
        id: 415,
        days: 90,
        sku: 'my_skin_smooth_and_hydration_pl', name: 'My Skin Smooth & Hydration'
    },
    {
        id: 416,
        days: 90,
        sku: 'my_skin_anti_aging_care_pl', name: 'My Anti-Aging Care'
    },
    {
        id: 423,
        days: 90,
        sku: 'my_daily_face_care_pl', name: 'My Daily Face Care'
    },
];

export function checkDays (product, dateString) {
    const days = x.find(({ sku }) => sku === product.sku)?.days || 30;
    const qty = product.quantityShipped || product.quantityOrdered || 1;
    const dateStart = moment(dateString).add(2, 'days');
    const endDate = moment(dateString).add(((days * qty) + 2), 'days');
    const now = moment();
    
    return endDate.diff(now, 'days');
}
