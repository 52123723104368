var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap"},[_c('v-row',[_c('v-col',[_c('treeselect',{staticClass:"-border-radius-max",attrs:{"placeholder":"wybierz email","multiple":true,"async":true,"load-options":_vm.getEmailsToFilter},model:{value:(_vm.selectedEmails),callback:function ($$v) {_vm.selectedEmails=$$v},expression:"selectedEmails"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"solo":"","dense":"","label":"nr zamówienia","required":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.getFiltered($event)}},model:{value:(_vm.orderSearchString),callback:function ($$v) {_vm.orderSearchString=$$v},expression:"orderSearchString"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"solo":"","dense":"","label":"Imię Nazwisko","required":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.getFiltered($event)}},model:{value:(_vm.customerNameSearchString),callback:function ($$v) {_vm.customerNameSearchString=$$v},expression:"customerNameSearchString"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.ORDERS,"items-per-page":15,"loading":_vm.tableLoading},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.externalId)),_c('br'),_vm._v(" "+_vm._s(item.incrementId)+" ")]}},{key:"item.emails",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.magentoCustomerEmail)+" ")]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.magentoCustomerFirstname)+" ")]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.magentoCustomerLastname)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.orderStatus)?_c('div',[_c('div',{staticClass:"pl-2",style:({color:item.orderStatus.color})},[_vm._v(" "+_vm._s(item.orderStatus.title)+" ")])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.campaign)?_c('v-btn',{attrs:{"small":"","to":{name:'UserCustomerOrder', params:{ orderId:item._id, campaignUuid: _vm.campaignId } }}},[_vm._v(" szczegóły ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }