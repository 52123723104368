import axios from 'axios';

export default class {
  // baseUrl = 'http://4call.internal.healthlabs.pl/api/in-post-points';
  baseUrl = '/api/in-post';

  getInPostPoints = (searchQuery) => new Promise((resolve) => {
    axios.get(this.baseUrl, { params: { searchQuery } })
      .then(({ data }) => {
        resolve(
          data.map(el => ({ id: el.title, label: el.description }))
        );
      });
  });
}
