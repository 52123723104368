import axios from 'axios';
import store from '@/store';
import router from '@/router';

const CancelToken = axios.CancelToken;
let source = {};

export default class {
    baseUrl = '/api/orders';

    async get (params) {
        let cancelTokenKey = 'orders_get';
        if (source[cancelTokenKey]) source[cancelTokenKey].cancel();
        source[cancelTokenKey] = CancelToken.source();

        const { data } = await axios.get(`${this.baseUrl}/`, {
            cancelToken: source[cancelTokenKey].token,
            params
        });

        if (!data) {
            store.dispatch('assignError', 'brak zamowień');
            store.dispatch('setAllOrders', []);
            return;
        }

        store.dispatch('setAllOrders', data.orders);
    }

    getAndRedirectToFirstActiveScoredOrder = (ninId) => new Promise((resolve, reject) => {
        axios.get(`${this.baseUrl}/get-first-active-scored-order/${ninId}`)
            .then(({ data }) => {
                if (!data) {
                    store.dispatch('assignError', 'brak zamowień');
                    if (router.currentRoute.name !== 'UserDashboard') {
                        router.push({
                            name: 'UserDashboard',
                            params: {}
                        });
                    }
                }

                store.dispatch('setOrder', data.order);
                store.dispatch('setCustomer', data.order.customer);
                router.push({
                    name: 'UserOrder',
                    params: { campaignUuid: 'HotLead', orderId: data.order._id }
                });
            })
            .catch(e => reject({ error: e.message }));
    });

    getTotalActiveOrdersQuantity = async () => {
        const { data } = await axios.get(`${this.baseUrl}/get-total-active-orders-quantity`);
        if (!data) {
            return;
        }
        store.dispatch('setTotalOrdersToRecallQuantity', data.totalOrdersToRecallQuantity);
        return data.ids;
    };

    getOrder = async ({ id, campaignExternalId }) => {
        await axios.get(`${this.baseUrl}/${campaignExternalId}/${id}`)
            .then(({ data }) => {
                store.dispatch('setOrder', data.order);
            })
            .catch(e => ({ error: e.message }));
    };

    createNewOrder = async (newOrder) => {
        return await axios.post(`${this.baseUrl}/`, { newOrder })
            .then(({ data }) => {
                console.log(data);
                return data;
            }).catch(e => {
                console.log(e);
            });
    };
}
