import Vue from 'vue';
import Vuex from 'vuex';
import { parseMultipleData, parseSingleInMultipleData } from '@/utils/arrayParser';

Vue.use(Vuex);

export default {
  state: {
    products: [],
  },
  getters: {
    PRODUCTS: state => state.products,
    PRODUCT: state => (id) => state.products.find(el => el._id === id),
  },
  mutations: {
    SET_ALL_PRODUCT: (state, payload) => state.products = payload,
    SET_PRODUCTS: (state, payload) => {
      state.products = parseMultipleData(state.products, payload);
    },
    SET_PRODUCT: (state, payload) => state.products = parseSingleInMultipleData(state.products, payload)
  },
  actions: {
    setProducts ({ commit }, payload) {
      if (payload) commit('SET_ALL_PRODUCT', payload);
    },
  }
};
