// @/mixins/CampaignsMixin
import ApiCampaigns from '@/api/Campaigns';

const apiCampaigns = new ApiCampaigns();

import { mapGetters } from 'vuex';

export default {
  data: () => ({
    loaderCampaigns: false
  }),

  computed: {
    ...mapGetters([
      'CAMPAIGNS',
      'CAMPAIGN',
      'CAMPAIGN_FROM_MIXED_ID'
    ]),
  },

  created () {
    if (this.CAMPAIGNS.length < 1) {
      this.getCampaigns();
    }
  },

  methods: {
    getCampaigns () {
      this.loaderCampaigns = true;
      apiCampaigns.get().finally(() => this.loaderCampaigns = false);
    },
  }
};
