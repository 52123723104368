// @/mixins/ErrorsMixin
export default {
  data: () => ({
    errors: [],
  }),
  computed: {
    hasErrors: {
      get () {
        return this.errors && this.errors.length > 0;
      },
      set (val) {
        this.errors = val;
      }
    }
  },
  methods: {
    removeError () {
      this.errors = [];
    }
  }
};
