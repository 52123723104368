<template>
  <v-container
      fluid
      class="_wrap">
    <div>
      <AdminNavigation/>
      <v-checkbox
          v-model="showAllStocks"
          label="pokaż produkty ze wszystkich sklepów"
      />
    </div>
    <div>
      <v-data-table
          :headers="headers"
          :items="itemsFiltered"
          :items-per-page="15"
          class="elevation-1"
          :loading="tableLoading"
      >

      </v-data-table>
    </div>
  </v-container>
</template>

<script>
  import StocksApi from '@/api/Stocks';
  import AdminNavigation from '@/components/AdminNavigation';

  const stocksApi = new StocksApi();
  export default {
    name: 'AdminStocks',
    components: {
      AdminNavigation,
    },
    data: () => ({
      showAllStocks: false,
      tableLoading: true,
      items: [],
      headers: [
        {
          text: 'sklep',
          value: 'store',
        },
        {
          text: 'sku',
          value: 'sku',
        },
        {
          text: 'ean',
          value: 'ean',
        },
        {
          text: 'nazwa',
          value: 'name',
        },
        {
          text: 'quantity',
          value: 'quantity',
        },
        {
          text: 'quantityFree',
          value: 'quantityFree',
        },
      ]
    }),
    computed: {
      itemsFiltered () {
        if (this.showAllStocks) return this.items;

        return this.items.filter(({ disabled, store }) => {
          return !disabled && store === 'pl';
        });
      },
    },
    mounted () {
      void this.getItems();
    },
    methods: {
      async getItems () {
        this.tableLoading = true;
        this.items = [];
        this.items = await stocksApi.get(this.showAllStocks);
        this.tableLoading = false;
      },
    }
  };
</script>

<style scoped>

</style>