import axios from 'axios';

const CancelToken = axios.CancelToken;
let source = {};

export default class {
  baseUrl = '/api/reports';

  /* *** get active current users call *** */
  get = async (uuid, { dates = [], campaignId = null, type = '', quantity = 0 }) => {
      let cancelTokenKey = 'reports_' + 'get';
    if (source[cancelTokenKey]) source[cancelTokenKey].cancel();
    source[cancelTokenKey] = CancelToken.source();
      const { data } = await axios.get(`${this.baseUrl}/${uuid}`, {
      cancelToken: source[cancelTokenKey].token,
      params: { dates, campaignId, type, quantity }
    });

    return data;
  };
}
