<template>
  <div class="wrap">
    <v-row>
      <v-col>
        <treeselect
            placeholder="wybierz email" class="-border-radius-max"
            v-model="selectedEmails" :multiple="true"
            :async="true" :load-options="getEmailsToFilter"/>
      </v-col>
      <!--<v-col>
        <treeselect
            placeholder="wybierz telefon" class="-border-radius-max"
            v-model="selectedPhones" :multiple="true"
            :async="true" :load-options="getPhonesToFilter"/>
      </v-col>-->
      <v-col>
        <v-text-field
            solo
            dense
            v-model="orderSearchString"
            label="nr zamówienia"
            required
            @keydown.enter.prevent="getFiltered"
        />
      </v-col>
      <v-col>
        <v-text-field
            solo
            dense
            v-model="customerNameSearchString"
            label="Imię Nazwisko"
            required
            @keydown.enter.prevent="getFiltered"
        />
      </v-col>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="ORDERS"
        :items-per-page="15"
        class="elevation-1"
        :loading="tableLoading"
    >
      <template v-slot:item.id="{ item }">
        {{ item.externalId }}<br>
        {{ item.incrementId }}
      </template>

      <template v-slot:item.emails="{ item }">
        {{ item.magentoCustomerEmail }}
      </template>

      <template v-slot:item.firstName="{ item }">
        {{ item.magentoCustomerFirstname }}
      </template>

      <template v-slot:item.lastName="{ item }">
        {{ item.magentoCustomerLastname }}
      </template>

      <template v-slot:item.status="{ item }">
        <div v-if="item.orderStatus">
          <div
              :style="{color:item.orderStatus.color}"
              class="pl-2"
          >
            {{ item.orderStatus.title }}
          </div>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
            v-if="campaign"
            small
            :to="{name:'UserCustomerOrder', params:{ orderId:item._id, campaignUuid: campaignId } }"
        >
          szczegóły
        </v-btn>
      </template>

    </v-data-table>
  </div>
</template>

<script>
  import moment from 'moment';
  import { mapGetters } from 'vuex';
  import StatusesMixin from '@/mixins/StatusesMixin';
  import CampaignsMixin from '@/mixins/CampaignsMixin';
  import ApiOrders from '@/api/Orders';
  import ApiCustomerEmails from '@/api/CustomerEmails';
  import ApiCustomerPhones from '@/api/CustomerPhones';

  const apiOrders = new ApiOrders();
  const apiCustomerEmails = new ApiCustomerEmails();
  const apiCustomerPhones = new ApiCustomerPhones();

  export default {
    name: 'UserOrders',
    mixins: [
      StatusesMixin,
      CampaignsMixin
    ],
    data: () => ({
      headers: [
        { text: 'id', align: 'start', value: 'id' },
        { text: 'Email', value: 'emails' },
        { text: 'Imię', value: 'firstName', },
        { text: 'Nazwisko', value: 'lastName', },
        { text: 'Status', value: 'status' },
        { text: 'Akcje', value: 'actions', align: 'end' },
      ],
      /* *** filters *** */
      selectedEmails: [],
      selectedPhones: [],
      orderSearchString: '',
      customerNameSearchString: '',
      tableLoading:false,
    }),
    computed: {
      ...mapGetters([
        'CAMPAIGN_FROM_MIXED_ID',
        'CAMPAIGNS',
        'ORDERS',
      ]),
      moment () {
        return moment;
      },
      lastStatus: function () {
        return (statuses) => {
          if (!statuses || statuses.length < 1) return false;
          const lastStatus = statuses[statuses.length - 1];
          return {
            ...lastStatus,
            status: this.STATUS(lastStatus.status)
          };
        };
      },

      /* *** redirect from inbound *** */
      campaignUuid () {
        return this.$route.query.campaignUuid;
      },
      campaignIdFromRoute () {
        if (this.$route.params.campaign_uuid) return this.$route.params.campaign_uuid;
        if (this.$route.query.campaignUuid) return this.$route.query.campaignUuid;
        if (!this.typeOfCustomers) return null;

        const campaignsPerType = {
          owner: 6,
          assigned: 7
        };

        const campaignUuid = campaignsPerType[this.typeOfCustomers];
        if (!campaignUuid) return null;
        return campaignUuid;
      },
      campaign () {
        return this.CAMPAIGN_FROM_MIXED_ID('Search');
      },
      campaignId () {
        if (!this.campaign) return null;
        return this.campaign._id;
      }
    },
    watch: {
      selectedPhones (newVal, oldVal) {
        if (!newVal && !oldVal) return;
        if (newVal.length < 1 && oldVal.length < 1) return;
        this.getFiltered();
      },
      selectedEmails (newVal, oldVal) {
        if (!newVal && !oldVal) return;
        if (newVal.length < 1 && oldVal.length < 1) return;
        this.getFiltered();
      },
      orderSearchString (newVal, oldVal) {
        newVal = newVal.replace(/\\/g, '');
        newVal = newVal.replace(/\//g, '');
        if (!newVal && !oldVal) return;
        if (newVal.length < 1 && oldVal.length < 1) return;
        this.orderSearchString = newVal;
        if (this.orderSearchString && this.orderSearchString.length > 0 && this.orderSearchString.length < 4) return;
        this.getFiltered();
      },
      customerNameSearchString (newVal, oldVal) {
        newVal = newVal.replace(/\\/g, '');
        newVal = newVal.replace(/\//g, '');
        if (!newVal && !oldVal) return;
        if (newVal.length < 1 && oldVal.length < 1) return;
        this.customerNameSearchString = newVal;
        if (this.customerNameSearchString && this.customerNameSearchString.length > 0 && this.customerNameSearchString.length < 4) return;
        this.getFiltered();
      },
    },
    created () {
      this.tableLoading=true
      apiOrders.get()
      .finally(() => {
              setTimeout(() => {
                this.tableLoading = false;
              }, 500);
            });
    },
    methods: {
      getFiltered () {
        const queryParams = {};
        queryParams.selectedEmails = this.selectedEmails;
        if (this.orderSearchString) queryParams.orderSearchString = this.orderSearchString;
        if (this.customerNameSearchString) queryParams.customerNameSearchString = this.customerNameSearchString;

        this.tableLoading=true
        apiOrders.get(queryParams)
        .finally(() => {
              setTimeout(() => {
                this.tableLoading = false;
              }, 500);
            });
      },
      /* *** filters *** */
      getEmailsToFilter ({ searchQuery, callback }) {
        searchQuery = searchQuery.replace(/\\/g, '');
        searchQuery = searchQuery.replace(/\//g, '');
        if (searchQuery.length < 3) return callback(null, []);
        apiCustomerEmails.get(searchQuery).then(emails => {
          const options = emails.map(email => ({
            id: email.email,
            label: email.email
          }));
          callback(null, options);
        });
      },
      getPhonesToFilter ({ searchQuery, callback }) {
        searchQuery = searchQuery.replace(/\\/g, '');
        searchQuery = searchQuery.replace(/\//g, '');
        if (searchQuery.length < 3) return callback(null, []);
        apiCustomerPhones.get(searchQuery).then(phones => {
          const options = phones.map(phone => ({
            id: phone.phone,
            label: phone.phone
          }));
          callback(null, options);
        });
      },
    }
  };
</script>

<style scoped>

</style>
