<template>
  <div v-if="customer"
       class="_customerBaseStatus">
    <div class="d-flex justify-space-between mb-3">
      <strong>
        Notatka główna
      </strong>
      <template>
        <div class="d-flex">
          <template v-if="childrenProducts">
            <small class="mr-3">zainteresowany <b class="primary--text">KIDS</b></small>
            <br>
          </template>
          <template v-if="dieticianCare">
            <small class="mr-3">opieka <b class="warning--text">dietetyka</b></small>
            <br>
          </template>
          <template v-if="metamorphosis">
            <small class="mr-3"><b class="warning--text">metamorfoza</b></small>
            <br>
          </template>
        </div>
      </template>

      <button class="_btn-info"
              v-if="!textToEdit && !edition"
              @click.prevent="startEdit">
        dodaj
        <v-icon>
          mdi-pencil
        </v-icon>
      </button>

      <button v-else-if="!edition"
              class="_btn-info"
              @click.prevent="startEdit">
        {{ !textToEdit ? 'dodaj' : 'zmień' }}
        <v-icon>
          mdi-pencil
        </v-icon>
      </button>

      <div v-else>
        <button class="_btn-success"
                type="button"
                @click.prevent="save"
                :disabled="loadingCustomerMainStatus">
          zapisz
          <v-icon>
            mdi-check
          </v-icon>
        </button>
        <button class="_btn-link"
                @click.prevent="cancelEdition"
                :disabled="loadingCustomerMainStatus">
          anuluj
          <v-icon>
            mdi-cancel
          </v-icon>
        </button>
      </div>
    </div>

    <div v-if="!edition && textToEdit"
         class="_customerBaseStatus-text">
      <p v-if="textToEdit"
         @dblclick="startEdit">
        {{ textToEdit }}
      </p>
    </div>

    <form @submit.prevent="save"
          v-if="!!edition">
      <div class="mb-3">
        <input type="text"
               value="tutaj jakiś tekst"
               ref="editionInput"
               v-model="textToEdit">
      </div>
      <div class="d-flex">
        <v-checkbox
            v-model="childrenProducts"
            label="zainteresowany KIDS"
            class="pt-0 mt-0"
        />
        <v-checkbox
            v-model="dieticianCare"
            label="opieka dietetyka"
            class="pt-0 mt-0"
        />
        <v-checkbox
            v-model="metamorphosis"
            label="metamorfoza"
            class="pt-0 mt-0"
        />
      </div>
    </form>
  </div>
</template>

<script>
  import CustomersMixin from '@/mixins/CustomersMixin';
  import ApiCustomers from '@/api/Customers';

  const apiCustomers = new ApiCustomers;
  export default {
    name: 'CustomerBaseStatus',
    mixins: [
      CustomersMixin
    ],
    props: {
      customer: {
        type: Object,
        required: true
      },
    },
    data: () => ({
      loadingCustomerMainStatus: false,
      edition: false,
      textToEdit: '',
      childrenProducts: false,
      dieticianCare: false,
      metamorphosis: false,
    }),
    computed: {
      customerMainStatus () {
        return this.customer.mainStatus || '';
      }
    },
    watch: {
      customerMainStatus () {
        this.textToEdit = this.customerMainStatus();
      }
    },
    mounted () {
      this.textToEdit = this.customer?.mainStatus || '';

      this.childrenProducts = this.customer?.childrenProducts || false;
      this.metamorphosis = this.customer?.metamorphosis || false;
      this.dieticianCare = this.customer?.dieticianCare || false;
    },
    methods: {
      startEdit () {
        this.textToEdit = this.customer?.mainStatus || '';
        this.edition = true;

        setTimeout(() => {
          this.$refs.editionInput?.focus();
        }, 100);
      },

      save () {
        if (!this.customer) return;
        this.loadingCustomerMainStatus = true;
        apiCustomers.setMainStatus(
            this.customer._id,
            {
              status: this.textToEdit,
              childrenProducts: this.childrenProducts,
              metamorphosis: this.metamorphosis,
              dieticianCare: this.dieticianCare,

            }
        ).then(() => {
          this.loadingCustomerMainStatus = false;
          this.textToEdit = this.customer?.mainStatus || '';
          this.edition = false;
        });
      },

      cancelEdition () {
        this.textToEdit = this.customer?.mainStatus || '';
        this.edition = false;
      }
    }
  };
</script>

<style scoped lang="scss">
  ._customerBaseStatus {
    background: #fff;
    margin-top: 16px;
    padding: 8px 16px;

    form {
      width: 100%;

      input {
        width: 100%;
        border: 1px solid;
      }
    }

    input {
      border-bottom: 1px solid;
      margin-right: 8px;
      padding: 2px 8px;
    }

    button {
      margin-left: 12px;
      text-align: left;

      &._btn {
        &-info {
          font-weight: bold;
          color: #547faa;
        }

        &-success {
          font-weight: bold;
          color: #47a18c;
        }
      }

      &:disabled {
        cursor: wait;
        color: gray;
      }
    }

  }
</style>