<template>
  <div class="wrap">

    <h2 v-if="campaign"
        class="primary--text mb-2">
      {{ campaign.title }}
    </h2>
    <v-row>
      <v-col>
        <treeselect
            placeholder="wybierz email" class="-border-radius-max"
            v-model="selectedEmails" :multiple="true"
            :async="true" :load-options="getEmailsToFilter"/>
      </v-col>
      <v-col>
        <treeselect
            placeholder="wybierz telefon" class="-border-radius-max"
            v-model="selectedPhones" :multiple="true"
            :async="true" :load-options="getPhonesToFilter"/>
      </v-col>
      <v-col>
        <v-text-field
            solo
            dense
            v-model="orderSearchString"
            label="nr zamówienia"
            required
            @keydown.enter.prevent="getFiltered"
        />
      </v-col>
      <v-col>
        <v-text-field
            solo
            dense
            v-model="customerNameSearchString"
            label="Imię Nazwisko"
            required
            @keydown.enter.prevent="getFiltered"
        />
      </v-col>
      <v-col>
        <button @click.p.prevent="isVisibleDatePicker = true">
          data ponownego kontaktu
          <template v-if="dates.length">
            <br>
            <span v-for="(date, i) in dates"
                  :key="`date-${i}`">
              {{ date }}
              <template v-if="i+1 < dates.length">-</template>
            </span>
          </template>
        </button>
        <v-overlay
            :value="isVisibleDatePicker"
            z-index="9"
        >
          <v-card light class="mw-80wv-i">
            <v-date-picker
                v-model="dates"
                range
                locale="pl"
            />
            <v-card-actions>
              <v-btn
                  color="gray lighten-2"
                  @click="dates = []"
                  small
              >
                wyczyść
              </v-btn>
              <v-btn
                  color="gray lighten-2"
                  @click="isVisibleDatePicker = false"
                  small
              >
                zamknij
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-overlay>
      </v-col>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="customersFiltered"
        :items-per-page="15"
        class="elevation-1"
        :loading="tableLoading"
    >
      <template v-slot:item.emails="{ item }">
        <p class="mb-0"
           v-for="(email, idx) of item.emails"
           :key="item._id+'email'+email._id+idx"
        >
          {{ email.email }}
        </p>
      </template>

      <template v-slot:item.phones="{ item }">
        <p class="mb-0"
           v-for="(phone, idx) of item.phones"
           :key="item._id+'phone'+phone._id+idx"
        >
          {{ phone.phone }}
        </p>
      </template>

      <template v-slot:item.status="{ item }">
        <div v-if="lastStatus(item.statuses)">
          {{ moment(lastStatus(item.statuses).createdAt).format('DD-MM-YYYY HH:mm:ss') }} <br>
          <p v-if="lastStatus(item.statuses).status"
             class="mb-0">
            {{ lastStatus(item.statuses).status.title }}
          </p>
          <p v-if="lastStatus(item.statuses).dateToRecall "
             class="mb-0">
            {{ moment(lastStatus(item.statuses).dateToRecall).zone('Europe/Warsaw').format('DD.MM.YYYY HH:mm') }}<br>
          </p>
          {{ lastStatus(item.statuses).description }}
          <br>
          portfel: {{ item.owner ? item.owner.email : '-' }} <br>
          mój rekord: {{ item.assigned ? item.assigned.email : '-' }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
            v-if="campaign"
            small
            :to="{name:'UserCustomer', params:{id:item._id, campaignId:campaign._id } }"
        >
          szczegóły
        </v-btn>
      </template>

    </v-data-table>

  </div>
</template>

<script>

  import ApiCustomers from '@/api/Customers';
  import ApiCustomerEmails from '@/api/CustomerEmails';
  import ApiCustomerPhones from '@/api/CustomerPhones';
  import { mapGetters } from 'vuex';
  import StatusesMixin from '@/mixins/StatusesMixin';
  import CampaignsMixin from '@/mixins/CampaignsMixin';

  const apiCustomers = new ApiCustomers();
  const apiCustomerEmails = new ApiCustomerEmails();
  const apiCustomerPhones = new ApiCustomerPhones();
  import moment from 'moment';

  export default {
    name: 'UserCustomers',
    mixins: [
      StatusesMixin,
      CampaignsMixin
    ],
    data: () => ({
      isVisibleDatePicker: false,
      dates: [],
      headers: [
        { text: 'Email', align: 'start', value: 'emails' },
        { text: 'Imię', value: 'firstName', },
        { text: 'Nazwisko', value: 'lastName', },
        { text: 'telefon', value: 'phones' },
        { text: 'Status', value: 'status' },
        { text: 'Akcje', value: 'actions', align: 'end' },
      ],
      tableLoading: false,
      /* *** filters *** */
      selectedEmails: [],
      selectedPhones: [],
      orderSearchString: '',
      customerNameSearchString: '',
    }),
    computed: {
      moment () {
        return moment;
      },
      ...mapGetters([
        'CUSTOMERS',
        'CAMPAIGN_FROM_MIXED_ID',
        'CAMPAIGNS'
      ]),
      customersFiltered () {
        if (!this.dates.length) {
          return this.CUSTOMERS;
        }

        return this.CUSTOMERS.filter(c => {
          const statuses = c.statuses;
          if (!statuses?.length) return false;

          const dateToRecall = statuses[statuses.length - 1]?.dateToRecall;

          if (!dateToRecall) return false;

          const dateStart = this.dates[0];
          const dateEnd = this.dates[1] ?? this.dates[0];

          const startTimestamp = moment(dateStart).startOf('day').unix();
          const endTimestamp = moment(dateEnd).endOf('day').unix();
          const recallTimestamp = moment(dateToRecall).unix();
          return startTimestamp < recallTimestamp && endTimestamp > recallTimestamp;
        });
      },
      lastStatus: function () {
        return (statuses) => {
          if (!statuses || statuses.length < 1) return false;
          const lastStatus = statuses[statuses.length - 1];
          return {
            ...lastStatus,
            status: this.STATUS(lastStatus.status)
          };
        };
      },
      /* *** redirect from inbound *** */
      campaignUuid () {
        return this.$route.query.campaignUuid;
      },
      campaignIdFromRoute () {
        if (this.$route.params.campaign_uuid) return this.$route.params.campaign_uuid;
        if (this.$route.query.campaignUuid) return this.$route.query.campaignUuid;
        if (!this.typeOfCustomers) return null;

        const campaignsPerType = {
          owner: 6,
          assigned: 7
        };

        const campaignUuid = campaignsPerType[this.typeOfCustomers];
        if (!campaignUuid) return null;
        return campaignUuid;
      },
      campaign () {
        return this.CAMPAIGN_FROM_MIXED_ID(this.campaignIdFromRoute);
      },
      /* *** *** */
      phone () {
        const phone = this.$route.query.phone;
        if (!phone || phone === 'anonymous') return '';
        return phone;
      },
      /* *** *** */
      typeOfCustomers () {
        return this.$route.params.type;
      },
    },
    watch: {
      selectedPhones (newVal, oldVal) {
        if (!newVal && !oldVal) return;
        if (newVal.length < 1 && oldVal.length < 1) return;
        this.getFiltered();
      },
      selectedEmails (newVal, oldVal) {
        if (!newVal && !oldVal) return;
        if (newVal.length < 1 && oldVal.length < 1) return;
        this.getFiltered();
      },
      orderSearchString (newVal, oldVal) {
        newVal = newVal.replace(/\\/g, '');
        newVal = newVal.replace(/\//g, '');
        if (!newVal && !oldVal) return;
        if (newVal.length < 1 && oldVal.length < 1) return;
        this.orderSearchString = newVal;
        if (this.orderSearchString && this.orderSearchString.length > 0 && this.orderSearchString.length < 4) return;
        this.getFiltered();
      },
      customerNameSearchString (newVal, oldVal) {
        newVal = newVal.replace(/\\/g, '');
        newVal = newVal.replace(/\//g, '');
        if (!newVal && !oldVal) return;
        if (newVal.length < 1 && oldVal.length < 1) return;
        this.customerNameSearchString = newVal;
        if (this.customerNameSearchString && this.customerNameSearchString.length > 0 && this.customerNameSearchString.length < 4) return;
        this.getFiltered();
      },
    },
    created () {
      let filters = {};
      if (this.phone) {
        filters.phoneString = this.phone;
      }
      if (this.$route.params.forAdmin) {
        filters.forAdmin = true;
      }

      this.tableLoading = true;
      apiCustomers.getPerType(this.typeOfCustomers, filters)
          .finally(() => {
            setTimeout(() => {
              this.tableLoading = false;
            }, 500);
          });
    },
    methods: {
      getFiltered () {
        const queryParams = {};
        let customers = [];

        if (this.selectedPhones.length > 0) customers = [...this.selectedPhones].map(el => el.split('|')[0]);
        if (this.selectedEmails.length > 0) customers = [...this.selectedEmails].map(el => el.split('|')[0]);
        customers = customers.filter((el, index) => el && customers.indexOf(el) === index);
        if (customers.length > 0) queryParams.customers = customers;
        if (this.orderSearchString) queryParams.orderSearchString = this.orderSearchString;
        if (this.customerNameSearchString) queryParams.customerNameSearchString = this.customerNameSearchString;

        this.tableLoading = true;
        apiCustomers.getPerType(this.typeOfCustomers, queryParams)
            .finally(() => {
              setTimeout(() => {
                this.tableLoading = false;
              }, 500);
            });
      },
      /* *** filters *** */
      getEmailsToFilter ({ searchQuery, callback }) {
        searchQuery = searchQuery.replace(/\\/g, '');
        searchQuery = searchQuery.replace(/\//g, '');
        if (searchQuery.length < 3) return callback(null, []);
        apiCustomerEmails.get(searchQuery).then(emails => {
          const options = emails.map(email => ({
            id: `${email.customer}|${email.email}`,
            label: email.email
          }));
          callback(null, options);
        });
      },
      getPhonesToFilter ({ searchQuery, callback }) {
        searchQuery = searchQuery.replace(/\\/g, '');
        searchQuery = searchQuery.replace(/\//g, '');
        if (searchQuery.length < 3) return callback(null, []);
        apiCustomerPhones.get(searchQuery).then(phones => {
          const options = phones.map(phone => ({
            id: `${phone.customer}|${phone.phone}`,
            label: phone.phone
          }));
          callback(null, options);
        });
      },
    }
  };
</script>

<style scoped lang="scss">
  ._loader {
    display: inline-block;
    position: relative;
    font-size: 100px;
    color: #ccc;
    width: 1em;
    height: 1em;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
  }

  ._inner {
    top: 40%;
    left: 40%;
    position: absolute;
    width: 40%;
    height: 40%;
    background: currentColor;
    animation: beat 0.6s infinite cubic-bezier(0.215, 0.61, 0.355, 1);

    &:after,
    &:before {
      content: ' ';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: currentColor;
    }

    &:before {
      left: -60%;
      border-radius: 50% 0 0 50%;
    }

    &:after {
      top: -60%;
      border-radius: 50% 50% 0 0;
    }
  }

  @keyframes beat {
    0% {
      transform: scale(0.9);
    }
    45% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.9);
    }
  }

</style>
