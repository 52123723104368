import { render, staticRenderFns } from "./AdminDownloadOrdersToAccept.vue?vue&type=template&id=0ccea047&scoped=true&"
import script from "./AdminDownloadOrdersToAccept.vue?vue&type=script&lang=js&"
export * from "./AdminDownloadOrdersToAccept.vue?vue&type=script&lang=js&"
import style0 from "./AdminDownloadOrdersToAccept.vue?vue&type=style&index=0&id=0ccea047&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ccea047",
  null
  
)

export default component.exports