import Vue from 'vue';
import Vuex from 'vuex';
import { parseMultipleData, parseSingleInMultipleData } from '@/utils/arrayParser';

Vue.use(Vuex);

export default {
    state: {
        customers: [],
        customersCareClub: [],
        customersSegments: [],
        customersPoll: [],
    },
    getters: {
        CUSTOMERS: state => state.customers,
        CUSTOMER: state => (id) => {
            if (id._id) id = id._id;
            if (!state.customers || state.customers.length < 1) return null;

            // return state.customers.find(el => el._id === id);
            const customerPerId = state.customers.find(el => el._id === id);
            if (customerPerId) return customerPerId;
            const customerPerSubId = state.customers.find(el => el.subId === id);
            if (customerPerSubId) return customerPerSubId;

            for (const customer of state.customers) {
                if (customer.matchedChildren && customer.matchedChildren.indexOf(id) > -1) {
                    return customer;
                }
            }
            return null;
            // const customerPerMatchedParentId = state.customers.find(el => el.matchedParent === id);
            // return customerPerMatchedParentId;
        },
        CUSTOMER_CARE_CLUB: state => (id) => {
            if (id._id) id = id._id;
            if (!state.customersCareClub || state.customersCareClub.length < 1) return null;
            const customerPerId = state.customersCareClub.find(el => el._id === id);
            if (customerPerId) return customerPerId;
            return null;
        },
        CUSTOMER_POLL: state => (id) => {
            if (id._id) id = id._id;
            if (!state.customersPoll || state.customersPoll.length < 1) return null;
            const customerPerId = state.customersPoll.find(el => el._id === id);
            if (customerPerId) return customerPerId;
            return null;
        },
        CUSTOMER_SEGMENT: state => (id) => {
            if (id._id) id = id._id;
            if (!state.customersSegments || state.customersSegments.length < 1) return null;
            const customerPerId = state.customersSegments.find(el => el._id === id);
            if (customerPerId) return customerPerId;
            return null;
        },
    },
    mutations: {
        SET_ALL_CUSTOMER: (state, payload) => state.customers = payload,
        SET_CUSTOMERS: (state, payload) => {
            state.customers = parseMultipleData(state.customers, payload);
        },
        SET_CUSTOMER: (state, payload) => state.customers = parseSingleInMultipleData(state.customers, payload),
        CUSTOMER_CARE_CLUB: (state, payload) => state.customersCareClub = parseSingleInMultipleData(state.customersCareClub, payload),
        CUSTOMER_POLL: (state, payload) => state.customersPoll = parseSingleInMultipleData(state.customersPoll, payload),
        CUSTOMER_SEGMENT: (state, payload) => state.customersSegments = parseSingleInMultipleData(state.customersSegments, payload),
    },
    actions: {
        setCustomers ({ commit }, payload) {
            commit('SET_ALL_CUSTOMER', payload);
        },
        addCustomers ({ commit }, payload) {
            commit('SET_CUSTOMER', payload);
        },
        setCustomer ({ commit }, payload) {
            commit('SET_CUSTOMER', payload);
        },
        setCustomerCareClub ({ commit }, payload) {
            commit('CUSTOMER_CARE_CLUB', payload);
        },
        setCustomerSegment ({ commit }, payload) {
            commit('CUSTOMER_SEGMENT', payload);
        },
        setCustomerPoll ({ commit }, payload) {
            commit('CUSTOMER_POLL', payload);
        },
    }
};
