<template>
  <v-app>
    <transition
        :name="transitionName"
        :enter-active-class="enterActive"
        :leave-active-class="leaveActive"
        mode="out-in"
    >
      <router-view/>
    </transition>
  </v-app>
</template>

<script>
  import _ from 'lodash';

  // import Navbar from '@/components/layout/Navbar.vue';
  // import Footer from '@/components/layout/Footer.vue';
  // import Particle from '@/components/layout/Particle.vue';

  const DEFAULT_TRANSITION = 'fade';
  const DEFAULT_ENTER_ACTIVE_CLASS = 'animated fadeIn';
  const DEFAULT_LEAVE_ACTIVE_CLASS = 'animated fadeOut';

  export default {
    name: 'App',
    components: {
      // Navbar: Navbar,
      // Particle,
      // Footer
    },
    data: () => ({
      /* *** ui *** */
      transitionName: DEFAULT_TRANSITION,
      leaveActive: DEFAULT_LEAVE_ACTIVE_CLASS,
      enterActive: DEFAULT_ENTER_ACTIVE_CLASS,

    }),
    created () {
      this.$router.beforeEach((to, from, next) => {
        if (!_.isEmpty(to.meta)) {
          if (to.meta.transitionName) {
            this.transitionName = to.meta.transitionName;
          }

          if (to.meta.enterActive) {
            this.enterActive = to.meta.enterActive;
          }

          if (to.meta.leaveActive) {
            this.leaveActive = to.meta.leaveActive;
          }
        }

        if (!to.meta.requiresAuth) {
          this.resetTransition();
        }
        next();
      });
    },

    methods: {
      resetTransition () {
        this.transitionName = DEFAULT_TRANSITION;
        this.enterActive = DEFAULT_ENTER_ACTIVE_CLASS;
        this.leaveActive = DEFAULT_LEAVE_ACTIVE_CLASS;
      },

    },
  };
</script>

<style lang="scss">
  //@import '@/assets/scss/base/base.scss';

  html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .v-input--radio-group__input {
    strong {
      font-weight: 400;
      font-size: 12px;
    }

    .v-radio {
      label {
        justify-content: space-between;
      }
    }
  }

  .mh-25vh {
    max-height: 25vh;
    overflow-y: auto;
  }

  .mh-60vh {
    max-height: 60vh;
    overflow-y: auto;
  }

  .mh-50vh {
    max-height: 50vh;
    overflow-y: auto;
  }

  .mh-60vh {
    max-height: 60vh;
    overflow-y: auto;
  }

  .mh-80vh {
    max-height: 80vh;
    overflow-y: auto;
  }

  .mw-80wv-i {
    max-width: 80vw !important;
  }

  .white-space-nowrap {
    white-space: nowrap;
  }

  .v-radio.v-radio--is-disabled {
    & > .v-input--selection-controls__input {
      & > i {
        color: transparent !important;
      }
    }
  }

  body {
    .v-application .blue.lighten-3 {
      background-color: #5e95cc !important;

    }

    .v-sheet.v-card {
      border-radius: 0 !important;
    }

    .v-application .blue--text {
      color: #547faa !important;
    }

    .v-application .blue-grey.lighten-5 {
      background: #f0f0ed !important;
    }

    .v-application .primary--text {
      color: #547faa !important;
    }

    .v-application .teal,
    .v-application .green.accent-4 {
      background: #47a18c !important;
    }

    .v-application .orange.darken-3 {
      background: #dd6d3b !important;
    }

    .v-application .primary.lighten-3 {
      background-color: #5e95cc !important;
    }

    .v-btn:not(.v-size--x-small):not(.v-btn--text) {
      border-radius: 12px;
      padding: 1px 16px 0 !important;
    }

    .theme--light.v-btn.v-btn--has-bg{
      background-color: #dfdfd6;
    }
    
    .v-btn:not(.v-btn--round).v-size--x-small {
      padding-top: 2px;
      border-radius: 10px;
    }
  }


  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;

    // enable custom scrollbar look for desktop
    scrollbar-width: thin;
    scrollbar-color: #000000;


    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, .1);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #000;
    }
  }
</style>
