import Vue from 'vue';
import Vuex from 'vuex';
import { parseMultipleData, parseSingleInMultipleData } from '@/utils/arrayParser';

Vue.use(Vuex);

export default {
  state: {
    statuses: [],
  },
  getters: {
    STATUSES: state => state.statuses,
    STATUS: state => (id) => state.statuses.find(el => el._id === id),
  },
  mutations: {
    SET_ALL_STATUS: (state, payload) => state.statuses = payload,
    SET_STATUSES: (state, payload) => {
      state.statuses = parseMultipleData(state.statuses, payload);
    },
    SET_STATUS: (state, payload) => state.statuses = parseSingleInMultipleData(state.statuses, payload)
  },
  actions: {
    setStatuses ({ commit }, payload) {
      commit('SET_ALL_STATUS', payload);
    },
  }
};
