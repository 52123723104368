import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '@/router';

Vue.use(Vuex);

export default {
  state: {
    authState: false,
    authUser: {},
  },
  getters: {
    AUTH_USER: state => state.authUser,
    IS_AUTHORIZED: state => state.authState,
  },
  mutations: {
    ASSIGN_USER_DATA: (state, payload) => {
      state.authUser = payload;
    },
    TOGGLE_AUTH_STATE: (state, payload) => {
      state.authState = payload;
    },
    RESET_STATE: state => {
      state.authState = false;
      state.authUser = {};
    }
  },
  actions: {
    saveUserData: ({ commit }, payload) => {
      commit('ASSIGN_USER_DATA', payload);
    },
    toggleAuthState: ({ commit }, payload) => {
      commit('TOGGLE_AUTH_STATE', payload);
    },
    deleteUserAccount: ({ commit }) => {
      axios.delete('/api/user/current').then(() => {
        commit('RESET_STATE');
        localStorage.clear();
        router.push({ name: 'Login' });
      });
    }
  }
};
