<template>
  <div class="page login">
    <section class="section section__landing">
      <div class="section__heading">
        <span class="section__title">Register Here</span>
      </div>
      <div class="section__content">
        <p class="section__lead">We hope you will enjoy our application!</p>

        <form @submit.prevent="handleSubmit" class="form">
          <span class="form__lead">
            We always welcome new astros!
          </span>
          <div class="form__input-group">
            <input
                type="email"
                name="email"
                class="form__control"
                placeholder="Enter Email"
                required
                v-model.trim="email"
            >
            <label for="email" class="form__label">Email</label>
          </div>
          <div class="form__input-group">
            <input
                type="password"
                name="password"
                class="form__control"
                placeholder="Enter Password"
                pattern=".{5,15}"
                title="Password must be between 5 and 15 characters"
                required
                v-model.trim="password"
            >
            <label for="password" class="form__label">Password</label>
          </div>
          <div class="form__info-group">
            <span>Already have an account?</span>
            <router-link to="/login" class="form__link btn btn--rounded">Login</router-link>
          </div>
          <Error :errors="errors"/>
          <button type="submit" class="form__submit">Register</button>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
  import axios from 'axios';
  import Error from '@/components/errors/Error.vue';
  import { setAuthToken } from '@/utils/authToken';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'Register',
    components: {
      Error,
    },
    data: function () {
      return {
        email: '',
        password: '',
        errors: []
      };
    },
    computed: {
      ...mapGetters(['GET_SOCKET'])
    },
    methods: {
      ...mapActions(['saveUserData', 'toggleAuthState']),
      handleSubmit () {
        this.errors = [];

        if (this.email && this.password) {
          axios
              .post('/api/auth/register', {
                email: this.email,
                password: this.password
              })
              .then(res => {
                if (res.data.errors) {
                  for (const error of res.data.errors) {
                    const [key] = Object.keys(error);
                    const [value] = Object.values(error);
                    this.errors.push({
                      key,
                      value
                    });
                  }
                } else {
                  localStorage.setItem('authToken', res.data.token);
                  this.$store.dispatch('toggleAuthState', true);
                  this.$store.dispatch('saveUserData', true);

                  setAuthToken(res.data.token);

                  this.$router.push({
                    name: 'UserDashboard',
                    params: { handle: res.data.user.id }
                  });
                }
              });
        }

        setTimeout(() => {
          this.errors = [];
        }, 1500);
      }
    },
    mounted () {}
  };
</script>


<style lang="scss">
  @import '@/assets/scss/components/form.scss';
</style>
