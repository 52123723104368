import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
/* *** modules *** */
import Auth from './_auth';
import Socket from './_socket';
import Orders from './_orders';
import Statuses from './_statuses';
import Products from './_products';
import Customers from './_customers';
import Countries from './_countries';
import Carriers from './_carriers';
import Payments from './_payments';
import Calls from './_calls';
import UserActivity from './_user-activities';
import Campaigns from './_campaigns';
import Errors from './_errors';
import Users from './_users';
import OrdersUpsell from './_orders-upsell';

export default new Vuex.Store({
    modules: {
        Auth,
        Socket,
        Orders,
        Statuses,
        Products,
        Customers,
        Countries,
        Carriers,
        Payments,
        Calls,
        UserActivity,
        Campaigns,
        Errors,
        Users,
        OrdersUpsell,
    }
});
