import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
//todo
export default {
    state: {
        users: [],
    },
    getters: {
        USERS: state => state.users,
        USER: state => (id) => state.users.find(el => el._id === id),
    },
    mutations: {
        SET_ALL_USER: (state, payload) => state.users = payload,
    },
    actions: {
        setUsers ({ commit }, payload) {
            if (payload) commit('SET_ALL_USER', payload);
        },
    }
};
