import Vue from 'vue';
import Vuex from 'vuex';
import { parseMultipleData, parseSingleInMultipleData } from '@/utils/arrayParser';

Vue.use(Vuex);

export default {
  state: {
    payments: [],
  },
  getters: {
    PAYMENTS: state => state.payments,
    PAYMENT: state => (id) => state.payments.find(el => el._id === id),
  },
  mutations: {
    SET_ALL_PAYMENT: (state, payload) => state.payments = payload,
    SET_PAYMENTS: (state, payload) => {
      state.payments = parseMultipleData(state.payments, payload);
    },
    SET_PAYMENT: (state, payload) => state.payments = parseSingleInMultipleData(state.payments, payload)
  },
  actions: {
    setPayments ({ commit }, payload) {
      if (payload) commit('SET_ALL_PAYMENT', payload);
    },
  }
};
