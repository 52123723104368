import ApiOrdersUpsell from '@/api/OrdersUpsell';

const apiOrdersUpsell = new ApiOrdersUpsell();

import { mapGetters } from 'vuex';

export default {
    data: () => ({
        loaderOrdersUpsell: false,
    }),
    computed: {
        ...mapGetters(['ORDER_UPSELL']),
    },
    methods: {
        getOrderUpsell (orderId) {
            this.loaderOrdersUpsell = true;
            apiOrdersUpsell.get(orderId).finally(() => this.loaderOrdersUpsell = false);
        }
    }
};