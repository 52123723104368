import Vue from 'vue';
import Vuex from 'vuex';
import { parseMultipleData, parseSingleInMultipleData } from '@/utils/arrayParser';

Vue.use(Vuex);

export default {
  state: {
    carriers: [],
  },
  getters: {
    CARRIERS: state => state.carriers,
    CARRIER: state => (id) => state.carriers.find(el => el._id === id),
  },
  mutations: {
    SET_ALL_CARRIER: (state, payload) => state.carriers = payload,
    SET_CARRIERS: (state, payload) => {
      state.carriers = parseMultipleData(state.carriers, payload);
    },
    SET_CARRIER: (state, payload) => state.carriers = parseSingleInMultipleData(state.carriers, payload)
  },
  actions: {
    setCarriers ({ commit }, payload) {
      if (payload) commit('SET_ALL_CARRIER', payload);
    },
  }
};
