<template>
  <div class="wrap">
    <v-container fluid class="_nav">
      <template v-if="AUTH_USER.email==='anton@healthlabs.pl'">
        <!--        <v-btn
                    class="_nav_link"
                    @click.prevent="assignCustomerPhones"
                    color="warning"
                    text>
                  assignCustomerPhones
                </v-btn>-->

        <v-btn
            class="_nav_link"
            @click.prevent="testCallToMigrationController"
            color="warning"
            text>
          testCallToMigrationController
        </v-btn>

        <!--        <v-btn
                    class="_nav_link"
                    @click.prevent="testScoring"
                    color="warning"
                    text>
                  test scoring
                </v-btn>-->

        <!--        <v-btn
                    class="_nav_link"
                    @click.prevent="writeNonContactCustomers"
                    color="warning"
                    text>
                  writeNonContactCustomers
                </v-btn>-->

        <!--        <v-btn-->
        <!--            class="_nav_link"-->
        <!--            @click.prevent="separateCustomer"-->
        <!--            color="warning"-->
        <!--            text>-->
        <!--          separateCustomer-->
        <!--        </v-btn>-->
        <!--    
          
        <v-btn
            class="_nav_link"
            @click.prevent="netTelCallSimulate"
            color="warning"
            text>
          net tel call simulation
        </v-btn>
          <v-btn
                  @click.prevent="migrateNewOrders"
                  class="my-3"
              >
                migrateNewOrders
              </v-btn>
              <br>
              <v-btn
                  @click.prevent="migrateOrderAddresses"
                  class="my-3"
              >
                migrateOrderAddresses
              </v-btn>
              <br>
              <v-btn
                  @click.prevent="migrateOrderStatusesChanges"
                  class="my-3"
              >
                migrateOrderStatusesChanges
              </v-btn>
              <br>
              <v-btn
                  @click.prevent="migrateNewOrderProducts"
                  class="my-3"
              >
                migrateNewOrderProducts
              </v-btn>
              <br>
              <v-btn
                  @click.prevent="migrateNewOrderShippingTracking"
                  class="my-3"
              >
                migrateNewOrderShippingTracking
              </v-btn>
              <br>
              <v-btn
                  @click.prevent="migrateNewOrderPayments"
                  class="my-3"
              >
                migrateNewOrderPayments
              </v-btn>
              <br>
              <v-btn
                  @click.prevent="assignStatusesToOrders"
                  class="my-3"
                  color="warning"
              >
                assignStatusesToOrders
              </v-btn>
              <br>
              <v-btn
                  @click.prevent="assignCarriersToOrders"
                  class="my-3"
                  color="warning"
              >
                assignCarriersToOrders
              </v-btn>
              <br>
              <v-btn
                  @click.prevent="migrateCustomers"
                  class="my-3"
              >
                migrateCustomers
              </v-btn>
              <br>
              <v-btn
                  @click.prevent="migrateAll"
                  class="my-3"
                  color="warning"
              >
                migrateAll
              </v-btn>-->
      </template>

      <AdminNavigation v-if="AUTH_USER.accountType===3"/>
    </v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="_card-header yellow lighten-3 justify-space-between">
            Kampania HotLead

            <v-btn text
                   @click.prevent="redirectToFirstActiveScoredOrder()"
                   :loading="loadingNextOrder"
                   :disabled="!IS_ACTIVE_USER"
            >
              Start
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col>
        <v-card
        >
          <v-card-title class="_card-header green lighten-3  justify-space-between">
            Portfel
            <v-btn text
                   :loading="loading"
                   :to="{name:'UserCustomers', params:{type:'owner' } }">
              pokaż
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title class="_card-header blue lighten-3 justify-space-between">
            Moje rekordy
            <v-btn
                text
                :loading="loading"
                :to="{name:'UserCustomers', params:{ type:'assigned' } }"
            >
              pokaż
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="_card-header blue lighten-3 justify-space-between">
            Wyszukiwarka klientów
            <v-btn
                text
                :loading="loading"
                :to="{name:'UserCustomers', params:{type:'all' , campaignId:'610beb584e76c12618ff2c91'} }"
            >
              pokaż
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-title class="_card-header blue lighten-3 justify-space-between">
            Wyszukiwarka zamówień
            <v-btn
                text
                :loading="loading"
                :to="{ name:'UserOrders' }"
            >
              pokaż
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-title class="_card-header orange lighten-3 justify-space-between">
            Nowe
            <v-btn
                :to="{name:'AdminNewOrder'}"
                :class="{'_active': $route.name==='AdminNewOrder'}"
                text>
              pokaż
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';

  import ErrorsMixin from '@/mixins/ErrorsMixin';
  import ScoredOrdersMixin from '@/mixins/ScoredOrdersMixin';
  import AdminNavigation from '@/components/AdminNavigation';

  export default {
    name: 'UserDashboard',
    components: {
      AdminNavigation,
    },
    mixins: [
      ErrorsMixin,
      ScoredOrdersMixin,
    ],
    data: () => ({
      loading: false,
    }),
    computed: {
      ...mapGetters([
        'IS_ACTIVE_USER',
        'AUTH_USER',
      ])
    },
    methods: {
      testCallToMigrationController: () => axios.post('/api/migrator/testCallToMigrationController'),
    }
  };
</script>

<style scoped lang="scss">
  ._nav {
    &_link {
      border-bottom: 1px solid rgb(118, 118, 118);
      border-radius: 0;
      margin: 0 16px 16px;
      transition: border-bottom-color ease-in-out .3s;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        border-color: #000;
      }
    }
  }

  ._card-header {
    font-size: 14px;
    padding: 8px;

    a {
      padding: 0 !important;
      font-size: 12px;
    }
  }
</style>
