import UserTemplate from '@/templates/UserTemplate';

const routes = [

  {
    path: '/orders',
    name: 'Orders',
    component: UserTemplate,
    children: [
      {
        path: '/orders/:campaignUuid/:orderId',
        name: 'UserOrder',
        component: () => import('@/views/orders/Order'),
        props: true,
        meta: {
          requiresAuth: true,
          transitionName: 'router-anim',
          enterActive: 'animated fadeIn'
        }
      }, {
        path: '/orders/customer/:campaignUuid/:orderId',
        name: 'UserCustomerOrder',
        component: () => import('@/views/orders/UserCustomerOrder'),
        props: true,
        meta: {
          requiresAuth: true,
          transitionName: 'router-anim',
          enterActive: 'animated fadeIn'
        }
      },
      {
        path: '/orders',
        name: 'UserOrders',
        component: () => import('@/views/user/UserOrders.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          transitionName: 'router-anim',
          enterActive: 'animated fadeIn'
        }
      },
    ]
  }
];

export default routes;
