import axios from 'axios';
import store from '@/store';

const CancelToken = axios.CancelToken;
let source = {};

export default class {
  baseUrl = '/api/campaigns';

  get = () => new Promise(() => {
    let cancelTokenKey = 'campaigns_' + 'get';
    if (source[cancelTokenKey]) source[cancelTokenKey].cancel();
    source[cancelTokenKey] = CancelToken.source();

    axios.get(this.baseUrl, {
      cancelToken: source[cancelTokenKey].token
    }).then(({ data }) => store.dispatch('setCampaigns', data.campaigns));
  });
}
