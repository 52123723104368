import axios from 'axios';
import store from '@/store';

const CancelToken = axios.CancelToken;
let source = {};

export default class {
    baseUrl = '/api/customer-care-club';

    get = async (_id) => {
        let cancelTokenKey = 'customers_careClub' + 'get' + _id;
        if (source[cancelTokenKey]) source[cancelTokenKey].cancel();
        source[cancelTokenKey] = CancelToken.source();

        let url = `${this.baseUrl}/${_id}`;

        await axios.get(url, {
            cancelToken: source[cancelTokenKey].token
        })
            .then(({ data }) => {
                store.dispatch('setCustomerCareClub', data);
            });
    };
}