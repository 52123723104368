<template>
  <v-overlay
      :value="isVisible"
      z-index="9"
  >
    <v-card light class="mw-80wv-i _customCard">
      <v-card-title
          class="lighten-3 pt-2 pb-0 blue white--text"
      >
        Care Club
      </v-card-title>

      <v-card-text class="_customCard_body">
        <ul>
          <li v-for="(tab, index) in customersCareClubData"
              :key="`tab${index}`"
              class="_tab"
              :class="{
                _active: index === activeIndex,
              }">
            <h2 @click="activeIndex = index">
              {{ tab.email }}
              <strong class="_success"
                      v-if="tab.balance && tab.balance.customer_level !== undefined && !tab.balance.is_guest">
                poziom - {{ tab.balance.customer_level || '0' }} 
              </strong>
            </h2>
            <div class="_tab_content">
              <div>
                <h3 class="pt-2">Care Coins</h3>
                <h4 v-if="tab.balance && tab.balance.is_guest"
                    class="_danger _coinsDescription pb-3">
                  jest jako gość - nie ma konta w Care Club
                </h4>
                <v-row>
                  <v-col class="py-1">
                    <span class="_coinsDescription"
                          v-if="tab.balance">
                      dostępne:
                      <strong class="_success">{{ tab.balance.available_coins || '0' }} </strong>
                      <CareClubCoinIcon class="_coinIcon"/>
                    </span>

                  </v-col>
                  <v-col class="py-1">
                    <span class="_coinsDescription"
                          v-if="tab.balance">
                      wykorzystane:
                      <strong class="_info">{{ tab.balance.used_coins || '0' }} </strong>
                      <CareClubCoinIcon class="_coinIcon"/>
                    </span>

                  </v-col>
                  <v-col class="py-1">
                    <span class="_coinsDescription"
                          v-if="tab.balance">
                      oczekują na akceptację:
                      <strong class="_warning">{{ tab.balance.coinback_approval_coins || '0' }} </strong>
                      <CareClubCoinIcon class="_coinIcon"/>
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-1">
                    <span class="_coinsDescription"
                          v-if="tab.balance">
                      zablokowane:
                      <strong class="_danger">{{ tab.balance.cart_locked_coins || '0' }} </strong>
                      <CareClubCoinIcon class="_coinIcon"/>
                    </span>
                  </v-col>

                  <v-col class="py-1">
                    <span class="_coinsDescription"
                          v-if="tab.balance">
                      anulowane:
                      <strong class="_danger">{{ tab.balance.coinback_canceled_coins || '0' }} </strong>
                      <CareClubCoinIcon class="_coinIcon"/>
                    </span>

                  </v-col>
                  <v-col class="py-1">
                    <span class="_coinsDescription"
                          v-if="tab.balance">
                      wygaśnięte:
                      <strong class="_danger">{{ tab.balance.expired_coins || '0' }} </strong>
                      <CareClubCoinIcon class="_coinIcon"/>
                    </span>
                  </v-col>
                </v-row>
              </div>
              <div>
                <h3>Kończące się Care Coins</h3>
                <ol>
                  <li v-for="(pointsItem, pointsIndex) in tab.points"
                      :key="`tab${index}_points_${pointsIndex}`">
                    <span class="_coinsDescription">
                      <strong>{{ pointsItem.coins }} </strong>
                      <CareClubCoinIcon class="_coinIcon"/>
                      - {{ pointsItem.expiryDate }}
                    </span>
                  </li>
                </ol>
              </div>
              <div>
                <h3>Zadania</h3>
                <h4 class="_info">Aktywne</h4>
                <ul class="_quests">
                  <li v-for="(questsItem, questsIndex) in tab.quests.active_quests"
                      :key="`tab${index}_quests_${questsIndex}`"
                      class="_quest">
                    <span class="_quest_coins">
                      {{ questsItem.coins }}
                      <CareClubCoinIcon class="_coinIcon"/>
                    </span>
                    <h4 class="_quest_title ">{{ questsItem.name }}</h4>
                    <span class="_quest_description">{{ questsItem.description }}</span>
                    <strong v-if="questsItem.level"
                            class="_quest_level">Dostępny na poziomie: {{ questsItem.level }}</strong>
                    <strong v-if="questsItem.date_end"
                            class="_quest_end">Aktywny do: {{ questsItem.date_end }}</strong>
                  </li>
                </ul>
                <h4 class="_success">Zrobione</h4>
                <ul class="_quests">
                  <li v-for="(questsItem, questsIndex) in tab.quests.done_quests"
                      :key="`tab${index}_quests_${questsIndex}`"
                      class="_quest">
                    <span class="_quest_coins">
                      {{ questsItem.coins }}
                      <CareClubCoinIcon class="_coinIcon"/>
                    </span>
                    <h4 class="_quest_title">{{ questsItem.name }}</h4>
                    <span class="_quest_description">{{ questsItem.description }}</span>
                    <strong v-if="questsItem.level"
                            class="_quest_level">Dostępny na poziomie: {{ questsItem.level }}</strong>
                    <strong v-if="questsItem.date_end"
                            class="_quest_end">Aktywny do: {{ questsItem.date_end }}</strong>
                  </li>
                </ul>
                <h4 class="_warning">Dostępne w przyszłości</h4>
                <ul class="_quests">
                  <li v-for="(questsItem, questsIndex) in tab.quests.next_quests"
                      :key="`tab${index}_quests_${questsIndex}`"
                      class="_quest">
                    <span class="_quest_coins">
                      {{ questsItem.coins }}
                      <CareClubCoinIcon class="_coinIcon"/>
                    </span>
                    <h4 class="_quest_title">{{ questsItem.name }}</h4>
                    <span class="_quest_description">{{ questsItem.description }}</span>
                    <strong v-if="questsItem.level"
                            class="_quest_level">Dostępny na poziomie: {{ questsItem.level }}</strong>
                    <strong v-if="questsItem.date_end"
                            class="_quest_end">Aktywny do: {{ questsItem.date_end }}</strong>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
            color="gray lighten-2"
            @click="setIsVisible(false)"
            small
        >
          zamkńij
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
  import { mapGetters } from 'vuex';
  import CareClubCoinIcon from '@/components/imgs/CareClubCoinIcon';

  export default {
    name: 'CustomerCareClubModal',
    props: {
      customerId: {
        type: String,
        required: false
      },
      isVisible: {
        type: Boolean,
        required: true,
      },
      setIsVisible: {
        type: Function,
        required: true,
      },
    },
    components: {
      CareClubCoinIcon,
    },
    data: () => ({
      activeIndex: 0,
    }),
    computed: {
      ...mapGetters([
        'CUSTOMER_CARE_CLUB',
      ]),
      customersCareClub () {
        if (!this.customerId) return null;
        return this.CUSTOMER_CARE_CLUB(this.customerId);
      },
      customersCareClubData () {
        if (!this.customersCareClub) return [];
        return this.customersCareClub.data || [];
      },
    },
    methods: {
      //
    }
  };
</script>

<style scoped lang="scss">
  ._customCard {
    width: 80vw;
    overflow: auto;

    &_body {
      padding-top: 16px;
      max-height: 80vh;
      min-height: 60vh;
      transition: height .4s ease-in-out;
    }
  }

  ._coinsDescription {
    overflow: hidden;
    display: block;
  }

  ._tab {
    list-style: none;
    padding: 8px 0;

    &:before {
      display: block;
      content: "";
      width: 12px;
      height: 12px;

      border-left: 1px solid;
      border-bottom: 1px solid;
      transform: rotate(-45deg);
      float: left;
      margin-right: 12px;
    }

    h2 {
      cursor: pointer;
    }

    h3 {
      padding: 24px 0 12px;
      color: #413e38;
    }

    h4 {
      padding: 4px 0 6px;
      color: #413e38;
    }

    strong {
      color: #413e38;
    }

    &_content {
      height: 0;
      overflow: hidden;
    }

    &._active {
      h2 {
        color: #00B7FF;
      }

      ._tab_content {
        transition: height .3s ease-in-out, padding .3s ease-in-out;
        height: 70vh;
        padding: 8px 24px;
        overflow: auto;
      }

      &:before {
        border-color: #00B7FF;
        transform: rotate(135deg) translate(100%);
      }
    }
  }


  ._success {
    color: #42b983 !important;

    &_strongChild {
      strong {
        color: #42b983;
      }
    }
  }

  ._info {
    color: #3c9adf !important;

    &_strongChild {
      strong {
        color: #3c9adf;
      }
    }
  }

  ._warning {
    color: #b66d00 !important;

    &_strongChild {
      strong {
        color: #b66d00;
      }
    }
  }

  ._danger {
    color: #ea4436 !important;

    &_strongChild {
      strong {
        color: #ea4436;
      }
    }
  }

  ._coinIcon {
    height: 1em;
    width: 1em;
    transform: translate(0px, 2px);
  }

  ._quests {
    display: flex;
    flex-wrap: wrap;
    padding: 0 8px;
  }

  ._quest {
    flex: 0 0 32%;
    width: 33%;
    display: flex;
    flex-direction: column;
    margin-right: 2%;
    margin-bottom: 16px;
    background: #F7F7F5;
    padding: 20px 24px;
    border-radius: 4px;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &_title {
      padding: 0 8px;
    }

    &_coins {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 12px;
    }
  }
</style>