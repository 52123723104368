// @/mixins/CustomersMixin
import ApiCustomers from '@/api/Customers';
import ApiCustomerEmails from '@/api/CustomerEmails';
import ApiCustomerPhones from '@/api/CustomerPhones';
import ApiCustomerCareClub from '@/api/CustomerCareClub';
import ApiCustomerPoll from '@/api/CustomerPoll';
import ApiCustomerSegment from '@/api/CustomerSegment';

const apiCustomers = new ApiCustomers();
const apiCustomerEmails = new ApiCustomerEmails();
const apiCustomerPhones = new ApiCustomerPhones();
const apiCustomerCareClub = new ApiCustomerCareClub();
const apiCustomerSegment = new ApiCustomerSegment();
const apiCustomerPoll = new ApiCustomerPoll();

import { mapGetters } from 'vuex';
import { isEmpty } from '@/utils/isEmpty';

export default {
    data: () => ({
        loaderCustomers: false,

        customerMainEmailId: null,
        newEmail: '',
        emailsToDisable: [],
        loadingEmailChanges: false,

        customerMainPhoneId: null,
        newPhone: '',
        phonesToDisable: [],
        loadingPhoneChanges: false,
        loaderCustomersSegment: false,
    }),

    computed: {
        ...mapGetters([
            'CUSTOMERS',
            'CUSTOMER'
        ]),

        /* *** *** */
        customerId () {
            if (!this.customer) return null;
            return this.customer._id;
        },
        customerEmails () {
            if (isEmpty(this.customer)) return [];
            return this.customer.emails;
        },
        customerMainEmail () {
            if (!this.customerEmails) return {};
            const mainEmail = this.customerEmails
                .find(el => el.isMain);

            if (!mainEmail) return this.customerEmails[0];
            this.setMainEmailId(mainEmail._id);
            return mainEmail;
        },
        customerEmailsForSelect () {
            if (!this.customerMainEmail) return [];
            if (!this.customerMainEmail) return [];
            const mainId = this.customerMainEmail._id;
            return this.customerEmails.map(el => ({
                id: el._id,
                label: `${el.email}`,
                key: `email${el._id}`,
                disabled: el.disabled,
                primaryTextClass: el._id === mainId,
                // todo disable customer emails
                // todo add new customer emails
                // todo undisable customer emails
            }));
        },

        customerPhones () {
            if (!this.customer) return [];
            return this.customer.phones;
        },
        customerMainPhone () {
            if (!this.customerPhones) return {};
            const mainPhone = this.customerPhones
                .find(el => el.isMain);
            if (!mainPhone) return this.customerPhones[this.customerPhones.length - 1];
            this.setMainPhoneId(mainPhone._id);
            return mainPhone;
        },

        customerPhonesForSelect () {
            if (!this.customerMainPhone) return [];
            if (!this.customerPhones) return [];
            const mainId = this.customerMainPhone._id;
            return this.customerPhones.map(el => ({
                id: el._id,
                label: `${el.phone}`,
                key: `phone${el._id}`,
                disabled: el.disabled,
                primaryTextClass: el._id === mainId,
                isMain: el.mainId,
                // todo add new customer phone
                // todo undisable customer phone
            })).sort((a) => a.disabled ? 1 : -1)
                .sort(a => a.isMain ? -1 : 1);
        },
        /***********/
        // emailInDisabledIndex: () => function (id) {
        //   return this.emailsToDisable.findIndex(el => el === id);
        // },
        // isEmailInDisabled: () => function (id) {
        //   return this.emailInDisabledIndex(id) === -1;
        // },
    },

    methods: {
        async getCustomer (_id, orderId) {
            // console.log('sss');
            if (!_id) return;
            if (_id._id) _id = _id._id;
            this.loaderCustomers = true;
            await apiCustomers.getPerId(_id, orderId)
                .then(() => this.loaderCustomers = false)
                .finally(() => this.loaderCustomers = false);
        },

        /* *** *** */
        setMainEmailId (id) {
            this.customerMainEmailId = id;
        },
        disableEmail (id) {
            const index = this.emailsToDisable.findIndex(el => el === id);
            if (index > -1) return this.emailsToDisable.splice(index, 1);
            this.emailsToDisable.push(id);
        },
        saveEmailChanges () {
            this.loadingEmailChanges = true;
            apiCustomerEmails.updateCustomerEmails(
                this.newEmail,
                this.customerMainEmailId,
                this.emailsToDisable,
                this.customerId
            ).then(() => {
                this.loadingEmailChanges = false;
            }).catch(() => this.loadingEmailChanges = false);
        },
        /* *** *** */
        setMainPhoneId (id) {
            this.customerMainPhoneId = id;
        },
        disablePhone (id) {
            const index = this.phonesToDisable.findIndex(el => el === id);
            if (index > -1) return this.phonesToDisable.splice(index, 1);
            this.phonesToDisable.push(id);
        },
        savePhoneChanges () {
            this.loadingPhoneChanges = true;
            apiCustomerPhones.updateCustomerPhones(
                this.newPhone,
                this.customerMainPhoneId,
                this.phonesToDisable,
                this.customerId
            ).then(() => {
                this.loadingPhoneChanges = false;
            }).catch(() => this.loadingPhoneChanges = false);
        },
        
        // customer care club
        async getCustomerCareClubData (_id) {
            if (!_id) return;
            if (_id._id) _id = _id._id;
            this.loaderCustomersCareClub = true;
            await apiCustomerCareClub.get(_id)
                .then(() => this.loaderCustomersCareClub = false)
                .finally(() => this.loaderCustomersCareClub = false);
        },
        // customer segment
        async getCustomerSegment (_id) {
            if (!_id) return;
            if (_id._id) _id = _id._id;
            this.loaderCustomersSegment = true;
            await apiCustomerSegment.get(_id)
                .then(() => this.loaderCustomersSegment = false)
                .finally(() => this.loaderCustomersSegment = false);
        },
        // customer poll
        async getCustomerPollData (_id) {
            if (!_id) return;
            if (_id._id) _id = _id._id;
            this.loaderCustomersCareClub = true;
            await apiCustomerPoll.get(_id)
                .then(() => this.loaderCustomersPoll = false)
                .finally(() => this.loaderCustomersPoll = false);
        },
    }
};
