<template>
  <v-overlay
      :value="isVisible"
  >
    <v-card light class="mw-80wv-i">
      <v-card-title
          class="lighten-3 pt-2 pb-0 blue white--text"
      >
        Segment
      </v-card-title>
      <div class="mh-60vh">
        <v-data-table
            :headers="headers"
            :items="customersSegmentData"
            :items-per-page="15"
            class="elevation-1"
            :disable-sort="true"
        />
      </div>

      <v-card-actions>
        <v-btn
            color="gray lighten-2"
            @click="setIsVisible(false)"
            small
        >
          zamkńij
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
  import CustomersMixin from '@/mixins/CustomersMixin';
  import { mapGetters } from 'vuex';

  export default {
    name: 'CustomerSegmentOverlay',
    mixins: [
      CustomersMixin
    ],
    props: {
      customer: {
        type: Object,
        required: true
      },
      isVisible: {
        type: Boolean,
        required: true,
      },
      setIsVisible: {
        type: Function,
        required: true,
      }
    },
    computed: {
      ...mapGetters([
        'CUSTOMER_SEGMENT',
      ]),
      customersSegment () {
        if (!this.customerId) return null;
        return this.CUSTOMER_SEGMENT(this.customerId);
      },
      customersSegmentData () {
        if (!this.customersSegment) return [];
        return this.customersSegment.data || [];
      },
      headers () {
        const headers = [];
        for (const item of this.customersSegmentData) {
          for (const key of Object.keys(item)) {
            if (headers.indexOf(key) > -1) continue;
            headers.push({
              text: key,
              value: key
            });
          }
        }
        return headers;
      }
    },
  };
</script>

<style scoped>

</style>
