<template>
  <v-overlay
      :value="isVisible"
  >
    <v-card light class="mw-80wv-i">
      <v-card-title
          class="lighten-3 pt-2 pb-0 blue white--text"
      >
        Historia zamówień klienta
      </v-card-title>

      <v-card-subtitle
          class="blue-grey lighten-3 pt-2 pr-2 pb-1 mt-0  d-flex justify-space-between"
      >
        <h4>{{ customer.firstName }} {{ customer.lastName }}</h4>
        <span class="white--text" v-if="!!customerMainEmail">
          {{ customerMainEmail.email }}
        </span>
        <span class="white--text" v-if="!!customerMainPhone">
          {{ customerMainPhone.phone }}
        </span>
      </v-card-subtitle>
      <!--      <pre style="height:80vh;overflow: scroll">{{ productsFromOrders }}</pre>-->
      <div class="mh-60vh">
        <v-card-text
            class="py-1"
            v-for="(product, index) in productsFromOrders"
            :key="`orderProductHistory${index}${product._id}`"
        >
          <!--{{product.productType}}-->
          <v-container
              class="d-flex align-center justify-space-between"
          >
            <span class="_days-left"
                  :class="{
                  '_days-left--green': product.daysLeft < 0, 
                  '_days-left--gray': product.daysLeft <= 5, 
                  '_days-left--yellow': product.daysLeft > 5, 
                  '_days-left--light': product.notDelivered, 
                }">
              <template v-if="product.notDelivered">
                <CarIcon/>
              </template>
              <template v-else-if="product.daysLeft < 0">
                <span class="_days-left__done"></span>
              </template>
              <template v-else>{{ product.daysLeft }}</template>
            </span>
            <span class="mr-5">
              <span v-html="product.orderDate"></span>
              <br>
              {{ product.orderExternalId }}
              <!--              {{ product.externalId }}-->
            </span>
            <div class="px-2 text-center mr-5">
              <h5 class="blue--text" v-html="product.title"/>

              <span>
                {{ product.quantityOrdered }}
              </span>
              x
              <span>
                {{ product.priceSelling.toFixed(2) }} zł
              </span>
            </div>

            <strong class="mr-5">{{ product.priceSellingTotal.toFixed(2) }} zł</strong>

            <span
                v-if="product.orderStatus"
                :style="{color:product.orderStatus.color}"
                class="pl-2 mr-5"
            >
              {{ product.orderStatus.title }}
            </span>
            <span v-else>
              nieznany status
            </span>

            <PromoCodeDescription
                class="text-center"
                :rule-name="product.couponRuleName"
                :code-code="product.couponCode"
                :order-created="product.orderDateNotReadable"
            />
          </v-container>

          <v-divider v-if="index<productsFromOrders.length+1"></v-divider>
        </v-card-text>
      </div>

      <v-card-actions>
        <v-btn
            color="gray lighten-2"
            @click="setIsVisible(false)"
            small
        >
          zamkńij
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
  import CustomersMixin from '@/mixins/CustomersMixin';
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import PromoCodeDescription from '@/components/PromoCodeDescription';
  import { checkDays } from '@/utils/ProductDeadlineUtils';
  import CarIcon from '@/components/imgs/CarIcon';

  export default {
    name: 'CustomerOrderedProductOverlay',
    mixins: [
      CustomersMixin
    ],
    components: {
      PromoCodeDescription,
      CarIcon
    },
    props: {
      customer: {
        type: Object,
        required: true
      },
      isVisible: {
        type: Boolean,
        required: true,
      },
      setIsVisible: {
        type: Function,
        required: true,
      }
    },
    computed: {
      ...mapGetters([
        'ORDER',
        'ORDERS'
      ]),
      productsFromOrders () {
        if (!this.customer || !this.customer.orders || !this.ORDERS) {
          return [];
        }

        // let orders = [...this.customer.orders].reverse();
        let orders = [...this.customer.orders].sort(function (a, b) {
          const dateToCheckA = a.magentoCreated ? a.magentoCreated : a.magentoUpdated;
          const dateToCheckB = b.magentoCreated ? b.magentoCreated : b.magentoUpdated;
          return new Date(dateToCheckB) - new Date(dateToCheckA) > 0
              ? 1 : -1;
        });

        let products = [];
        let productIds = [];
        for (let orderFromCustomer of orders) {
          const order = this.ORDER(orderFromCustomer._id);
          if (!order) {
            continue;
          }

          for (let product of order.products) {
            if (productIds.indexOf(product._id) > -1) continue;
            // if (product.productType !== 'simple') continue;
            if (product.priceSelling < 1) continue;
            // console.log(product);
            const orderDateNotReadable = order.magentoCreated ? order.magentoCreated : order.magentoUpdated;

            const orderDate = order.magentoCreated
                ? 'zamówienie stworzone:<br>' + moment(order.magentoCreated).format('DD.MM.YY HH:mm')
                : 'zamówienie zaktualizowane:<br>' + moment(order.magentoUpdated).format('DD.MM.YY HH:mm');

            productIds.push(product._id);
            const daysLeft = checkDays({ ...product }, order.saleDate);

            products.push({
              orderStatus: order.orderStatus,
              couponRuleName: order.couponRuleName,
              couponCode: order.couponCode,
              orderExternalId: order.externalId,
              orderDate,
              orderDateNotReadable,
              ...product,
              daysLeft,
              notDelivered: product.quantityShipped === 0 && checkDays > 0
            });
            // console.log(products);
          }

        }

        return products.filter((el, index) => el && products.indexOf(el) === index)
            .sort((a, b) => a.externalId > b.externalId ? -1 : 1);
      }
    }
  };
</script>

<style scoped lang="scss">
  ._days-left {
    position: relative;
    color: white;
    font-weight: bold;
    display: flex;
    height: 40px;
    width: 40px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 12px;

    &--green {
      background: #47a18c !important;
    }

    &--gray {
      background: #646363;
    }

    &--yellow {
      background: #5e95cc;
    }

    &--light {
      background: #f7f7f5 !important;

      svg {
        fill: #619f8e;
      }
    }

    &__done {
      position: absolute;
      height: 60%;
      width: 27%;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      top: 14%;
    }
  }
</style>
