import Vue from 'vue';
import Vuex from 'vuex';
import { parseMultipleData, parseSingleInMultipleData } from '@/utils/arrayParser';

Vue.use(Vuex);

export default {
    state: {
        ordersUpsell: [],
    },
    getters: {
        ORDERS_UPSELL: state => state.ordersUpsell,
        ORDER_UPSELL: state => (id) => state.ordersUpsell.find(el => el._id === id),
    },
    mutations: {
        SET_ORDER_UPSELL: (state, payload) => state.ordersUpsell = parseSingleInMultipleData(state.ordersUpsell, payload),
    },
    actions: {
        setOrderUpsell ({ commit }, payload) {
            commit('SET_ORDER_UPSELL', payload);
        }
    }
}