<template>
  <div>
    <div v-if="loading || loaderCustomers"
         class="loader">
      <v-progress-circular
          indeterminate
          color="primary"/>
    </div>
    <template v-else>
      <div v-if="customer">
        <v-row>
          <v-col cols="12" class="pb-0">
            <CustomerBaseActions
                :customer="customer"
                :campaign="campaign"
                :parent-order-id="customerLastOrderId"
            />
          </v-col>
          <v-col cols="9">
            <OrderDetails
                v-if="customerLastOrderId"
                :order-id="customerLastOrderId"
            />

          </v-col>

          <v-col v-if="customer" cols="3">
            <CustomerBaseData :customer="customer"/>
          </v-col>
        </v-row>
      </div>
    </template>
  </div>
</template>

<script>
  import CustomersMixin from '@/mixins/CustomersMixin';
  import StatusesMixin from '@/mixins/StatusesMixin';
  import OrderDetails from '@/components/OrderDetails';
  import { mapGetters } from 'vuex';
  import CustomerBaseData from '@/components/CustomerBaseData';
  import CustomerBaseActions from '@/components/CustomerBaseActions';
  import CampaignsMixin from '@/mixins/CampaignsMixin';

  import ApiOrders from '@/api/Orders';

  const apiOrders = new ApiOrders();

  export default {
    name: 'UserCustomer',
    mixins: [
      CustomersMixin,
      StatusesMixin,
      CampaignsMixin
    ],
    components: {
      OrderDetails,
      CustomerBaseData,
      CustomerBaseActions
    },
    data: () => ({
      loading: false,
    }),
    computed: {
      ...mapGetters([
        'ORDERS',
        'ORDER',
      ]),
      customerId () {
        return this.$route.params.id;
      },
      customer () {
        return this.CUSTOMER(this.customerId);
      },
      customerLastOrder () {
        if (!this.customer || !this.customer.orders || this.customer.orders.length < 1) return [];
        return [...this.customer.orders].reverse()[0];
      },
      customerLastOrderId () {
        if (!this.customerLastOrder) return null;
        if (typeof this.customerLastOrder === 'string') return this.customerLastOrder;
        return this.customerLastOrder._id;
      },
      campaign () {
        return this.CAMPAIGN(this.$route.params.campaignId);
      }
    },
    watch: {
      customerId () {
        this.getCustomer(this.customerId).finally(() => {
          if (this.ORDER(this.customerLastOrderId)) {
            apiOrders.getOrder({ id: this.customerLastOrderId, campaignExternalId: this.campaign.externalId });
          }
        });
      }
    },
    created () {
      this.loading = true;
      this.getCustomer(this.customerId).finally(() => {
        if (this.ORDER(this.customerLastOrderId)) {
          apiOrders.getOrder({
            id: this.customerLastOrderId,
            campaignExternalId: this.campaign.externalId
          }).then(() => this.loading = false);
        }
      });
    }
  };
</script>

<style scoped lang="scss">
  .loader {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 100px 0;
  }
</style>
