<template>
  <div class="wrap">
    {{ phone }}
    <!--    {{ campaignUuid }}-->
    <v-btn
        @click.prevent="checkRedirect"
        class="float-right white--text mx-auto"
        block
        color="green"
    >
      otwóż kienta
    </v-btn>
  </div>
</template>

<script>
  import ApiCustomers from '@/api/Customers';
  import { mapGetters } from 'vuex';

  const apiCustomers = new ApiCustomers();
  import ApiCampaigns from '@/api/Campaigns';

  const apiCampaigns = new ApiCampaigns();

  export default {
    name: 'UserInboundDashboard',
    // mixins: [
    //   CampaignsMixin
    // ],
    data: () => ({
      anonymousPhoneKey: 'anonymous',
      dashboardRouteName: 'UserCustomers',
      detailsRouteName: 'UserCustomer'
    }),
    computed: {
      ...mapGetters([
        'CAMPAIGNS',
        'CAMPAIGN',
        'CAMPAIGN_FROM_MIXED_ID'
      ]),
      phone () {
        return this.$route.params.phone;
      },
      campaignUuid () {
        return this.$route.params.campaign_uuid;
      },
      campaignId () {
        const campaign = this.CAMPAIGN_FROM_MIXED_ID(
            this.campaignUuid
        );
        if (!campaign) return null;
        return campaign._id;
      }
    },
    created () {
      // if (this.CAMPAIGNS.length < 1) {
      //   await this.getCampaigns();
      this.checkRedirect();
      // return;
      // }
      // this.checkRedirect();
    },
    methods: {
      async getCampaigns () {
        return await apiCampaigns.get();
      },
      redirectToDetailsPage (id) {
        if (this.$route.name === this.detailsRouteName) return false;
        this.$router.push({
          name: this.detailsRouteName,
          params: {
            id,
            campaignId: this.campaignId
          }
        });
        return true;
      },
      redirectToDashboard () {
        this.$router.push({
          name: this.dashboardRouteName,
          params: { type: 'all', },
          query: { phone: this.phone, campaignId: this.campaignId },
        });
      },
      async checkRedirect () {

        if (this.CAMPAIGNS.length < 1) {
          await this.getCampaigns();
        }

        const isAnonymousPhone = !this.phone || this.phone === this.anonymousPhoneKey;
        if (isAnonymousPhone) return this.redirectToDashboard();

        apiCustomers.getPerPhone(this.phone)
            .then(response => {
              if (response?._id) return this.redirectToDetailsPage(response._id);
              this.redirectToDashboard();
            });
      }
    }
  };
</script>

<style scoped>

</style>
