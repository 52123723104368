import axios from 'axios';
import store from '@/store';

const CancelToken = axios.CancelToken;
let source = {};

export default class {
    baseUrl = '/api/customers';

    getPerId = async (_id, orderId) => {
        let cancelTokenKey = 'customers_' + 'getPerId' + _id + orderId;
        if (source[cancelTokenKey]) source[cancelTokenKey].cancel();
        source[cancelTokenKey] = CancelToken.source();

        let url = `${this.baseUrl}/${_id}`;
        if (orderId !== undefined && orderId !== null && orderId !== '') {
            url = `${url}/${orderId}`;
        }
        await axios.get(url, {
            cancelToken: source[cancelTokenKey].token
        })
            .then(({ data }) => {
                store.dispatch('setCustomer', data.customer);
                if (data.orders) {
                    store.dispatch('setOrders', data.orders);
                }
            });
    };

    getPerPhone = async (phone) => {
        const { data } = await axios.get(`${this.baseUrl}/per-phone/${phone}`);
        store.dispatch('setCustomer', data.customer);
        return data.customer;
    };

    getPerType = async (type, params = {}) => {
        let cancelTokenKey = 'customers_' + 'getPerType' + type;
        if (source[cancelTokenKey]) source[cancelTokenKey].cancel();
        source[cancelTokenKey] = CancelToken.source();

        axios.get(`${this.baseUrl}/per-type/${type}`, {
            params,
            cancelToken: source[cancelTokenKey].token
        })
            .then(({ data }) => store.dispatch('setCustomers', data.customers));
    };

    updateCustomerStatus = async (id, params) => {
        const { data } = await axios.put(`${this.baseUrl}/update-status/${id}`, params);
        store.dispatch('setCustomer', data.customer);
        if (data.orders) {
            store.dispatch('setOrders', data.orders);
        }
        if (data && data.localOrderExternalId) {
            store.dispatch('setLocalOrderExternalId', data.localOrderExternalId);
        }
    };

    updateCustomerAssigned = async (id, type) => {
        const { data } = await axios.put(`${this.baseUrl}/update-customer-assigned/${id}`, { type });
        store.dispatch('setCustomer', data.customer);
        if (data.orders) {
            store.dispatch('setOrders', data.orders);
        }
    };

    setCustomerOwner = async (id, type) => {
        const { data } = await axios.put(`${this.baseUrl}/set-customer-owner/${id}`, { type });
        store.dispatch('setCustomer', data.customer);
        if (data.orders) {
            store.dispatch('setOrders', data.orders);
        }
    };

    setMainStatus = async (id, { status, childrenProducts, metamorphosis, dieticianCare }) => {
        const { data } = await axios.put(`${this.baseUrl}/update-main-status/${id}`, {
            status,
            childrenProducts,
            metamorphosis,
            dieticianCare
        });
        store.dispatch('setCustomer', data.customer);

        if (data.orders) {
            store.dispatch('setOrders', data.orders);
        }
    };
}
