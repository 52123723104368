import Vue from 'vue';
import Vuex from 'vuex';
import { parseMultipleData, parseSingleInMultipleData } from '@/utils/arrayParser';

Vue.use(Vuex);

export default {
  state: {
    countries: [],
  },
  getters: {
    COUNTRIES: state => state.countries,
    COUNTRIES_ACTIVE: state => state.countries.filter(el=>el.isActive),
    COUNTRY: state => (id) => state.countries.find(el => el._id === id),
  },
  mutations: {
    SET_ALL_COUNTRY: (state, payload) => state.countries = payload,
    SET_COUNTRIES: (state, payload) => {
      state.countries = parseMultipleData(state.countries, payload);
    },
    SET_COUNTRY: (state, payload) => state.countries = parseSingleInMultipleData(state.countries, payload)
  },
  actions: {
    setCountries ({ commit }, payload) {
      commit('SET_ALL_COUNTRY', payload);
    },
    setCountry ({ commit }, payload) {
      commit('SET_COUNTRY', payload);
    },
  }
};
