<template>
  <v-container
      fluid
      class="_wrap">

    <AdminNavigation/>

    <div class="_adminNewOrder">
      <div class="wrap">
        <div class="new-order-wrap">
          <h2 v-if="createdOrderId"
              class="my-2 blue--text">
            zamówienie zostało dodane
          </h2>
          <h2 class="my-2 blue--text">
            nowe zamówienie
          </h2>

          <v-row v-if="notAppliedPromoCode">
            promokod nie działa
          </v-row>
          <v-row v-else>
            <v-col>
              <v-text-field
                  v-model="newOrder.percentDiscount"
                  label="rabat"
                  type="number"
                  min="0"
                  step="1"
                  max="100"
              />
            </v-col>
            <v-col>
              <v-text-field
                  label="Kod rabatowy"
                  v-model="promoCodeCode"
              />
            </v-col>
            <v-col>
              <v-btn
                  class="mt-3"
                  @click.prevent="validatePromoCode"
                  :disabled="!newOrder.promoCodeIsActive || promoCodeCode.length < 1"
              >
                sprawdź
              </v-btn>
            </v-col>
            <v-col>
              <v-checkbox
                  label="włącz kod"
                  v-model="newOrder.promoCodeIsActive"
              />
            </v-col>
          </v-row>

          <v-divider/>

          <v-combobox
              v-if="productsMapped.length > 0"
              v-model="selectedProducts"
              :items="productsMapped"
              item-disabled="disabled"
              label="produkty"
              multiple
              chips
              item-text="title"
              deletable-chips
          />

          <div
              v-for="product in newOrder.products"
              :key="`product${product.id}`"
          >
            <div
                class="d-flex justify-space-between align-center"
            >
              <div>
                <h4 class="primary--text white-space-nowrap overflow-hidden mr-4">
                  <small class="grey--text">{{ product.id }}</small>
                  {{ product.title }}
                </h4>
              </div>
              <div
                  class="d-flex"
              >
                <v-text-field
                    x-small
                    type="number"
                    step="1"
                    label="iłość"
                    class="mr-2"
                    v-model.number="product.quantity"
                    @change="calcProductsPricesFromProductsChange"
                    @input="calcProductsPricesFromProductsChange"
                />
                <v-text-field
                    step="any"
                    type="number"
                    label="cena"
                    class="mr-2"
                    v-model="product.priceSelling"
                    @change="calcProductsPricesFromProductsChange"
                    @input="calcProductsPricesFromProductsChange"
                />
                <v-text-field
                    readonly
                    label="wartość"
                    v-model="product.priceTotal"
                />
              </div>
            </div>

            <v-divider/>
          </div>
        </div>

        <div class="new-order-address-wrap d-flex pt-2">
          <h3 class="info--text">Adres</h3>
          <v-container>
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <v-text-field
                        label="email"
                        type="email"
                        v-model="newOrder.newAddress.email"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                        :label="`telefon ${newOrder.newAddress.phone}`"
                        @input="checkIsPhone"
                        @change="checkIsPhone"
                        v-model="newOrder.newAddress.phone"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        label="imię"
                        v-model="newOrder.newAddress.firstName"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                        label="nazwisko"
                        v-model="newOrder.newAddress.lastName"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-combobox
                        v-if="COUNTRIES.length > 0"
                        v-model="newOrder.country"
                        :items="COUNTRIES"
                        label="kraj"
                        item-text="title"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                        label="miasto"
                        v-model="newOrder.newAddress.city"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        label="adres"
                        v-model="newOrder.newAddress.address"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                        label="kod pocztowy"
                        v-model="newOrder.newAddress.postCode"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        label="nazwa firmy"
                        v-model="newOrder.newAddress.company"
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                        label="nip"
                        v-model="newOrder.newAddress.vatNumber"
                    />
                  </v-col>
                </v-row>


              </v-col>
              <v-divider vertical/>
              <v-col>

                <h5>
                  Dostatwa i płatność
                </h5>

                <v-row>
                  <v-col>
                    <v-combobox
                        v-if="PAYMENTS.length > 0"
                        v-model="newOrder.payment"
                        :items="PAYMENTS"
                        label="płatność"
                        item-text="title"
                        :item-disabled="isDisabledPayment"
                    />
                  </v-col>
                  <v-col>
                    <v-combobox
                        v-if="CARRIERS.length > 0"
                        v-model="newOrder.carrier"
                        :items="CARRIERS"
                        label="dostawa"
                        item-text="title"
                        :item-disabled="isDisabledCarrier"
                    />
                  </v-col>
                </v-row>

                <div v-if="newOrder.carrier&&newOrder.carrier.code === 'dhl_pop'">
                  <treeselect
                      :multiple="false"
                      :async="true"
                      placeholder="wybierz punkt odbioru DHL"
                      :load-options="getDhlPops"
                      v-model="newOrder.dhlPopPoint"
                  />
                </div>

                <div v-if="newOrder.carrier&&newOrder.carrier.code === 'dpd_pop'">
                  <treeselect
                      :multiple="false"
                      :async="true"
                      placeholder="wybierz punkt odbioru DPD"
                      :load-options="getDpdPops"
                      v-model="newOrder.dpdPickupPoint"
                  />
                </div>

                <div v-if="newOrder.carrier&&newOrder.carrier.code === 'in_post'">
                  <treeselect
                      :multiple="false"
                      :async="true"
                      placeholder="wybierz paczkomat"
                      :load-options="getInPostPoints"
                      v-model="newOrder.inPostPoint"
                  />
                </div>

                <v-checkbox
                    v-model="newOrder.freeShipping"
                    label="darmowa dostawa"
                />
                <v-divider class="mb-2"/>

                dostawa: {{ newOrder.priceShipping.toFixed(2) }} zł <br>
                suma produktów: {{ newOrder.priceProductsSum.toFixed(2) }} zł <br>
                do zapłaty: {{ newOrder.priceTotal.toFixed(2) }} zł <br>

                <br><br>
                bez rabatu: {{
                  (newOrder.priceProductsSumWithoutDiscount + newOrder.priceShippingWithoutDiscount).toFixed(2)
                }}

                <!--              <pre>-->
                <!--              {{ newOrder.priceTotal }}-->
                <!--              {{ newOrder.priceProductsSum }}-->
                <!--              {{ newOrder.priceProductsSumWithoutDiscount }}-->
                <!--              </pre>-->
              </v-col>
            </v-row>
          </v-container>
        </div>

        <v-snackbar
            v-model="hasErrors"
            :multi-line="true"
        >
          <p v-for="error in errors" :key="`error${error.key}`">
            {{ error.key }} - {{ error.msg }}
          </p>

          <template v-slot:action="{ attrs }">
            <v-btn
                color="red"
                text
                v-bind="attrs"
                @click="errors = []"
            >
              zamknij
            </v-btn>
          </template>
        </v-snackbar>

      </div>

      <h2 v-if="createdOrderId"
          class="my-2 blue--text">
        zamówienie zostało dodane
      </h2>
      <v-btn
          color="success"
          small
          :dark="!!isOrderValid"
          :loading="loading"
          :disabled="!isOrderValid"
          @click.prevent="createNewOrder"
      >
        zapisz
      </v-btn>
    </div>
  </v-container>
</template>
<script>
  import AdminNavigation from '@/components/AdminNavigation';
  import ErrorsMixin from '@/mixins/ErrorsMixin';
  import ApiOrders from '@/api/Orders';
  import { mapGetters } from 'vuex';
  import ProductsMixin from '@/mixins/ProductsMixin';
  import CountriesMixin from '@/mixins/CountriesMixin';
  import CarriersMixin from '@/mixins/CarriersMixin';
  import PaymentsMixin from '@/mixins/PaymentsMixin';
  import CustomersMixin from '@/mixins/CustomersMixin';
  import ApiDhl from '@/api/external/Dhl';
  import ApiDpd from '@/api/external/Dpd';
  import ApiInPost from '@/api/external/InPost';
  import ApiPromoCodes from '@/api/PromoCodes';

  const apiDhl = new ApiDhl();
  const apiDpd = new ApiDpd();
  const apiInPost = new ApiInPost();
  const apiPromoCodes = new ApiPromoCodes();
  const apiOrders = new ApiOrders();

  export default {
    name: 'AdminNewOrder',
    mixins: [
      ProductsMixin,
      CountriesMixin,
      CarriersMixin,
      PaymentsMixin,
      CustomersMixin,
      ErrorsMixin,
    ],
    components: {
      AdminNavigation,
    },
    data: () => ({
      createdOrderId: null,
      loading: false,
      selectedProducts: [],
      promoCodeCode: '',
      newOrder: {
        percentDiscount: 0,
        promoCodeCode: '',
        promoCodeRuleId: null,
        promoCodeIsActive: false,

        freeShipping: false,
        carrier: null,
        payment: null,

        priceTotal: 0,
        priceProductsSum: 0,
        priceShipping: 0,

        priceProductsSumWithoutDiscount: 0,
        priceShippingWithoutDiscount: 0,

        products: [],

        country: null,

        address: null,

        inPostPoint: '',
        dhlPopPoint: '',
        dpdPickupPoint: '',

        newAddress: {
          email: '',
          phone: '',
          firstName: '',
          lastName: '',
          address: '',
          postCode: '',
          city: '',
          company: '',
          vatNumber: '',
          // email: 'anton+992@healthlabs.pl',
          // phone: '+48999999992',
          // firstName: 'test',
          // lastName: 'test',
          // address: 'test',
          // postCode: '03-000',
          // city: 'test',
          // company: '',
          // vatNumber: '',
        }
      },
    }),
    computed: {
      ...mapGetters([
        'ORDER',
      ]),
      /* *** */
      notAppliedPromoCode () {
        return !!this.newOrder.promoCodeCode
            && !!this.appliedPromoCodeTitle
            && !!this.promoCodeRuleId;
      },
      /* *** */
      isOrderValid () {
        const newOrder = this.newOrder;
        if (!newOrder.newAddress || !newOrder.newAddress.email
            || !newOrder.newAddress.phone
            || !newOrder.newAddress.firstName
            || !newOrder.newAddress.lastName
            || !newOrder.newAddress.address
            || !newOrder.newAddress.postCode
            || !newOrder.newAddress.city) {

          return false;
        }

        if (!newOrder.carrier) {
          return false;
        }

        if (!newOrder.payment) {
          return false;
        }
        return true;
      },

      productsMapped () {
        return this.PRODUCTS.map(p => {
          if (!p.bundle) {
            return p;
          }
          const subProducts = p.bundle.map(b => {
            return b.title;
          });
          const title = `${p.title} (${subProducts.join(', ')})`;
          return {
            ...p,
            title,
          };
        });
      },
      /* *** */
      isDisabledCarrier: function () {
        return (item) => {
          if (!this.newOrder.country) return false;
          const country = this.newOrder.country;
          const payment = this.newOrder.payment;
          if (!payment) {
            return country.carriersPayments.findIndex(el => {
              return el.carrier === item._id;
            }) < 0;
          }

          return country.carriersPayments.findIndex(el => {
            return el.carrier === item._id && el.payment === payment._id;
          }) < 0;
        };
      },
      isDisabledPayment: function () {
        return ({ _id }) => {
          if (!this.newOrder.country) return false;
          const country = this.newOrder.country;
          const carrier = this.newOrder.carrier;
          if (!carrier) {
            return country.carriersPayments.findIndex(el => {
              return el.payment === _id;
            }) < 0;
          }

          return country.carriersPayments.findIndex(el => {
            return el.payment === _id && el.carrier === carrier._id;
          }) < 0;
        };
      },

    },

    watch: {
      'newOrder.inPostPoint' () {
        this.setInPostPointToAddress();
      },
      'newOrder.dhlPopPoint' () {
        this.setDhlPopPointToAddress();
      },
      'newOrder.dpdPickupPoint' () {
        this.setDpdPopPointToAddress();
      },
      'newOrder.percentDiscount' () {
        this.calcProductsPricesFromDiscountChange();
      },
      selectedProducts () {
        this.checkProducts();
      },

      'newOrder.freeShipping' () {
        this.checkPriceShipping();
      },
      'newOrder.payment' () {
        this.checkPriceShipping();
      },
      'newOrder.carrier' () {
        this.checkPriceShipping();
      },
      'newOrder.country' () {
        this.checkPriceShipping();
      },
      customerIdProp () {
        this.prepareAddress();
      },
      'newOrder.newAddress.email' () {
        this.prepareAddress();
      },
      'newOrder.address' () {
        this.prepareAddress();
      },
      customerMainEmail () {
        this.prepareAddress();
      },
    },

    methods: {
      /* *** */
      checkIsPhone (value) {
        let x = value.replace(/\D/g, '');
        if (x === null || x === undefined || x === false || x === '') { return ''; }
        if (value.indexOf('+') === 0) {
          x = `+${x}`;
        }

        this.newOrder.newAddress.phone = x;
      },
      /* *** */
      createNewOrder () {
        this.errors = [];
        if (!this.isOrderValid) {
          this.errors.push({
            key: 'błąd',
            msg: 'adres jest niepoprawny'
          });
          return;
        }
        this.loading = true;
        apiOrders.createNewOrder(this.newOrder).then(data => {
          if (data) {
            this.createdOrderId = data.externalId;
          }
        }).finally(() => this.loading = false);
      },
      /* *** */
      checkProducts () {
        let products = this.selectedProducts;

        for (let [k, v] of Object.entries(this.newOrder.products)) {
          const index = products.findIndex(el => v.id === el.id);
          if (index < 0) delete this.newOrder.products[k];
        }

        this.newOrder.products = this.newOrder.products.filter(n => n);

        for (let v of products) {
          const index = this.newOrder.products.findIndex(el => v.id === el.id);
          if (index > -1) continue;

          let currentObject = {
            ...v,
            quantity: v.quantity ?? 1,
            priceSelling: v.priceSelling ?? v.price - ((v.price * this.newOrder.percentDiscount) / 100),
          };
          currentObject.priceTotal = currentObject.priceSelling * currentObject.quantity;
          currentObject.priceTotalWithoutDiscount = currentObject.price * currentObject.quantity;
          this.newOrder.products.push(currentObject);
        }
        this.calcPriceProductsSum();
      },
      
      /* *** */
      calcProductsPricesFromDiscountChange () {
        for (let v of this.newOrder.products) {
          v.priceSelling = (v.price - ((v.price * this.newOrder.percentDiscount) / 100)); //* v.quantity;
          v.priceTotal = v.priceSelling * v.quantity;
        }
        this.calcPriceProductsSum();
      },
      calcProductsPricesFromProductsChange () {
        for (let v of this.newOrder.products) {
          v.priceTotal = v.priceSelling * v.quantity;
          v.priceTotalWithoutDiscount = v.price * v.quantity;
        }
        this.calcPriceProductsSum();
      },

      calcPriceProductsSum () {
        this.newOrder.priceProductsSum = this.newOrder.products
            .reduce((accumulator, el) => accumulator + el.priceTotal, 0);
        this.newOrder.priceProductsSumWithoutDiscount = this.newOrder.products
            .reduce((accumulator, el) => accumulator + el.priceTotalWithoutDiscount, 0);
        
        this.calcPriceTotal();
      },

      checkPriceShipping () {
        if (!this.newOrder.country
            || !this.newOrder.carrier
            || !this.newOrder.payment
        ) {
          this.newOrder.priceShipping = 0;
          this.calcPriceTotal();
          return;
        }

        const carrierPayment = this.newOrder.carrier.payments
            .find(el => el.payment === this.newOrder.payment._id && el.country === this.newOrder.country._id);

        if (!carrierPayment) {
          this.newOrder.priceShipping = 0;
          this.calcPriceTotal();
          return;
        }

        this.newOrder.priceShippingWithoutDiscount = carrierPayment.price;
        if (this.newOrder.freeShipping) {
          this.newOrder.priceShipping = 0;
          this.calcPriceTotal();
          return;
        }

        this.newOrder.priceShipping = carrierPayment.price;
        this.calcPriceTotal();

      },

      calcPriceTotal () {
        this.newOrder.priceTotal = this.newOrder.priceShipping + this.newOrder.priceProductsSum;
      },
      /* *** */
      getDhlPops ({ searchQuery, callback }) {
        if (searchQuery.length < 3) return callback(null, []);

        apiDhl.getDhlPops(searchQuery)
            .then(response => callback(null, response));

      },
      getDpdPops ({ searchQuery, callback }) {
        if (searchQuery.length < 3) return callback(null, []);

        apiDpd.getDpdPops(searchQuery)
            .then(response => callback(null, response));
      },
      getInPostPoints ({ searchQuery, callback }) {
        if (searchQuery.length < 3) return callback(null, []);
        apiInPost.getInPostPoints(searchQuery)
            .then(response => callback(null, response))
            .catch(() => callback(null, []));
      },
      /* *** */
      validatePromoCode () {
        apiPromoCodes.getPerCode(this.promoCodeCode)
            .then(response => {
              if (response.errors) return this.errors = response.errors;

              this.addPromoCodeToOrder(response);
            });
      },
      checkIsValidPromoCodeDates (promoCode) {
        const startDateString = promoCode.rule?.dateStart;
        const endDateString = promoCode.rule?.dateEnd;
        if (!startDateString && !endDateString) return true;

        const nowDate = +new Date();
        const startDate = +new Date(`${startDateString} 00:00`);
        const endDate = +new Date(`${endDateString} 23:59`);

        if (startDateString && !endDateString) {
          return nowDate >= startDate;
        }
        if (!startDateString && endDateString) {
          return nowDate < endDate;
        }

        return nowDate >= startDate && nowDate < endDate;
      },
      addPromoCodeToOrder (promoCode) {
        if (!promoCode.rule.byPercent) {
          this.errors = [];
          return this.errors.push({ key: 'Błąd', msg: 'kod nie jest procentowy' });
        }

        if (!this.checkIsValidPromoCodeDates(promoCode)) {
          this.errors = [];
          return this.errors.push({ key: 'Błąd', msg: 'kod jest nieaktywny' });
        }

        this.newOrder.promoCodeCode = promoCode.code;
        this.newOrder.percentDiscount = promoCode.rule.discountAmount;
        this.newOrder.promoCodeRuleId = promoCode.ruleId;
        this.appliedPromoCodeTitle = `${promoCode.rule.title} - ${promoCode.rule.discountAmount}%`;
      },
      removePromoCode () {
        this.newOrder.promoCodeCode = '';
        this.appliedPromoCodeTitle = '';
        this.promoCodeRuleId = null;
      },
      /* *** */
      prepareAddress () {
        this.newOrder.customerId = this.customerIdProp;

        if (this.newOrder.address === 0 || !this.customerMainEmail || !this.customerMainEmail) {
          this.newOrder.customerEmailString = this.newOrder.newAddress.email;
          this.newOrder.customerEmailId = null;
        } else {
          this.newOrder.customerEmailString = this.customerMainEmail.email;
          this.newOrder.customerEmailId = this.customerMainEmail._id;
        }

        if (!this.newOrder.address) return;
        this.newOrder.country = this.COUNTRY(this.newOrder.address.country);
        this.newOrder.dhlPopPoint = this.newOrder.address.dhlPopPoint;
        this.newOrder.inPostPoint = this.newOrder.address.inPostPoint;
        this.newOrder.dpdPickupPoint = this.newOrder.address.dpdPickupPoint;
      },
      /* *** */
      /* *** */
      setInPostPointToAddress () {
        this.newOrder.newAddress.inPostPoint = this.inPostPoint;
        
      },
      setDhlPopPointToAddress () {
        this.newOrder.newAddress.dhlPopPoint = this.dhlPopPoint;
      },
      setDpdPopPointToAddress () {
        this.newOrder.newAddress.dpdPickupPoint = this.dpdPickupPoint;
      },
    },
  };
</script>

<style scoped lang="scss">
  ._adminNewOrder {
    background: #fff;
    padding: 8px 20px;
    display: grid;

    &-btn {
    }
  }
</style>