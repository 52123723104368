import axios from 'axios';
import store from '@/store';

export default class {
  baseUrl = '/api/payments';

  get = () => new Promise(() => {
    axios.get(this.baseUrl)
      .then(({ data }) => store.dispatch('setPayments', data.payments));
  });
  getActive = () => new Promise(() => {
    axios.get(this.baseUrl, { params: { isActive: true } })
      .then(({ data }) => store.dispatch('setPayments', data.payments));
  });
}
