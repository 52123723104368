<template>
  <v-card
      v-if="order && order.orderStatus"
  >

    <v-card-title
        class="blue lighten-3 white--text pt-2 _title"
    >
      Zamówienie #{{ order.externalId }} ({{ order.incrementId }})
      {{ order.magentoAddedBySystem }}
      <template v-if="isOrderCreatedAfterAgreementsValidator && order.hasPhoneAgreements === false">
        <small class="pl-2 dark-red--text">
          <b>(nie wyraził zgody na kontakt telefoniczny)</b>
        </small>
      </template>
    </v-card-title>

    <v-card-subtitle class="pl-2 white--text _subtitle">
      <!--      <span :style="{color:order.orderStatus.color}" class="pl-2">-->
      <strong class="pl-2">
        {{ order.orderStatus.title }}
      </strong>
      {{ moment(order.magentoCreated).format('DD.MM.YYYY HH:mm') }}
    </v-card-subtitle>


    <v-card-text class="py-1">
      <v-row>
        <v-col cols="12" md="4">
          <h4 class="blue--text">
            Dostawa:
          </h4>
          {{ order.carrier.title }}
        </v-col>
        <v-col cols="12" md="8">
          <h4 class="blue--text">
            Płatności:
          </h4>
          <div v-for="(payment, index) in order.payments"
               :key="`orderPayment${index}`">
            Metoda: {{ payment.method }}.
            Do zapłaty: <strong>{{ payment.priceToPay }} zł.</strong>
            Zapłacono: <strong>{{ payment.pricePaid ? payment.pricePaid : 0 }} zł.</strong>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider/>

    <v-card-text class="py-1" v-if="order.shippingAddress">
      <h4 class="blue--text">
        Adres:
      </h4>
      <p class="mb-1">
        {{ order.shippingAddress.firstName }}
        {{ order.shippingAddress.lastName }}
      </p>
      {{ order.shippingAddress.address }},
      {{ order.shippingAddress.city }}
      {{ order.shippingAddress.postCode }}
      {{ order.shippingAddress.countryCode }}
      <template v-if="order.shippingAddress.company">
        Nazwa firmy: {{ order.shippingAddress.company }}
      </template>
      <template v-if="order.shippingAddress.vatNumber">
        Nip: {{ order.shippingAddress.vatNumber }}
      </template>
      <span v-if="order.shippingAddress.inPostPoint">
        InPost {{ order.shippingAddress.inPostPoint }}
      </span>
      <span v-if="order.shippingAddress.dhlPopPoint">
        DHL POP {{ order.shippingAddress.dhlPopPoint }}
      </span>
      <span v-if="order.shippingAddress.dpdPickupPoint">
        DPD Pickup {{ order.shippingAddress.dpdPickupPoint }}
      </span>
    </v-card-text>

    <v-divider/>

    <v-card-text>
      <v-row>
        <v-col>
          <h4 class="blue--text">
            Produkty:
          </h4>
          <!--      <pre>{{ order.products }}</pre>-->
          <!--      <template v-for="(product, index) in order.products">-->
          <template v-for="(product, index) in orderProducts">
            <div v-if="product.priceSelling > 0"
                 :key="`orderProduct${index}${order._id}`" class="d-flex">
              {{ product.quantityOrdered }} x
              <h5 class="px-2 blue--text">
                {{ product.title }}
                <br>
                <small v-if="product.children.length > 1">
                  <span :key="`child-${index}-${pIndex}`"
                        v-for="(p, pIndex) in product.children">
                    {{ p.title }}
                    <br>
                  </span>
                </small>
              </h5>

              <span>
                {{ product.priceSelling.toFixed(2) }} zł
              </span>
              <span class="px-2">=</span>
              <strong>{{ product.priceSellingTotal.toFixed(2) }} zł</strong>
            </div>
          </template>
        </v-col>

        <v-col v-if="loaderOrdersUpsell">
          <h4 class="warning--text">
            Upsell:
          </h4>
          <v-progress-circular
              indeterminate
              color="primary"/>

        </v-col>
        <v-col v-else-if="!!upsell && upsell.length">
          <h4 class="warning--text">
            Upsell:
          </h4>
          <template v-for="(product, index) in upsell">
            <div :key="`upsell${index}${order._id}`" class="d-flex">
              <h5 class="pr-2 blue--text">
                {{ product.title }}
                <br>
                <small v-if="product.children && product.children.length > 1">
                  <span :key="`child-${index}-${pIndex}`"
                        v-for="(p, pIndex) in product.children">
                    {{ p.title }}
                    <br>
                  </span>
                </small>
              </h5>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider/>


    <v-card-text>
      <v-row>
        <v-col md="6" cols="12" class="">
          <h4 class="blue--text mb-2">
            Historia zamówienia
          </h4>
          <div class="mh-25vh">
            <div v-for="(status, index) of order.statuses"
                 :key="`ordersStatuses${order._id}_${index}`">
              <strong>
                {{ status.magentoStatus }}
              </strong>
              <br>
              {{ status.description }}
              <v-divider
                  v-if="index<order.statuses.length-1"
                  class="my-2"
              />
            </div>
          </div>
        </v-col>

        <v-divider vertical/>

        <v-col md="6" cols="12" class="text-right">
          <div class="mb-2">
            Cena dostawy: <strong>{{ order.priceShipping }} zł</strong>
          </div>

          <div class="mb-2">
            Rabat: {{ order.priceDiscount || 0 }} zł
            <PromoCodeDescription
                :rule-name="order.couponRuleName"
                :code-code="order.couponCode"
                :order-created="order.magentoCreated"
            />
          </div>
          <div class="mb-2">
            Do zapłaty: <strong>{{ order.priceTotal }} zł</strong>
          </div>
          <div class="mb-2">
            Zapłacono: <strong>{{ order.priceTotalPaid || 0 }} zł </strong>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex';
  import PromoCodeDescription from '@/components/PromoCodeDescription';
  import moment from 'moment';
  import ApiOrders from '@/api/Orders';
  import OrdersUpsellMixin from '@/mixins/OrdersUpsellMixin';

  const apiOrders = new ApiOrders();

  export default {
    name: 'OrderDetails',
    mixins: [OrdersUpsellMixin],
    components: {
      PromoCodeDescription,
    },
    props: {
      orderId: {
        type: String,
        required: true
      }
    },

    computed: {
      ...mapGetters([
        'ORDER',
        'PRODUCTS'
      ]),
      order () {
        return this.ORDER(this.orderId);
      },
      isInfluencerCoupon () {
        if (!this.order.couponRuleName) return '';
        if (this.order.couponRuleName.indexOf('.{callcenter}') > -1) return 'success--text';
        if (this.order.couponRuleName.indexOf('.[influencer]') > -1) return 'danger--text';

        return '';
      },
      moment () {
        return moment;
      },

      orderProducts () {
        const simple = [];
        const notSimple = [];
        this.order?.products?.forEach(p => {
          if (p.productType === 'simple' && !!p.parentOrderProductId) {
            simple.push(p);
          } else {
            notSimple.push({
              ...p,
              children: []
            });
          }
        });

        simple.forEach(s => {
          const parent = notSimple.find(({ externalId }) => externalId === s.parentOrderProductId);
          if (parent) parent.children.push(s);
        });
        return notSimple;
      },

      isOrderCreatedAfterAgreementsValidator () {
        return (+new Date(this.order?.createdAt) - +new Date('2023-10-09 15:56')) > 0;
      },

      /* *** *** */
      upsell () {
        if (!this.orderId || !this.order) return [];
        const upsellSkus = this.ORDER_UPSELL(this.orderId);
        if (!upsellSkus?.products) return [];

        return upsellSkus.products;
      }
    },

    created () {
      if (!this.order) {
        apiOrders.getOrder({ id: this.orderId });
      } else {
        this.getOrderUpsell(this.orderId);
      }
    }
  };
</script>

<style scoped>
  ._title {
    padding-bottom: 30px;
  }

  ._subtitle {
    margin-top: -32px;
  }

  .dark-red--text {
    color: #b71c1c;
  }
</style>
