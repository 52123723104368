// @mixins/FormValidatorMixin
export default {
  data: () => ({
    valid: true,

    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],

    notEmptyFieldRules: [v => !!v || 'Pole jest wymagane'],
    requiredCombobox(value) {
      console.log(value);
      if (value instanceof Array && value.length == 0) {
        return  'Pole jest wymagane';
      }
      return !!value || 'Pole jest wymagane';
    },
  }),
  methods: {
    validate (ref) {
      return this.$refs[ref].validate();
    },
  }
};
