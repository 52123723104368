import axios from 'axios';
import { setAuthToken } from '@/utils/authToken';
import store from '@/store';
import router from '@/router';

const login = (email, password) => new Promise((resolve, reject) => {
  axios.post('/api/auth/login', {
    email,
    password
  }).then(({ data }) => {
    if (data.errors) {
      let errors = [];
      for (const [key, value] of data.errors) {
        errors.push({ key, value });
      }
      reject(errors);
    }

    localStorage.setItem('authToken', data.token);

    store.dispatch('toggleAuthState', true);
    store.dispatch('saveUserData', data.user);

    setAuthToken(data.token);

    router.push({
      name: 'UserDashboard',
      params: { handle: data.user.id }
    });
  }).catch(e => {

    reject({ error: e.message });
  });
});

export default {
  login
};
