<template>
  <v-overlay
      :value="isVisible"
      z-index="9"
  >
    <v-card light class="mw-80wv-i _customCard">
      <v-card-title
          class="lighten-3 pt-2 pb-0 blue white--text"
      >
        Ankieta
      </v-card-title>

      <v-card-text class="_customCard_body">
        <div v-if="!customersPollData.length">
          brak odpowiedzi
        </div>
        <ul>
          <li v-for="(tab, index) in customersPollData"
              :key="`tab${index}`"
              class="_tab"
              :class="{
                _active: index === activeIndex,
              }">
            <h2 @click="activeIndex = index">
              {{ tab.email }} - {{ moment(tab.createdAt).format('DD.MM.YYYY') }}
            </h2>
            <div class="_tab_content">
              <div>
                <h3>Ankieta</h3>
                <ol>
                  <li v-for="(answer, answerIndex) in tab.answers"
                      :key="`tab${index}_points_${answerIndex}`">
                    <span class="_coinsDescription">
                      {{ answer.key }}
                      <br>
                      <strong>{{ answer.value }}</strong>
                    </span>
                  </li>
                </ol>
              </div>
            </div>
          </li>
        </ul>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
            color="gray lighten-2"
            @click="setIsVisible(false)"
            small
        >
          zamkńij
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
  import { mapGetters } from 'vuex';
  import moment from 'moment';

  export default {
    name: 'CustomerPollModal',
    props: {
      customerId: {
        type: String,
        required: false
      },
      isVisible: {
        type: Boolean,
        required: true,
      },
      setIsVisible: {
        type: Function,
        required: true,
      },
    },
    data: () => ({
      activeIndex: 0,
    }),
    computed: {
      ...mapGetters([
        'CUSTOMER_POLL',
      ]),
      customersPoll () {
        if (!this.customerId) return null;
        return this.CUSTOMER_POLL(this.customerId);
      },
      customersPollData () {
        if (!this.customersPoll) return [];
        return this.customersPoll.data || [];
      },

      moment () {
        return moment;
      },
    },
    methods: {
      //
    }
  };
</script>

<style scoped lang="scss">
  ._customCard {
    width: 80vw;
    overflow: auto;

    &_body {
      padding-top: 16px;
      max-height: 80vh;
      min-height: 60vh;
      transition: height .4s ease-in-out;
    }
  }

  ._coinsDescription {
    overflow: hidden;
    display: block;
  }

  ._tab {
    list-style: none;
    padding: 8px 0;

    &:before {
      display: block;
      content: "";
      width: 12px;
      height: 12px;

      border-left: 1px solid;
      border-bottom: 1px solid;
      transform: rotate(-45deg);
      float: left;
      margin-right: 12px;
    }

    h2 {
      cursor: pointer;
    }

    h3 {
      padding: 24px 0 12px;
      color: #413e38;
    }

    h4 {
      padding: 4px 0 6px;
      color: #413e38;
    }

    strong {
      color: #413e38;
    }

    &_content {
      height: 0;
      overflow: hidden;
    }

    &._active {
      h2 {
        color: #00B7FF;
      }

      ._tab_content {
        transition: height .3s ease-in-out, padding .3s ease-in-out;
        height: 70vh;
        padding: 8px 24px;
        overflow: auto;
      }

      &:before {
        border-color: #00B7FF;
        transform: rotate(135deg) translate(100%);
      }
    }
  }


  ._success {
    color: #42b983 !important;

    &_strongChild {
      strong {
        color: #42b983;
      }
    }
  }

  ._info {
    color: #3c9adf !important;

    &_strongChild {
      strong {
        color: #3c9adf;
      }
    }
  }

  ._warning {
    color: #b66d00 !important;

    &_strongChild {
      strong {
        color: #b66d00;
      }
    }
  }

  ._danger {
    color: #ea4436 !important;

    &_strongChild {
      strong {
        color: #ea4436;
      }
    }
  }

  ._coinIcon {
    height: 1em;
    width: 1em;
    transform: translate(0px, 2px);
  }

  ._quests {
    display: flex;
    flex-wrap: wrap;
    padding: 0 8px;
  }

  ._quest {
    flex: 0 0 32%;
    width: 33%;
    display: flex;
    flex-direction: column;
    margin-right: 2%;
    margin-bottom: 16px;
    background: #F7F7F5;
    padding: 20px 24px;
    border-radius: 4px;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &_title {
      padding: 0 8px;
    }

    &_coins {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 12px;
    }
  }
</style>