<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#FCBB2E"/>
    <circle cx="15" cy="15" r="12.5" fill="#F3A700"/>
    <path d="M20.7303 8.75H17.5098V21.2503H20.7303V8.75Z" fill="#FFE2A3"/>
    <path d="M13.2379 17.1479C15.4326 16.9571 17.0548 15.0248 16.8639 12.8301C14.6692 13.0209 13.0471 14.9532 13.2379 17.1479Z" fill="#FFE2A3"/>
    <path d="M12.5955 8.75H9.375V21.2503H12.5955V8.75Z" fill="#FFE2A3"/>
  </svg>
</template>

<script>
  export default {
    name: 'CareClubCoinIcon'
  };
</script>

<style scoped>

</style>