<template>
  <v-container
      fluid
      class="_wrap"
      v-if="AUTH_USER.accountType===3">
    <div>
      <AdminNavigation/>
    </div>

    <v-row class="align-center">
      <v-col>
        zamówienia do obdzwonienia:
        <strong v-if="loadingDownloadTotalOrdersQuantity === 2">
          {{ +TOTAL_ORDERS_TO_RECALL_QUANTITY }}
        </strong>

        <v-btn
            outlined
            :disabled="loadingDownloadTotalOrdersQuantity===1"
            :loading="loadingDownloadTotalOrdersQuantity===1"
            @click.prevent="downloadTotalOrdersQuantity()"
            color="warning"
        >
          pobierz
        </v-btn>
      </v-col>
      <v-col>
        <AdminDownloadOrdersToAccept/>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="blue lighten-3">
            Raport połączenia
          </v-card-title>

          <ReportForm
              :select-type="true"
              reportUuid="ReportCalls"
              report-title="raport_polaczenia"
          />

        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title class="blue lighten-3">
            Raport mnożniki
          </v-card-title>

          <ReportForm
              reportUuid="ReportProducts"
              report-title="raport_mnozniki"
              :campaign-required="false"
          />

        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title class="blue lighten-3">
            Raport zamówienia
          </v-card-title>

          <ReportForm
              reportUuid="ReportOrders"
              report-title="raport_zamowienia"
              :campaign-required="false"
          />

        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="blue lighten-3">
            Wszystkie zamówienia
          </v-card-title>

          <ReportForm
              reportUuid="OrdersWithDetails"
              report-title="raport_wszystkie_zamowienia"
              :campaign-required="false"
          />

        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title class="blue lighten-3">
            Komentarze do połączeń
          </v-card-title>

          <ReportForm
              report-uuid="CallWithComments"
              report-title="raport_komentarze_do_polaczen"
          />

        </v-card>
      </v-col>
      <v-col></v-col>
    </v-row>
    <hr class="_separator my-10">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="blue lighten-3">
            Moje rekordy konsultantów
          </v-card-title>

          <v-card-text class="pt-3 pb-0">
            Raport, zawierający "Moje rekordy" konsultantów
          </v-card-text>

          <v-card-actions>
            <v-btn
                text
                :loading="loadingDownloadAssignedOwnedCustomersReport"
                @click.prevent="downloadAssignedOwnedCustomersReport('assigned')"
            >
              pobierz
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title class="blue lighten-3">
            Raport Portfele konsultantów
          </v-card-title>

          <v-card-text class="pt-3 pb-0">
            Raport, zawierający  Portfele konsultantów
          </v-card-text>

          <v-card-actions>
            <v-btn
                text
                :loading="loadingDownloadAssignedOwnedCustomersReport"
                @click.prevent="downloadAssignedOwnedCustomersReport('owner')"
            >
              pobierz
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col></v-col>
    </v-row>
    <hr class="_separator my-10">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="blue lighten-3">
            Strona wszystkie "Moje rekordy"
          </v-card-title>

          <v-card-text class="pt-3 pb-0">
            Tabela, zawierająca wszystkich klientów w "Mój rekord"
          </v-card-text>

          <v-card-actions>
            <v-btn
                text
                :loading="loading"
                :to="{name:'UserCustomers', params:{ type:'assigned', forAdmin:true } }"
            >
              Start
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-col>
      <v-col>

        <v-card>
          <v-card-title class="blue lighten-3">
            Strona wszystkie Portfele
          </v-card-title>

          <v-card-text class="pt-3 pb-0">
            Tabela, zawierająca wszystkich klientów w "Portfel"
          </v-card-text>

          <v-card-actions>
            <v-btn
                text
                :loading="loading"
                :to="{name:'UserCustomers', params:{ type:'owner', forAdmin:true } }"
            >
              Start
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-col>
      <v-col></v-col>
    </v-row>

    <hr class="_separator my-10">

    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="blue lighten-3">
            Raport zainteresowany KIDS
          </v-card-title>

          <v-card-text class="pt-3 pb-0">
      
          </v-card-text>

          <v-card-actions>
            <v-btn
                text
                :loading="loadingDownloadCustomCustomerFields"
                @click.prevent="downloadCustomCustomerFields('childrenProducts')"
            >
              pobierz
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title class="blue lighten-3">
            Raport opieka dietetyka
          </v-card-title>

          <v-card-text class="pt-3 pb-0">
            
          </v-card-text>

          <v-card-actions>
            <v-btn
                text
                :loading="loadingDownloadCustomCustomerFields"
                @click.prevent="downloadCustomCustomerFields('dieticianCare')"
            >
              pobierz
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title class="blue lighten-3">
            Raport metamorfoza
          </v-card-title>

          <v-card-text class="pt-3 pb-0">
            
          </v-card-text>

          <v-card-actions>
            <v-btn
                text
                :loading="loadingDownloadCustomCustomerFields"
                @click.prevent="downloadCustomCustomerFields('metamorphosis')"
            >
              pobierz
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <template v-if="false && AUTH_USER.email==='anton@healthlabs.pl'">
      <v-card>
        <v-card-title class="blue lighten-3">
          Raport statusy
        </v-card-title>

        <ReportForm
            reportUuid="ReportStatuses"
            report-title="raport_statusy"
            :campaign-required="false"
        />
      </v-card>
      <v-card>
        <v-card-title class="blue lighten-3">
          Raport wszystkie połączenia
        </v-card-title>

        <ReportForm
            reportUuid="ReportAllCals"
            report-title="raport_wszystkie_statusy"
            :campaign-required="false"
        />
      </v-card>
    </template>
  </v-container>
</template>

<script>
  import { prepareCsvToDownload } from '@/utils/csvWriter';
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  import ReportForm from '@/components/ReportForm';
  import AdminDownloadOrdersToAccept from '@/components/AdminDownloadOrdersToAccept';
  import AdminNavigation from '@/components/AdminNavigation';
  import ErrorsMixin from '@/mixins/ErrorsMixin';
  import ScoredOrdersMixin from '@/mixins/ScoredOrdersMixin';
  import ReportsApi from '@/api/Reports';
  import ApiOrders from '@/api/Orders';

  const apiOrders = new ApiOrders();
  const reportsApi = new ReportsApi();
  export default {
    name: 'AdminReports',
    components: {
      ReportForm,
      AdminDownloadOrdersToAccept,
      AdminNavigation,
    },
    mixins: [
      ErrorsMixin,
      ScoredOrdersMixin,
    ],

    data: () => ({
      loading: false,
      loadingDownloadAssignedOwnedCustomersReport: false,
      loadingDownloadCustomCustomerFields: false,
      loadingDownloadTotalOrdersQuantity: 0,
    }),

    computed: {
      ...mapGetters([
        'AUTH_USER',
        'TOTAL_ORDERS_TO_RECALL_QUANTITY',
      ])
    },
    methods: {
      prepareFileToDownload (content) {
        let csvContent = 'data:text/csv;charset=utf-8,' + content;
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', this.reportTitle + '.csv');
        document.body.appendChild(link); // Required for FF
        link.click();
      },

      downloadTotalOrdersQuantity () {
        if (this.AUTH_USER.accountType === 3) {
          this.loadingDownloadTotalOrdersQuantity = 1;
          apiOrders.getTotalActiveOrdersQuantity().then(r => {
            let str = '';
            for (let i = 0; i < r.length; i++) {
              str = `${str}\n${r[i]},`;
            }
            prepareCsvToDownload(str, 'zamówienia do obdzwonienia.csv');
            this.loadingDownloadTotalOrdersQuantity = 2;
          });
        }
      },
      downloadAssignedOwnedCustomersReport (type) {
        this.loadingDownloadAssignedOwnedCustomersReport = true;
        const reportsNames = {
          assigned: 'moj-rekord-konsultantow',
          owner: 'portfel-konsultantow',
        };

        const reportTitle = reportsNames[type] ?? 'raport';

        reportsApi.get('assignedOwnedCustomers', { type: type }).then(response => prepareCsvToDownload(response, reportTitle))
            .catch(() => {
              this.errors.push({
                key: 'błąd',
                msg: 'wystąpił błąd z pobraniem raportu'
              });
            })
            .finally(() => this.loadingDownloadAssignedOwnedCustomersReport = false);
      },

      downloadCustomCustomerFields (type) {
        this.loadingDownloadCustomCustomerFields = true;
        const reportsNames = {
          childrenProducts: 'zainteresowany-kids',
          dieticianCare: 'opieka-dietetyka',
          metamorphosis: 'metamorfoza',
        };

        const reportTitle = reportsNames[type] ?? 'raport';

        reportsApi.get('customCustomerFields', { type: type })
            .then(response => prepareCsvToDownload(response, reportTitle))
            .catch(() => {
              this.errors.push({
                key: 'błąd',
                msg: 'wystąpił błąd z pobraniem raportu'
              });
            })
            .finally(() => this.loadingDownloadCustomCustomerFields = false);
      },

      /* *** SU methods *** */
      netTelCallSimulate () {
        axios.put('/api/internal/net-tel/call/leadId', {
          // axios.put('/api/internal', {
          'status': 'answered',
          'call_status': 'POTZAM',
          'phone_number': 'anonymous',
          'agent_login': 'anton',
          'is_inbound': 0,
          'campaign': '01_SU_PL'
        });
      },

      migrateNewOrders: () => axios.post('/api/migrator/migrateNewOrders'),
      migrateOrderAddresses: () => axios.post('/api/migrator/migrateOrderAddresses'),
      migrateOrderStatusesChanges: () => axios.post('/api/migrator/migrateOrderStatusesChanges'),
      migrateNewOrderProducts: () => axios.post('/api/migrator/migrateNewOrderProducts'),
      migrateNewOrderShippingTracking: () => axios.post('/api/migrator/migrateNewOrderShippingTracking'),
      migrateNewOrderPayments: () => axios.post('/api/migrator/migrateNewOrderPayments'),
      assignStatusesToOrders: () => axios.post('/api/migrator/assignStatusesToOrders'),
      assignCarriersToOrders: () => axios.post('/api/migrator/assignCarriersToOrders'),
      migrateCustomers: () => axios.post('/api/migrator/migrateCustomers'),
      migrateAll: () => axios.post('/api/migrator/migrateAll/zq1zkko2cu/01vm78v90b'),

      assignCustomerPhones: () => axios.post('/api/migrator/assignCustomerPhones'),
      testScoring: () => axios.post('/api/orders/test-scoring'),
      writeNonContactCustomers: () => axios.post('/api/customer-statuses/check-non-contact-customers/a2nzp254fg/0pu4gga72a'),
      separateCustomer: () => axios.post('/api/customers-relations/separate-customer'),
    }
  };
</script>

<style scoped lang="scss">
._separator{
  border-color: #5e95cc;
}
</style>