<template>
  <v-form
      ref="callSummaryForm"
      v-if="CURRENT_CALL"
      v-model="valid"
  >
    <div
        class="call-popover-wrap"
    >
      <v-btn
          class="call-popover-toggler"
          fab
          small
          color="blue lighten-5"
          @click.prevent="isToggledPopover=!isToggledPopover"
      >
        <v-icon v-if="isToggledPopover">mdi-arrow-up</v-icon>
        <v-icon v-else>mdi-arrow-down</v-icon>
      </v-btn>

      <v-card elevation="12"
              class="call-popover"
              :class="{'hide':isToggledPopover}"
      >
        <!--        <pre>{{CURRENT_CALL}}</pre>-->
        <v-card-title
            class="justify-space-between blue lighten-5 py-0 mb-2"
        >
          <h5 v-if="CURRENT_CALL.campaign">
            {{ CURRENT_CALL.campaign.title }}
          </h5>

          <h4 v-if="customer">
            {{ customer.email }}
            {{ customer.firstName }}
            {{ customer.lastName }}
          </h4>
          <h5 v-if="CURRENT_CALL.customerPhone&&CURRENT_CALL.customerPhone.phone">
            {{ CURRENT_CALL.customerPhone.phone }}
          </h5>
          <div v-else
               class="d-flex pt-2">
            <treeselect
                placeholder="wybierz email" class="-border-radius-max"
                v-model="selectedEmail"
                autofill="false"
                :async="true"
                open-direction="bottom"
                :disabled="!!selectedPhone"
                :load-options="getEmailsToFilter"/>
            <span class="white-space-nowrap px-2 pt-1">lub</span>
            <treeselect
                placeholder="wybierz telefon" class="-border-radius-max"
                v-model="selectedPhone"
                :async="true"
                open-direction="bottom"
                :disabled="!!selectedEmail"
                :load-options="getPhonesToFilter"/>
          </div>

          <template>
            <v-btn
                x-small
                v-if="CURRENT_CALL.campaign && CURRENT_CALL.campaign.uuid==='HotLead' && CURRENT_CALL.order"
                :to="{name: 'UserOrder', params: { campaignUuid: CURRENT_CALL.campaign._id, orderId: CURRENT_CALL.order }}"
            >
              szczegóły zamówienia
            </v-btn>

            <!-- todo set real campaign id -->
            <v-btn
                v-if="CURRENT_CALL.customer && CURRENT_CALL.campaign && CURRENT_CALL.campaign._id"
                x-small
                :to="{name: 'UserCustomer', params: { campaignId: CURRENT_CALL.campaign._id, id: CURRENT_CALL.customer }}"
            >
              szczegóły klienta
            </v-btn>
            <v-btn
                v-if="selectedCustomerId && CURRENT_CALL.campaign && CURRENT_CALL.campaign._id"
                x-small
                :to="{name: 'UserCustomer', params: { campaignId: CURRENT_CALL.campaign._id, id: selectedCustomerId }}"
            >
              szczegóły klienta
            </v-btn>
          </template>
          <div>
          <v-chip
              v-if="!callFinished"
              class="ma-2"
              text-color="red"
              small
          >
            rozmowa w toku
          </v-chip>
          <v-chip
              v-if="callIsHold"
              class="ma-2"
              text-color="red"
              small
          >
            rozmowa zawieszona
          </v-chip>
          </div>
          
          <template v-if="callFinished">
            <v-chip
                class="ma-2"
                text-color="blue"
            >
              rozmowa zakończona {{ callDateEnd }}
            </v-chip>
            <span v-if="countdown"
                  :class="countdown.class">
              {{ countdown.time }}
            </span>
          </template>
          <div>
            
            <v-btn
                v-if="!callFinished"
                @click.prevent="toggleCallHold"
                :color="callIsHold ? 'success' : 'warning'"
                fab
                x-small
                dark
                class="mr-2"
                :loading="loadingHoldCall"
                :disabled="!callStartedLessThanTenSeconds"
            >
              <v-icon small>mdi-pause</v-icon>
            </v-btn>
            
            <v-btn
                v-if="!callFinished"
                @click.prevent="endCall"
                color="red"
                fab
                x-small
                dark
                :loading="loadingEndCall"
                :disabled="!callStartedLessThanTenSeconds || callIsHold"
            >
              <v-icon small>mdi-phone-off</v-icon>
            </v-btn>

            <v-btn
                color="green"
                small
                dark
                class="ml-2"
                @click.prevent="finishCall"
                :loading="loadingFinishCall"
                :disabled="!callFinished"
                v-if="callFinished"
            >
              zapisz
            </v-btn>
          </div>


        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-combobox
                  v-if="statusesPerCampaign"
                  v-model="status"
                  :items="statusesPerCampaign"
                  label="status"
                  item-text="title"
                  item-value="_id"
                  deletable-chips
                  :rules="notEmptyFieldRules"
              />
            </v-col>
            <!-- ----------------- contact later ------------------ -->
            <v-col v-if="isStatusContactLater(status)">
              <label for="dateToRecll">
                data
              </label>

              <DateTimePicker
                  id="dateToRecll"
                  type="datetime"
                  class="theme-primary datepicker-main"
                  :min-datetime="now"
                  placeholder="data"
                  v-model="recallDate"
                  value-zone="Europe/Warsaw"
                  zone="Europe/Warsaw"
              />
            </v-col>
            <!-- ----------------- contact later ------------------ -->

            <v-col v-if="isSetAssignableStatuses && canOwnedCustomer">
              <v-checkbox
                  v-model="assignToUser"
                  label="dodaj do portfela"
              />
            </v-col>
            <v-col>
              <v-checkbox
                  v-model="childrenProducts"
                  label="zainteresowany KIDS"
              />
              <v-checkbox
                  v-model="dieticianCare"
                  label="opieka dietetyka"
                  class="pt-0 mt-0"
              />
              <v-checkbox
                  v-model="metamorphosis"
                  label="metamorfoza"
                  class="pt-0 mt-0"
              />
            </v-col>

          </v-row>
          <v-textarea
              label="komentarz"
              v-model="comment"
              hint="komentarz"
              rows="1"
              :rules="notEmptyFieldRules"
          />
          <!-- -----------------  new order ------------------ -->
          <template v-if="isStatusSale(status)">
            <OrderCreationForm
                :set-current-order="setCurrentOrder"
                :customer-id-prop="customerId && customerId._id ? customerId._id : customerId"
                :parent-order-id="parentOrderId"
            />

          </template>

          <!-- -----------------  new order ------------------ -->


        </v-card-text>
      </v-card>
    </div>

    <v-snackbar
        v-model="hasErrors"
        :multi-line="true"
    >
      <p v-for="(error, index) in errors"
         :key="`error${index}`"
         :class="{'mb-0': index===errors.length-1}">
        {{ error.key }} - {{ error.msg }}
      </p>

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="errors = []"
        >
          zamknij
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
  import moment from 'moment';

  import StatusesMixin from '@/mixins/StatusesMixin';
  import CustomersMixin from '@/mixins/CustomersMixin';
  import FormValidatorMixin from '@/mixins/FormValidatorMixin';

  import OrderCreationForm from '@/components/OrderCreationForm';

  import ApiCalls from '@/api/Calls';
  import { mapActions, mapGetters } from 'vuex';
  import ScoredOrdersMixin from '@/mixins/ScoredOrdersMixin';
  import ApiCustomerEmails from '@/api/CustomerEmails';
  import ApiCustomerPhones from '@/api/CustomerPhones';
  import ErrorsMixin from '@/mixins/ErrorsMixin';
  import ProductsMixin from '@/mixins/ProductsMixin';
  import CountriesMixin from '@/mixins/CountriesMixin';
  import CarriersMixin from '@/mixins/CarriersMixin';
  import PaymentsMixin from '@/mixins/PaymentsMixin';

  const apiCalls = new ApiCalls();
  const apiCustomerEmails = new ApiCustomerEmails();
  const apiCustomerPhones = new ApiCustomerPhones();
  // todo check luxon
  export default {
    name: 'CallSummary',
    mixins: [
      ErrorsMixin,
      StatusesMixin,
      CustomersMixin,
      FormValidatorMixin,
      ScoredOrdersMixin,
      ProductsMixin,
      CountriesMixin,
      CarriersMixin,
      PaymentsMixin,
    ],
    components: {
      OrderCreationForm
    },

    data: () => ({
      assignToUser: false,
      childrenProducts: false,
      dieticianCare: false,
      metamorphosis: false,
      comment: '',
      status: null,
      now: moment().toISOString(),
      recallDate: moment(moment()).add(3, 'hours').toISOString(),
      isToggledPopover: false,

      newOrder: {},

      loadingFinishCall: false,
      loadingHoldCall: false,
      loadingEndCall: false,
      selectedEmail: null,
      selectedPhone: null,
      callStartedLessThanTenSeconds: false,
      countdown: null,
      timer: null
    }),
    computed: {
      ...mapGetters([
        'CURRENT_CALL',
        'IS_ACTIVE_USER',
        'GET_SOCKET',
        'AUTH_USER'
      ]),
      /* *** *** */
      statusesPerCampaign () {
        if (!this.STATUSES) return [];

        if (
            !this.CURRENT_CALL
            || !this.CURRENT_CALL.campaign
            || !this.CURRENT_CALL.campaign.statuses
        ) return this.STATUSES;

        const statusesIds = this.CURRENT_CALL.campaign.statuses;
        return this.STATUSES.filter(el => statusesIds.indexOf(el._id) !== -1);
      },
      isSetAssignableStatuses () {
        return this.statusesPerCampaign.findIndex(el => el.assignToUser) > -1;
      },
      canOwnedCustomer () {
        if (!this.customer) return true;
        if (!this.customer.owner) return true;
        if (!this.AUTH_USER) return true;
        return false;
        // feature - user had option to remove customer from owned
        // return this.customer.owner === this.AUTH_USER._id;
      },
      /* *** *** */
      callFinished () {
        if (!this.CURRENT_CALL) return true;
        return !!this.CURRENT_CALL.dateEnd;
      },
      callIsHold () {
        if (!this.CURRENT_CALL) return true;
        return !!this.CURRENT_CALL.isHold;
      },
      /* *** *** */
      parentOrderId () {
        return this.CURRENT_CALL.order;
      },
      customerId () {
        if (!this.CURRENT_CALL) return null;
        return this.CURRENT_CALL.customer || null;
      },
      customer () {
        if (!this.customerId) return null;
        const customer = this.CUSTOMER(this.customerId);
        if (!customer) {
          this.getCustomer(this.customerId);
          return;
        }

        return customer;
      },

      selectedCustomerId () {
        if (this.customer) return null;

        if (this.selectedPhone) {
          return this.selectedPhone.split('|')[0];
        }
        if (this.selectedEmail) {
          return this.selectedEmail.split('|')[0];
        }
        return null;
      },

      callDateEnd () {
        if (!this.CURRENT_CALL.dateEnd) return null;
        return moment(this.CURRENT_CALL.dateEnd).format('DD.MM.YYYY HH:mm');
      },

    },
    watch: {
      customerId () {
        if (!this.customerId) return;
        this.getCustomer(this.customerId).then(() => {
          this.childrenProducts = this.customer?.childrenProducts || false;
          this.dieticianCare = this.customer?.dieticianCare || false;
          this.metamorphosis = this.customer?.metamorphosis || false;
        });
      },
      CURRENT_CALL: {
        deep: true,

        handler () {
          this.assignToUser = false;
          this.validateLessThanTenSeconds();
        }
      },
      'CURRENT_CALL.dateEnd' () {
        if (!this.CURRENT_CALL?.dateEnd) {
          clearInterval(this.timer);
          return this.countdown = null;
        }
        // setInterval(this.setTimeout(), 1000);
        this.timer = setInterval(() => {
          if (!this.CURRENT_CALL?.dateEnd) return this.countdown = null;
          const secondsLeft = 30 - (moment().unix() - moment(this.CURRENT_CALL.dateEnd).unix());

          if (secondsLeft <= 0) {
            clearInterval(this.timer);
            return this.countdown = {
              time: '00:00',
              class: 'color-danger'
            };
          }

          this.countdown = {
            time: secondsLeft.toFixed(),
            class: ''
          };
        }, 1000);
      }
    },
    beforeDestroy () {
      clearInterval(this.timer);
    },
    created () {
      this.assignToUser = false;
      if (!this.CURRENT_CALL) {
        apiCalls.getCurrent().then(() => {
          this.validateLessThanTenSeconds();
          this.childrenProducts = this.customer?.childrenProducts || false;
          this.dieticianCare = this.customer?.dieticianCare || false;
          this.metamorphosis = this.customer?.metamorphosis || false;
        });
      } else {
        this.validateLessThanTenSeconds();
      }

      this.GET_SOCKET.on(
          'receivedCustomerCall',
          data => {
            if (data.currentCall && data.currentCall.dateEnd && data.currentCall.finished) {
              this.clearData();
              this.$store.dispatch('clearCurrentCall');
            } else {
              this.$store.dispatch('setCurrentCall', JSON.parse(data));
              this.validateLessThanTenSeconds();
            }
          }
      );
    },
    methods: {
      ...mapActions([
        'setCurrentCall',
        'clearCurrentCall'
      ]),
      /* *** *** */
      validateLessThanTenSeconds () {
        this.callStartedLessThanTenSeconds = false;
        const now = moment();
        if (!this.CURRENT_CALL) return;
        this.callStartedLessThanTenSeconds = now.diff(moment(this.CURRENT_CALL.dateStart), 'seconds') > 10;
        if (!this.callStartedLessThanTenSeconds) {
          setTimeout(() => {
            this.callStartedLessThanTenSeconds = true;
          }, 5000);
        }
      },
      /* *** *** */
      setCurrentOrder (payload) {
        this.newOrder = payload;
      },
      /* *** *** */
      endCall () {
        this.loadingEndCall = true;
        apiCalls.patch(this.CURRENT_CALL._id)
            .finally(() => this.loadingEndCall = false);
      },
      toggleCallHold () {
        this.loadingHoldCall = true;
        apiCalls.toggleHold(this.CURRENT_CALL._id)
            .finally(() => this.loadingHoldCall = false);
      },
      finishCall () {
        const call = { ...this.CURRENT_CALL };
        // this.redirectToFirstActiveScoredOrder(this.CURRENT_CALL.order);
        this.errors = [];
        this.loadingFinishCall = true;
        if (!this.validate('callSummaryForm')) {
          this.loadingFinishCall = false;
          return;
        }

        const recallDate = this.recallDate
            ? this.recallDate.substr(0, this.recallDate.length - 6) + '+00:00'
            : null;

        if (this.status.isSale) {
          const newOrder = this.newOrder;
          if (typeof newOrder.address !== 'string' && typeof newOrder.address !== 'object') {
            if (!newOrder.newAddress || !newOrder.newAddress.email
                || !newOrder.newAddress.phone
                || !newOrder.newAddress.firstName
                || !newOrder.newAddress.lastName
                || !newOrder.newAddress.address
                || !newOrder.newAddress.postCode
                || !newOrder.newAddress.city) {

              this.errors.push({
                key: 'błąd',
                msg: 'adres jest niepoprawny'
              });
              this.loadingFinishCall = false;
              return;
            }
          }

          if (!newOrder.carrier) {
            this.errors.push({
              key: 'błąd',
              msg: 'wybierz metodę dostawy'
            });
            this.loadingFinishCall = false;
            return;
          }

          if (!newOrder.payment) {
            this.errors.push({
              key: 'błąd',
              msg: 'wybierz płatność'
            });
            this.loadingFinishCall = false;
            return;
          }
        }

        const params = {
          assignToUser: this.assignToUser,
          childrenProducts: this.childrenProducts,
          dieticianCare: this.dieticianCare,
          metamorphosis: this.metamorphosis,
          comment: this.comment,
          status: this.status,
          recallDate,
          newOrder: this.newOrder
        };

        if (!this.CURRENT_CALL.customer && this.selectedPhone) {
          params.selectedCustomerId = this.selectedPhone.split('|')[0];
          params.selectedCustomerPhoneId = this.selectedPhone.split('|')[1];
        }
        if (!this.CURRENT_CALL.customer && this.selectedEmail) {
          params.selectedCustomerId = this.selectedEmail.split('|')[0];
        }

        if (call.isExternal && call.campaign && call.campaign.uuid === 'HotLead' && !this.parentOrderId && this.customer && this.customer.orders && this.customer.orders.length > 0) {
          const orders = [...this.customer.orders].sort(function (a, b) {
            const dateToCheckA = a.magentoCreated ? a.magentoCreated : a.magentoUpdated;
            const dateToCheckB = b.magentoCreated ? b.magentoCreated : b.magentoUpdated;
            return new Date(dateToCheckB) - new Date(dateToCheckA) > 0
                ? 1 : -1;
          });
          params.parentOrderId = orders[0]._id;
        }

        if (this.parentOrderId) {
          params.parentOrderId = this.parentOrderId;
        }

        // console.log(this.customer);
        // if (params) return;
        apiCalls.put(this.CURRENT_CALL._id, params).then(() => {
          this.clearData();
          if (this.IS_ACTIVE_USER && this.$route.name === 'UserOrder') {
            let orderId = 'null';
            if (call && call.order && typeof call.order === 'string') {
              orderId = call.order;
            } else if (call.order && call.order._id && typeof call.order._id === 'string') {
              orderId = call.order._id;
            }
            this.redirectToFirstActiveScoredOrder(orderId);
          }
        }).finally(() => this.loadingFinishCall = false);
      },

      getEmailsToFilter ({ searchQuery, callback }) {
        if (searchQuery.length < 3) return callback(null, []);
        apiCustomerEmails.get(searchQuery).then(emails => {
          const options = emails.map(email => ({
            id: `${email.customer}|${email._id}|${email.email}`,
            label: email.email
          }));
          callback(null, options);
        });
      },
      getPhonesToFilter ({ searchQuery, callback }) {
        if (searchQuery.length < 3) return callback(null, []);
        apiCustomerPhones.get(searchQuery).then(phones => {
          const options = phones.map(phone => ({
            id: `${phone.customer}|${phone._id}|${phone.phone}`,
            label: phone.phone
          }));
          callback(null, options);
        });
      },

      clearData () {
        this.comment = '';
        this.status = null;
        this.now = moment().toISOString();
        this.recallDate = moment(moment()).add(3, 'hours').toISOString();
        this.newOrder = {};

        this.assignToUser = false;
        this.status = null;
        this.now = moment().toISOString();
        this.recallDate = moment(moment()).add(3, 'hours').toISOString();
        this.isToggledPopover = false;
        this.newOrder = {};
        this.loadingFinishCall = false;
        this.loadingEndCall = false;
        this.selectedEmail = null;
        this.selectedPhone = null;
      }
    }
  };
</script>

<style scoped lang="scss">
  .white-space-nowrap {
    white-space: nowrap;
  }

  .datepicker-main {
    border-bottom: 1px solid;
  }

  .call-popover-wrap {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;

    .call-popover {
      max-height: calc(100vh - 130px);
      overflow: auto;

      &.hide {
        height: 45px;
      }
    }

    .call-popover-toggler {
      position: absolute;
      right: -15px;
      top: -30px;
      z-index: 9;
    }
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background: #ccc !important;
  }

  .color-danger {
    color: red !important;
  }
</style>
