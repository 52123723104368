<template>
  <svg viewBox="0 0 477.93 68.27" height="22px" fill="#292728">
    <path d="M42.92 0h16.97v66.46H42.92z"></path>
    <path
      d="M89.58 15.02c-14.64 0-26.13 12.39-26.13 26.76a26.38 26.38 0 0026.4 26.4c10.51 0 19.58-6.37 24.34-17.24H98.92c-2.42 3.15-5 4.67-9.07 4.67-6.1 0-10.86-3.77-11.67-9.43h37.36a19 19 0 00.36-3.95c0-15.45-11.41-27.21-26.32-27.21zm-11.22 21.1c1.17-5.3 5.84-8.53 11.22-8.53 5.75 0 10.24 3.41 11.41 8.53z"></path>
    <path
      d="M159.13 22.02h-.18c-2.87-4.58-8.53-7-14.64-7-14.28 0-25 11.85-25 26.58 0 15.09 10.23 26.67 25 26.67 6.56 0 12.49-2.69 14.64-7.09h.18v5.39h14.89V16.72h-14.89zm-12.48 32.33a12.45 12.45 0 01-12.4-12.57c0-7.18 5.39-12.84 12.22-12.84a12.65 12.65 0 0112.66 12.66 12.46 12.46 0 01-12.48 12.75z"></path>
    <path d="M179.53.11h14.91v66.46h-14.91z"></path>
    <path transform="translate(-181.98 -263.5)" fill="#292728"
          d="M387.68 280.23h-7.37v11.31h7.37v38.53h14.91v-38.53h7.18v-11.31h-7.18v-16.62h-14.91z"></path>
    <path
      d="M259.28 15.02c-5.92 0-10.77 2.42-12.93 6.46V.11h-14.91v66.46h14.91v-28c0-6.2 2.7-9.52 8.35-9.52 5.48 0 8 3.68 8 10.15v27.39h14.9V35.23c0-13.03-6.91-20.21-18.32-20.21z"></path>
    <path transform="translate(-181.98 -263.5)" fill="#292728"
          d="M464.76 330.07h36.64v-14.91h-19.66v-51.55h-16.98z"></path>
    <path
      d="M360.75 22.02h-.18c-2.88-4.58-8.53-7-14.64-7-14.28 0-25 11.85-25 26.58 0 15.09 10.24 26.67 25 26.67 6.55 0 12.48-2.69 14.64-7.09h.18v5.39h14.91V16.72h-14.91zm-12.49 32.33a12.45 12.45 0 01-12.39-12.57c0-7.18 5.39-12.84 12.21-12.84a12.65 12.65 0 0112.67 12.66 12.46 12.46 0 01-12.49 12.75z"></path>
    <path
      d="M409.91 15.02c-5.21 0-10.24 1.44-14.19 5.66V.11h-14.91v66.46h14.28v-5.3h.18c2.24 4.22 8.26 7 14.28 7 15.35 0 25.14-11.58 25.14-26.94 0-16.61-11.85-26.31-24.78-26.31zm-2.61 39.33c-6.91 0-12.12-5.47-12.12-12.84a12.31 12.31 0 1112.12 12.84z"></path>
    <path
      d="M462.21 36.12c-6.91-1.79-9.34-3.14-9.34-5.83 0-1.89 1.8-3.33 4.31-3.33s4.49.9 4.58 3.78h14.64c-.72-9.79-8.35-15.72-19.13-15.72-10.06 0-19.31 6.37-19.31 16.25 0 5.84 3.33 10.78 15.9 14.64 7.45 2.34 9.16 3.77 9.16 6.56 0 2.33-2.6 3.86-5.21 3.86-3 0-5.21-1.44-5.66-4.58h-14.82c1.08 10.15 9.07 16.52 20.3 16.52s20.3-6.55 20.3-17c0-7.24-3.86-12-15.72-15.15z"></path>
    <path d="M20.39 44.71a21.11 21.11 0 0019.11-23 21.12 21.12 0 00-19.11 23z"></path>
    <path d="M0 0h16.97v66.46H0z"></path>
  </svg>
</template>

<script>
  export default {
    name: 'DefaultLogo'
  }
</script>

<style scoped>

</style>
