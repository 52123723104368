import store from '@/store';
import _ from 'lodash';
import axios from 'axios';

export const checkUserData = async next => {
  if (_.isEmpty(localStorage.getItem('authToken'))) return next();
  if (!_.isEmpty(store.getters.AUTH_USER)) return next();
  if (!_.isEmpty(store.getters.AUTH_USER)) return next();

  const res = await axios.get('/api/users/current');

  if (res.data) {
    await store.dispatch('saveUserData', res.data);
    await store.dispatch('toggleAuthState', true);

    next();
  }
};
