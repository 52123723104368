import axios from 'axios';

export default class {
  // baseUrl = 'https://api-gw.dhlparcel.nl/parcel-shop-locations/pl';
  baseUrl = '/api/dhl-pop';

  getDhlPops = (searchQuery) => new Promise((resolve) => {
    axios.get(this.baseUrl, {
      params: { searchQuery}
    }).then(({ data }) => {
      resolve(
        data.map(el => ({
            id: el.id,
            label: `${el.address.street} ${el.address.number}, ${el.address.zipCode} ${el.address.city} ${el.name}`
          })
        )
      );
    });
  });
}
