import Vue from 'vue';
import Vuex from 'vuex';
import { parseMultipleData, parseSingleInMultipleData } from '@/utils/arrayParser';

Vue.use(Vuex);

export default {
  state: {
    campaigns: [],
  },
  getters: {
    CAMPAIGNS: state => state.campaigns,
    CAMPAIGN: state => (id) => state.campaigns.find(el => el._id === id),

    CAMPAIGN_FROM_MIXED_ID: state => (mixedId) => {
      if (!mixedId) return state.campaigns.find(el => el.uuid === 'NotFound');
      const index = state.campaigns.findIndex(el =>
        [el._id, el.uuid, el.externalId].indexOf(mixedId) > -1
      );

      if (index > -1) return state.campaigns[index];

      return state.campaigns.find(el => el.uuid === 'NotFound');
    }
  },
  mutations: {
    SET_ALL_CAMPAIGN: (state, payload) => state.campaigns = payload,
    SET_CAMPAIGNS: (state, payload) => {
      state.campaigns = parseMultipleData(state.campaigns, payload);
    },
    SET_CAMPAIGN: (state, payload) => state.campaigns = parseSingleInMultipleData(state.campaigns, payload)
  },
  actions: {
    setCampaigns ({ commit }, payload) {
      if (payload) commit('SET_ALL_CAMPAIGN', payload);
    },
  }
};
