import Profile from './_profile';
import UserTemplate from '@/templates/UserTemplate';

export default [
    {
        path: '/user',
        name: 'User',
        component: UserTemplate,
        children: [
            {
                path: '/',
                name: 'UserDashboard',
                component: () => import('@/views/user/UserDashboard.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                    transitionName: 'router-anim',
                    enterActive: 'animated fadeIn'
                }
            },
            {
                path: '/customers/:type/:forAdmin?',
                name: 'UserCustomers',
                component: () => import('@/views/user/UserCustomers.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                    transitionName: 'router-anim',
                    enterActive: 'animated fadeIn'
                }
            },
            { // todo from UserCustomers
                path: '/customer/:campaignId/:id',
                name: 'UserCustomer',
                component: () => import('@/views/user/UserCustomer.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                    transitionName: 'router-anim',
                    enterActive: 'animated fadeIn'
                }
            },
            ...Profile,

            {
                path: 'inbound/dashboard/:campaign_uuid/:phone?',
                name: 'InboundDashboard',
                component: () => import('@/views/user/UserInboundDashboard.vue'),
                meta: {
                    requiresAuth: true,
                    transitionName: 'router-anim',
                    enterActive: 'animated fadeIn'
                }
            },
            {
                path: '/admin/reports',
                name: 'AdminReports',
                component: () => import('@/views/admin/AdminReports.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                    transitionName: 'router-anim',
                    enterActive: 'animated fadeIn'
                }
            },
            {
                path: '/admin/stocks',
                name: 'AdminStocks',
                component: () => import('@/views/admin/AdminStocks.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                    transitionName: 'router-anim',
                    enterActive: 'animated fadeIn'
                }
            },
            {
                path: '/admin/new-order',
                name: 'AdminNewOrder',
                component: () => import('@/views/admin/AdminNewOrder.vue'),
                props: true,
                meta: {
                    requiresAuth: true,
                    transitionName: 'router-anim',
                    enterActive: 'animated fadeIn'
                }
            },
            // {
            //   path: 'customers/:campaign_uuid/:phone?',
            //   name: 'UserCustomers',
            //   component: () => import('@/views/user/UserCustomersList.vue'),
            //   meta: {
            //     requiresAuth: true,
            //     transitionName: 'router-anim',
            //     enterActive: 'animated fadeIn'
            //   }
            // },
        ]
    }
];
