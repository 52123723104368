import axios from 'axios';
import store from '@/store';

const CancelToken = axios.CancelToken;
let source = {};

export default class {
  baseUrl = '/api/customer-phones';

  /* *** customer phones *** */
  updateCustomerPhones = async (newPhone = '', mainPhoneId = null, phonesToDisable = [], customerId) => {
    await axios.put(`${this.baseUrl}/${customerId}`, { newPhone, mainPhoneId, phonesToDisable })
      .then(({ data }) => {
        store.dispatch('setCustomer', data.customer);
        if (data.orders) {
          store.dispatch('setOrders', data.orders);
        }
      });
  };

  get = async (phone) => {
    let cancelTokenKey = 'customersPhones';
    if (source[cancelTokenKey]) source[cancelTokenKey].cancel();
    source[cancelTokenKey] = CancelToken.source();

    const phones = await axios.get(this.baseUrl, {
      cancelToken: source[cancelTokenKey].token,
      params: { phone }
    }).then(({ data, status }) => {
      if (status === 202) return [];
      return data;
    });
    return phones;
  };
}
