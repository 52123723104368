import axios from 'axios';
import store from '@/store';

export default class {
  baseUrl = '/api/users';

  get = () => new Promise(() => {
    axios.get(this.baseUrl)
      .then(({ data }) => store.dispatch('setUsers', data.users));
  });
}
