<template>
  <div class="_customerStatusChanger">
    <v-row>
      <v-col>
        <v-combobox
            v-if="CAMPAIGNS"
            v-model="selectedCampaign"
            :items="campaignsForSelect"
            label="kampania"
            item-text="title"
            item-value="_id"
            deletable-chips
            :rules="notEmptyFieldRules"
            :readonly="!!customer.owner || !!selectedCampaign.notChangeable"
        />

        <v-combobox
            v-if="statusesPerCampaign"
            v-model="status"
            :items="statusesPerCampaign"
            label="status"
            item-text="title"
            item-value="_id"
            deletable-chips
            :rules="notEmptyFieldRules"
        />

        <!-- ----------------- contact later ------------------ -->
        <template v-if="isStatusContactLater(status)">
          <label for="dateToRecll">
            data
          </label>
          <DateTimePicker
              id="dateToRecll"
              type="datetime"
              class="theme-primary datepicker-main"
              :min-datetime="now"
              placeholder="data"
              v-model="recallDate"
              value-zone="Europe/Warsaw"
              zone="Europe/Warsaw"
          />
        </template>

      </v-col>
      <!-- ----------------- contact later ------------------ -->
      <v-col>
        <template v-if="isSetAssignableStatuses&&canOwnedCustomer">
          <v-checkbox
              v-model="assignToUser"
              label="dodaj do portfela"
          />
        </template>
        <v-checkbox
            v-model="childrenProducts"
            label="zainteresowany KIDS"
            :class="isSetAssignableStatuses&&canOwnedCustomer ? 'pt-0 mt-0' : ''"
        />
        <v-checkbox
            v-model="dieticianCare"
            label="opieka dietetyka"
            class="pt-0 mt-0"
        />
        <v-checkbox
            v-model="metamorphosis"
            label="metamorfoza"
            class="pt-0 mt-0"
        />
      </v-col>

    </v-row>
    <v-textarea
        label="komentarz"
        v-model="comment"
        hint="komentarz"
        rows="1"
        :rules="notEmptyFieldRules"
    />
    <!-- -----------------  new order ------------------ -->
    <template v-if="isStatusSale(status)">
      <OrderCreationForm
          :set-current-order="setCurrentOrder"
          :customer-id-prop="customerId"
          :parent-order-id="parentOrderId"
      />

    </template>
    <!-- -----------------  new order ------------------ -->
  </div>
</template>

<script>
  import StatusesMixin from '@/mixins/StatusesMixin';
  import CustomersMixin from '@/mixins/CustomersMixin';
  import FormValidatorMixin from '@/mixins/FormValidatorMixin';
  import OrderCreationForm from '@/components/OrderCreationForm';
  import moment from 'moment';
  import { mapActions, mapGetters } from 'vuex';
  import CampaignsMixin from '@/mixins/CampaignsMixin';
  import ProductsMixin from '@/mixins/ProductsMixin';
  import CountriesMixin from '@/mixins/CountriesMixin';
  import CarriersMixin from '@/mixins/CarriersMixin';
  import PaymentsMixin from '@/mixins/PaymentsMixin';

  export default {
    name: 'CustomerStatusChanger',
    mixins: [
      StatusesMixin,
      CustomersMixin,
      FormValidatorMixin,
      CampaignsMixin,
      ProductsMixin,
      CountriesMixin,
      CarriersMixin,
      PaymentsMixin,
    ],
    components: {
      OrderCreationForm
    },
    props: {
      customer: {
        type: Object,
        required: true
      },
      campaign: {
        type: Object,
        required: false
      },
      parentOrderId: {
        type: String,
        required: false
      },
      setCommentData: {
        type: Function,
        required: true
      },
      setSelectedCampaign: {
        type: Function,
        required: true
      }
    },
    data: () => ({
      assignToUser: false,
      childrenProducts: false,
      dieticianCare: false,
      metamorphosis: false,
      comment: '',
      status: null,
      now: moment().toISOString(),
      recallDate: moment(moment()).add(3, 'hours').toISOString(),
      isToggledPopover: false,

      newOrder: {},

      loadingFinishCall: false,
      loadingEndCall: false,

      selectedCampaign: null
    }),
    computed: {
      ...mapGetters([
        'IS_ACTIVE_USER',
        'GET_SOCKET',
        'AUTH_USER',
      ]),
      /* *** *** */
      campaignsForSelect () {
        return this.CAMPAIGNS.filter(el => !el.isHidden);
      },
      /* *** *** */
      statusesPerCampaign () {
        // console.log(this.selectedCampaign);
        if (!this.selectedCampaign || !this.selectedCampaign._id) return [];
        const campaign = this.CAMPAIGN(this.selectedCampaign._id);
        // console.log(campaign);
        if (!campaign || !campaign.statuses || !this.STATUSES) return [];
        // console.log(campaign);
        // console.log(campaign.title);
        // console.log(campaign.statuses);
        const statusesIds = campaign.statuses;
        return this.STATUSES.filter(el => statusesIds.indexOf(el._id) !== -1);
      },
      isSetAssignableStatuses () {
        // console.log(this.selectedCampaign);
        return this.statusesPerCampaign.findIndex(el => el.assignToUser) > -1;
      },

      canOwnedCustomer () {
        if (!this.customer) return true;
        if (!this.customer.owner) return true;
        return false;
        // feature - user had option to remove customer from owned
        // return this.customer.owner === this.AUTH_USER._id;
      },
    },
    watch: {
      assignToUser () {
        this.updateParentData();
      },
      childrenProducts () {
        this.updateParentData();
      },
      dieticianCare () {
        this.updateParentData();
      },
      metamorphosis () {
        this.updateParentData();
      },
      comment () {
        this.updateParentData();
      },
      status () {
        this.updateParentData();
      },
      recallDate () {
        this.updateParentData();
      },
      campaign () {
        // console.log(this.campaign);
        this.assignToUser = false;
        this.selectedCampaign = this.campaign;
        // console.log(this.selectedCampaign);
      },
      selectedCampaign () {
        this.assignToUser = false;
        this.setSelectedCampaign(this.selectedCampaign);
      },
      customer () {
        this.childrenProducts = this.customer.childrenProducts || false;
        this.dieticianCare = this.customer.dieticianCare || false;
        this.metamorphosis = this.customer.metamorphosis || false;
      }
    },
    mounted () {
      this.childrenProducts = this.customer.childrenProducts || false;
      this.dieticianCare = this.customer.dieticianCare || false;
      this.metamorphosis = this.customer.metamorphosis || false;
      if (!!this.customer.owner) {
        const campaign = this.CAMPAIGNS.find(({ uuid }) => uuid === 'Owner');
        this.selectedCampaign = campaign;
      }
    },
    created () {
      this.selectedCampaign = this.campaign;
      this.GET_SOCKET.on(
          'receivedCustomerCall',
          data => {
            if (this.currentCall && this.currentCall.dateEnd && this.currentCall.finished) {
              this.$store.dispatch('clearCurrentCall');
            } else {
              this.$store.dispatch('setCurrentCall', JSON.parse(data));
            }
          }
      );
    },
    methods: {
      ...mapActions([
        'setCurrentCall'
      ]),
      updateParentData () {
        this.setCommentData({
          childrenProducts: this.childrenProducts,
          dieticianCare: this.dieticianCare,
          metamorphosis: this.metamorphosis,
          assignToUser: this.assignToUser,
          comment: this.comment,
          status: this.status,
          recallDate: this.recallDate,
          newOrder: this.newOrder,
        });
      },
      /* *** *** */
      setCurrentOrder (payload) {
        this.newOrder = payload;
        this.updateParentData();
      },
    }
  };
</script>

<style scoped lang="scss">
  .white-space-nowrap {
    white-space: nowrap;
  }

  .datepicker-main {
    border-bottom: 1px solid;
  }

  .call-popover-wrap {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;

    .call-popover {
      max-height: calc(100vh - 130px);
      overflow: auto;

      &.hide {
        height: 45px;
      }
    }

    .call-popover-toggler {
      position: absolute;
      right: -15px;
      top: -30px;
      z-index: 9;
    }
  }

  ._customerStatusChanger {
    min-width: 600px;
  }
</style>
