<template>
  <div class="wrap">
    <strong v-if="rule"
            :class="rule.classNames">
      {{ rule.text }}
    </strong>
    <br v-if="rule">
    <span v-if="code"
          :class="code.classNames">
      {{ code.text }}
    </span>
    <!--    {{ orderCreated }}-->

    <span v-if="!rule&&!code">
      brak kodu
    </span>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'PromoCodeDescription',
    props: {
      ruleName: {
        type: String,
      },
      codeCode: {
        type: String,
      },
      orderCreated: {
        type: String
      }
    },
    computed: {
      rule () {
        if (!this.ruleName) return null;
        let text = this.ruleName;
        let classNames = '';

        if (this.ruleName.indexOf('.{callcenter}') > -1) {
          classNames = 'success--text';
          text = 'Kod z 4call';
        }

        if (this.ruleName.indexOf('.[influencer]') > -1) {
          const isDanger = this.orderCreated && moment(this.orderCreated)
              ? moment().diff(moment(this.orderCreated), 'days') < 60 // todo check
              : true;
          if (isDanger) {
            classNames = 'red--text';
          }
        }

        return { classNames, text };
      },

      code () {
        if (!this.ruleName && !this.codeCode) return null;
        let text = this.codeCode;
        let classNames = '';

        if (!this.ruleName) return { classNames, text };
        if (this.ruleName.indexOf('.{callcenter}') > -1) {
          const titleArr = this.ruleName.split('.');
          text = 'konsultant: ' + titleArr[2] + titleArr[3];
        }

        return { classNames, text };
      },
    }
  };
</script>

<style scoped>

</style>
