<template>
  <div>
    <v-card
        class="mb-2"
    >
      <v-card-title
          class="lighten-3 white--text py-2 mb-2"
          :class="{'red':customer.disabled, 'blue':!customer.disabled}"
      >
        Uwagi
      </v-card-title>
      <v-card-text>
        <div class="mh-25vh mr-n4">
          <div v-for="(status, index) in customerStatuses"
               :key="`${index}--customer-statuses`">
            <small>{{ moment(status.createdAt).format('DD.MM.YYYY HH:mm') }}</small>
            <strong v-if="status.campaign&&status.campaign.title">
              {{ status.campaign.title }}
            </strong>
            <br>
            <template v-if="status.childrenProducts">
              <small>zainteresowany <b class="primary--text">KIDS</b></small>
              <br>
            </template>
            <template v-if="status.dieticianCare">
              <small>opieka <b class="warning--text">dietetyka</b></small>
              <br>
            </template>
            <template v-if="status.metamorphosis">
              <small><b class="warning--text">metamorfoza</b></small>
              <br>
            </template>

            <strong
                v-if="STATUS(status.status)"
                :class="statusColor(status.status)"
            >
              {{ STATUS(status.status).title }}
              <span v-if="STATUS(status.status).contactLater && status.dateToRecall">
                {{ moment(status.dateToRecall).format('DD.MM.YYYY HH:mm') }}
              </span>
            </strong>
            <br>

            <p class="black--text pb-0 mb-0">{{ status.description }}</p>
            <span> {{ status.user.email }}</span>
            <v-divider
                class="my-2"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card
        class="mb-2"
    >
      <v-card-title
          class="blue lighten-3 white--text py-2 mb-2"
      >
        Maile
      </v-card-title>
      <v-card-text class="pb-0">

        <div v-if="customerMainEmail"
             class="mh-25vh mr-n4">
          <v-radio-group
              v-model="customerMainEmailId"
              hide-details
              class="mt-0 pb-0">
            <v-radio
                v-for="email in customerEmailsForSelect"
                :key="email.key"
                :label="email.label"
                :value="email.id"
                :disabled="email.disabled"

            >
              <template v-slot:label>
                <strong
                    :class="{'blue--text':email.primaryTextClass}"
                >
                  {{ email.label }}
                </strong>
                <v-btn icon @click.prevent="disableEmail(email.id)"
                       :color="emailsToDisable.indexOf((email.id))>-1 ? 'red' :''">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
            </v-radio>
          </v-radio-group>
        </div>

        <v-text-field
            v-model="newEmail"
            label="dodaj email"
            type="email"
        />

      </v-card-text>
      <v-card-actions>

        <v-spacer/>
        <v-btn class="float-right"
               @click.prevent="saveEmailChanges"
               :loading="loadingEmailChanges"
               x-small
               dark
               color="teal"
        >
          zapisz
        </v-btn>
      </v-card-actions>
    </v-card>


    <v-card class="mb-2">
      <v-card-title
          class="blue lighten-3 white--text py-2 mb-2"
      >
        Telefony
      </v-card-title>
      <v-card-text class="pb-0">
        <div v-if="customerMainPhone"
             class="mh-25vh mr-n4">
          <v-radio-group
              v-model="customerMainPhoneId"
              class="mt-0">
            <v-radio
                v-for="phone in customerPhonesForSelect"
                :key="phone.key"
                :label="phone.label"
                :value="phone.id"
                :disabled="phone.disabled"
            >
              <template v-slot:label>
                <strong
                    :class="{'blue--text':phone.primaryTextClass}"
                >
                  {{ phone.label }}
                </strong>

                <v-btn
                    v-if="!phone.disabled"
                    icon
                    @click.prevent="disablePhone(phone.id)"
                    :color="phonesToDisable.indexOf((phone.id))>-1 ? 'red' :''">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
            </v-radio>
          </v-radio-group>
        </div>
        <v-text-field
            v-model="newPhone"
            label="dodaj telefon"
            type="text"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
            class="float-right"
            @click.prevent="savePhoneChanges"
            :loading="loadingPhoneChanges"
            x-small
            dark
            color="teal"
        >
          zapisz
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import CustomersMixin from '@/mixins/CustomersMixin';
  import StatusesMixin from '@/mixins/StatusesMixin';
  import moment from 'moment';

  export default {
    name: 'CustomerBaseData',
    mixins: [
      CustomersMixin,
      StatusesMixin
    ],
    props: {
      customer: {
        type: Object,
        required: false
      }
    },
    computed: {
      customerStatuses () {
        if (!this.customer || !this.customer.statuses) return [];
        return [...this.customer.statuses].reverse();
      },
      moment () {
        return moment;
      }
    },
  };
</script>

<style scoped>
</style>
