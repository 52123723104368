import Vue from 'vue';
import Vuex from 'vuex';
import { parseMultipleData, parseSingleInMultipleData } from '@/utils/arrayParser';

Vue.use(Vuex);

export default {
  state: {
    orders: [],
    totalOrdersToRecallQuantity: null,
    localOrderExternalId: null
  },
  getters: {
    ORDERS: state => state.orders,
    ORDER: state => (id) => state.orders.find(el => el._id === id),
    TOTAL_ORDERS_TO_RECALL_QUANTITY: state => state.totalOrdersToRecallQuantity,
    LOCAL_ORDER_EXTERNAL_ID: state => state.localOrderExternalId,
  },
  mutations: {
    SET_ALL_ORDER: (state, payload) => state.orders = payload,
    SET_ORDERS: (state, payload) => {
      state.orders = parseMultipleData(state.orders, payload);
    },
    SET_ORDER: (state, payload) => state.orders = parseSingleInMultipleData(state.orders, payload),
    SET_TOTAL_ORDERS_TO_RECALL_QUANTITY: (state, payload) => state.totalOrdersToRecallQuantity = payload,
    /* *** */
    SET_LOCAL_ORDER_EXTERNAL_ID: (state, payload) => state.localOrderExternalId = payload,
  },
  actions: {
    setOrder ({ commit }, payload) {
      commit('SET_ORDER', payload);
    },
    setAllOrders ({ commit }, payload) {
      commit('SET_ALL_ORDER', payload);
    },
    setOrders ({ commit }, payload) {
      commit('SET_ORDERS', payload);
    },
    setTotalOrdersToRecallQuantity ({ commit }, payload) {
      commit('SET_TOTAL_ORDERS_TO_RECALL_QUANTITY', payload);
    },

    setLocalOrderExternalId ({ commit }, payload) {
      commit('SET_LOCAL_ORDER_EXTERNAL_ID', payload);
    },
  }
};
