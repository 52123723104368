<template>
  <div class="wrap">
    <div class="new-order-wrap">
      <h2 class="my-2 blue--text">
        nowe zamówienie
        <!--        <pre>
        {{ newOrder.customerId }}s
        {{ newOrder.customerEmailId }}s
        {{ newOrder.customerEmailString }}s
          </pre>-->
        <!--<span class="black&#45;&#45;text">
          {{ newOrder.customerEmailString }}
        </span>-->
        <!--<span class="black&#45;&#45;text" v-if="newOrder.address===0||newOrder.newAddress.email">
          email: {{ newOrder.newAddress.email }}
        </span>
        <span class="black&#45;&#45;text" v-else-if="customerMainEmail">
          email: {{ customerMainEmail.email }}
        </span>-->
      </h2>

      <v-row v-if="notAppliedPromoCode">
        promokod nie działa
      </v-row>
      <v-row v-else>
        <v-col>
          <v-text-field
              v-model="newOrder.percentDiscount"
              label="rabat"
              type="number"
              min="0"
              step="1"
              max="100"
          />
        </v-col>
        <v-col>
          <v-text-field
              label="Kod rabatowy"
              v-model="promoCodeCode"
          />
        </v-col>
        <v-col>
          <v-btn
              class="mt-3"
              @click.prevent="validatePromoCode"
              :disabled="!newOrder.promoCodeIsActive || promoCodeCode.length < 1"
          >
            sprawdź
          </v-btn>
        </v-col>
        <v-col>
          <v-checkbox
              label="włącz kod"
              v-model="newOrder.promoCodeIsActive"
          />
        </v-col>
      </v-row>

      <v-divider/>

      <v-combobox
          v-if="productsMapped.length > 0"
          v-model="selectedProducts"
          :items="productsMapped"
          item-disabled="disabled"
          label="produkty"
          multiple
          chips
          item-text="title"
          deletable-chips
      />

      <div
          v-for="product in newOrder.products"
          :key="`product${product.id}`"
      >
        <div
            class="d-flex justify-space-between align-center"
        >
          <div>
            <h4 class="primary--text white-space-nowrap overflow-hidden mr-4">
              <small class="grey--text">{{ product.id }}</small>
              {{ product.title }}
            </h4>
          </div>
          <div
              class="d-flex"
          >
            <v-text-field
                x-small
                type="number"
                step="1"
                label="iłość"
                class="mr-2"
                v-model.number="product.quantity"
                @change="calcProductsPricesFromProductsChange"
                @input="calcProductsPricesFromProductsChange"
            />
            <v-text-field
                step="any"
                type="number"
                label="cena"
                class="mr-2"
                v-model="product.priceSelling"
                @change="calcProductsPricesFromProductsChange"
                @input="calcProductsPricesFromProductsChange"
            />
            <v-text-field
                readonly
                label="wartość"
                v-model="product.priceTotal"
            />
          </div>
        </div>

        <v-divider/>
      </div>
    </div>

    <div class="new-order-address-wrap d-flex pt-2">

      <v-radio-group v-model="newOrder.address"
                     class="mt-0">
        <h3 class="info--text">Adres</h3>
        <v-row>
          <v-col>
            <v-text-field
                x-small
                step="1"
                label="szukaj"
                class="mr-2"
                v-model="addressSearchString"
                v-if="customer && customer.addresses && customer.addresses.length > 3"
            />
            <div v-if="customerAddresses"
                 class="mh-50vh">
              <v-radio
                  v-for="address in customerAddresses"
                  :key="address.id"
                  :value="address"
                  :disabled="address.disabled"
                  class="_addressRadio"
              >
                <template v-slot:label>
                  <p>
                    <small>{{ address.firstName }} {{ address.lastName }}</small>
                    <br>
                    <b>{{ address.phone }}</b>
                    <br>
                    <strong class="info--text">{{ address.email }}</strong>
                    <br>
                    {{ address.address }},
                    {{ address.postCode }}
                    {{ address.city }}
                    <template v-if=" COUNTRY(address.country)">
                      {{ COUNTRY(address.country).title }}
                    </template>
                    <br>
                    <span class="_dhlPop"
                          v-if="address.dhlPopPoint">
                      DHL POP: {{ address.dhlPopPoint }}
                    </span>
                    <span class="_dpdPop"
                          v-if="address.dpdPickupPoint">
                      DPD Pickup: {{ address.dpdPickupPoint }}
                    </span>
                    <span class="_inPost"
                          v-else-if="address.inPostPoint">
                      InPost: {{ address.inPostPoint }}
                    </span>
                    <span class="_dpd"
                          v-else>
                      <small>Dostawa:</small> <strong>DPD</strong>
                    </span>
                    <span v-if="address.company">
                      Nazwa firmy: {{ address.company }}
                    </span>
                    <span v-if="address.vatNumber">
                      Nip: {{ address.vatNumber }}
                    </span>
                  </p>
                </template>
              </v-radio>
            </div>
          </v-col>

          <v-col>
            <v-radio
                :value="0"
                label="nowy adres"
            />

            <v-row>
              <v-col>
                <v-text-field
                    label="email"
                    type="email"
                    v-model="newOrder.newAddress.email"
                />
              </v-col>
              <v-col>
                <v-text-field
                    :label="`telefon ${newOrder.newAddress.phone}`"
                    @input="checkIsPhone"
                    @change="checkIsPhone"
                    v-model="newOrder.newAddress.phone"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="imię"
                    v-model="newOrder.newAddress.firstName"
                />
              </v-col>
              <v-col>
                <v-text-field
                    label="nazwisko"
                    v-model="newOrder.newAddress.lastName"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-combobox
                    v-if="COUNTRIES.length > 0"
                    v-model="newOrder.country"
                    :items="COUNTRIES"
                    label="kraj"
                    item-text="title"
                />
              </v-col>
              <v-col>
                <v-text-field
                    label="miasto"
                    v-model="newOrder.newAddress.city"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="adres"
                    v-model="newOrder.newAddress.address"
                />
              </v-col>
              <v-col>
                <v-text-field
                    label="kod pocztowy"
                    v-model="newOrder.newAddress.postCode"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="nazwa firmy"
                    v-model="newOrder.newAddress.company"
                />
              </v-col>
              <v-col>
                <v-text-field
                    label="nip"
                    v-model="newOrder.newAddress.vatNumber"
                />
              </v-col>
            </v-row>


          </v-col>

          <v-divider vertical/>

          <v-col>

            <h5>
              Dostatwa i płatność
            </h5>

            <v-row>
              <v-col>
                <v-combobox
                    v-if="PAYMENTS.length > 0"
                    v-model="newOrder.payment"
                    :items="PAYMENTS"
                    label="płatność"
                    item-text="title"
                    :item-disabled="isDisabledPayment"
                />
              </v-col>
              <v-col>
                <v-combobox
                    v-if="CARRIERS.length > 0"
                    v-model="newOrder.carrier"
                    :items="CARRIERS"
                    label="dostawa"
                    item-text="title"
                    :item-disabled="isDisabledCarrier"
                />
              </v-col>
            </v-row>

            <div v-if="newOrder.carrier&&newOrder.carrier.code === 'dhl_pop'">
              <treeselect
                  :multiple="false"
                  :async="true"
                  placeholder="wybierz punkt odbioru"
                  :load-options="getDhlPops"
                  v-model="newOrder.dhlPopPoint"
              />
            </div>
            <div v-if="newOrder.carrier&&newOrder.carrier.code === 'dpd_pop'">
              <treeselect
                  :multiple="false"
                  :async="true"
                  placeholder="wybierz punkt odbioru"
                  :load-options="getDpdPops"
                  v-model="newOrder.dpdPickupPoint"
              />
            </div>

            <div v-if="newOrder.carrier&&newOrder.carrier.code === 'in_post'">
              <treeselect
                  :multiple="false"
                  :async="true"
                  placeholder="wybierz paczkomat"
                  :load-options="getInPostPoints"
                  v-model="newOrder.inPostPoint"
              />
            </div>

            <v-checkbox
                v-model="newOrder.freeShipping"
                label="darmowa dostawa"
            />
            <v-divider class="mb-2"/>

            dostawa: {{ newOrder.priceShipping.toFixed(2) }} zł <br>
            suma produktów: {{ newOrder.priceProductsSum.toFixed(2) }} zł <br>
            do zapłaty: {{ newOrder.priceTotal.toFixed(2) }} zł <br>

            <br><br>
            bez rabatu: {{
              (newOrder.priceProductsSumWithoutDiscount + newOrder.priceShippingWithoutDiscount).toFixed(2)
            }}

            <!--              <pre>-->
            <!--              {{ newOrder.priceTotal }}-->
            <!--              {{ newOrder.priceProductsSum }}-->
            <!--              {{ newOrder.priceProductsSumWithoutDiscount }}-->
            <!--              </pre>-->
          </v-col>
        </v-row>
      </v-radio-group>
    </div>

    <v-snackbar
        v-model="hasErrors"
        :multi-line="true"
    >
      <p v-for="error in errors" :key="`error${error.key}`">
        {{ error.key }} - {{ error.msg }}
      </p>

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="errors = []"
        >
          zamknij
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
  import ProductsMixin from '@/mixins/ProductsMixin';
  import CountriesMixin from '@/mixins/CountriesMixin';
  import CarriersMixin from '@/mixins/CarriersMixin';
  import PaymentsMixin from '@/mixins/PaymentsMixin';

  import ApiDhl from '@/api/external/Dhl';
  import ApiDpd from '@/api/external/Dpd';
  import ApiPromoCodes from '@/api/PromoCodes';
  import ApiInPost from '@/api/external/InPost';
  import CustomersMixin from '@/mixins/CustomersMixin';
  import { mapGetters } from 'vuex';
  import ErrorsMixin from '@/mixins/ErrorsMixin';

  const apiDhl = new ApiDhl();
  const apiDpd = new ApiDpd();
  const apiInPost = new ApiInPost();
  const apiPromoCodes = new ApiPromoCodes();

  //todo parse parent data

  export default {
    name: 'OrderCreationForm',
    mixins: [
      ProductsMixin,
      CountriesMixin,
      CarriersMixin,
      PaymentsMixin,
      CustomersMixin,
      ErrorsMixin,
    ],
    props: {
      setCurrentOrder: {
        type: Function,
        required: true
      },
      parentOrderId: {
        type: String,
        required: false
      },
      customerIdProp: {
        type: String,
        required: false
      }
    },

    data: () => ({
      addressSearchString: '',

      selectedProducts: [],
      promoCodeCode: '',
      newOrder: {
        customerId: null,
        customerEmailId: null,
        customerEmailString: null,

        percentDiscount: 0,
        promoCodeCode: '',
        promoCodeRuleId: null,
        promoCodeIsActive: false,

        freeShipping: false,
        carrier: null,
        payment: null,

        priceTotal: 0,
        priceProductsSum: 0,
        priceShipping: 0,

        priceProductsSumWithoutDiscount: 0,
        priceShippingWithoutDiscount: 0,

        products: [],

        country: null,

        address: null,

        inPostPoint: '',
        dhlPopPoint: '',
        dpdPickupPoint: '',

        newAddress: {
          email: '',
          phone: '',

          firstName: '',
          lastName: '',

          address: '',
          postCode: '',
          city: '',

          company: '',
          vatNumber: '',
        }
      },
    }),

    computed: {
      ...mapGetters([
        'ORDER'
      ]),
      customer () {
        if (!this.customerIdProp) return null;
        const customer = this.CUSTOMER(this.customerIdProp);
        if (!customer) {
          this.getCustomer(this.customerIdProp);
          return;
        }

        return customer;
      },
      customerAddresses () {
        if (!this.customer) return [];

        let addresses = [...this.customer.addresses].splice(0, 50);
        addresses = addresses.reverse();
        if (this.addressSearchString.length > 0) {
          addresses = addresses.filter(a => {
            return a.phone.indexOf(this.addressSearchString) > -1 || a.email.indexOf(this.addressSearchString) > -1;
          });
        }
        return addresses.reverse();
      },

      payments () {
        return this.PAYMENTS.map(el => ({ ...el, disabled: true }));
      },

      isNewSelectedAddress () {
        return this.newOrder.address !== 0;
      },
      selectedAddressIsDhlPop () {
        return this.isNewSelectedAddress && !!this.newOrder.address.dhlPopPoint;
      },
      selectedAddressIsInPost () {
        return this.isNewSelectedAddress && !!this.newOrder.address.inPostPoint;
      },
      selectedAddressIsDhl () {
        return this.isNewSelectedAddress && !this.selectedAddressIsDhlPop && !this.selectedAddressIsInPost;
      },

      isDisabledCarrier: function () {
        return (item) => {
          if (this.isNewSelectedAddress) {
            if (this.selectedAddressIsDhlPop && item.code === 'in_post') {
              return true;
            }
            if (this.selectedAddressIsInPost && item.code === 'dhl_pop') {
              return true;
            }
            if (this.selectedAddressIsDhl && ['in_post', 'dhl_pop'].indexOf(item.code) > -1) {
              return true;
            }
          }
          if (!this.newOrder.country) return false;
          const country = this.newOrder.country;
          const payment = this.newOrder.payment;
          if (!payment) {
            return country.carriersPayments.findIndex(el => {
              return el.carrier === item._id;
            }) < 0;
          }

          return country.carriersPayments.findIndex(el => {
            return el.carrier === item._id && el.payment === payment._id;
          }) < 0;
        };
      },
      isDisabledPayment: function () {
        return ({ _id }) => {
          if (!this.newOrder.country) return false;
          const country = this.newOrder.country;
          const carrier = this.newOrder.carrier;
          if (!carrier) {
            return country.carriersPayments.findIndex(el => {
              return el.payment === _id;
            }) < 0;
          }

          return country.carriersPayments.findIndex(el => {
            return el.payment === _id && el.carrier === carrier._id;
          }) < 0;
        };
      },

      /* *** *** */
      notAppliedPromoCode () {
        return !!this.newOrder.promoCodeCode
            && !!this.appliedPromoCodeTitle
            && !!this.promoCodeRuleId;
      },
      /* * todo ** */
      productsMapped () {
        return this.PRODUCTS.map(p => {
          if (!p.bundle) {
            return p;
          }
          const subProducts = p.bundle.map(b => {
            return b.title;
          });
          const title = `${p.title} (${subProducts.join(', ')})`;
          return {
            ...p,
            title,
          };
        });
      },
    },
    watch: {
      'newOrder.inPostPoint' () {
        this.setInPostPointToAddress();
      },
      'newOrder.dhlPopPoint' () {
        this.setDhlPopPointToAddress();
      },
      'newOrder.dpdPickupPoint' () {
        this.setDpdPopPointToAddress();
      },
      'newOrder.percentDiscount' () {
        this.calcProductsPricesFromDiscountChange();
      },
      selectedProducts () {
        this.checkProducts();
      },

      'newOrder.freeShipping' () {
        this.checkPriceShipping();
      },
      'newOrder.payment' () {
        this.checkPriceShipping();
      },
      'newOrder.carrier' () {
        this.checkPriceShipping();
      },
      'newOrder.country' () {
        this.checkPriceShipping();
      },
      customerIdProp () {
        this.prepareAddress();
      },
      'newOrder.newAddress.email' () {
        // if (this.newOrder.address !== 0) return;
        // this.newOrder.customerEmailString = this.newOrder.newAddress.email;
        // this.newOrder.customerEmailId = null;
        this.prepareAddress();
      },
      'newOrder.address' () {
        this.prepareAddress();
      },
      customerMainEmail () {
        // if (this.newOrder.address === 0 || !this.customerMainEmail || !this.customerMainEmail) {
        //   this.newOrder.customerEmailString = this.newOrder.newAddress.email;
        //   this.newOrder.customerEmailId = null;
        // }
        // this.newOrder.customerEmailString = this.customerMainEmail.email;
        // this.newOrder.customerEmailId = this.customerMainEmail._id;
        this.prepareAddress();
      },
      newOrder: {
        deep: true,

        handler () {
          this.setCurrentOrder(this.newOrder);
        }
      },

    },
    mounted () {
      if (this.parentOrderId) {
        const parentOrder = this.ORDER(this.parentOrderId);
        this.newOrder.carrier = parentOrder.carrier;
        this.newOrder.address = this.customerAddresses.find(
            el => el.magentoAddressId === parentOrder.shippingAddressId
        );
      } else {
        if (
            this.customer &&
            this.customer.orders &&
            this.customer.orders.length > 0
        ) {
          // [...this.customer.orders].map(el => console.log(el.createdAt));
          const lastOrder = [...this.customer.orders].reverse()
              .find(el => {
                if (!el.carrier) return false;
                if (!el.shippingAddress) return false;
                return true;
              });
          if (lastOrder) {
            const address = this.customerAddresses.find(
                el => el.magentoAddressId === lastOrder.shippingAddressId
            );
            this.newOrder.carrier = this.CARRIER(lastOrder.carrier);
            this.newOrder.address = address;
          }
        }
      }
    },
    methods: {
      checkIsPhone (value) {
        let x = value.replace(/\D/g, '');
        if (x === null || x === undefined || x === false || x === '') { return ''; }
        if (value.indexOf('+') === 0) {
          x = `+${x}`;
        }

        this.newOrder.newAddress.phone = x;
      },

      setInPostPointToAddress () {
        this.newOrder.newAddress.inPostPoint = this.inPostPoint;
      },
      setDhlPopPointToAddress () {
        this.newOrder.newAddress.dhlPopPoint = this.dhlPopPoint;
      },
      setDpdPopPointToAddress () {
        this.newOrder.newAddress.dpdPickupPoint = this.dpdPickupPoint;
      },
      checkProducts () {
        let products = this.selectedProducts;

        for (let [k, v] of Object.entries(this.newOrder.products)) {
          const index = products.findIndex(el => v.id === el.id);
          if (index < 0) delete this.newOrder.products[k];
        }

        this.newOrder.products = this.newOrder.products.filter(n => n);

        for (let v of products) {
          const index = this.newOrder.products.findIndex(el => v.id === el.id);
          if (index > -1) continue;

          let currentObject = {
            ...v,
            quantity: v.quantity ?? 1,
            priceSelling: v.priceSelling ?? v.price - ((v.price * this.newOrder.percentDiscount) / 100),
          };
          currentObject.priceTotal = currentObject.priceSelling * currentObject.quantity;
          currentObject.priceTotalWithoutDiscount = currentObject.price * currentObject.quantity;
          this.newOrder.products.push(currentObject);
        }
        this.calcPriceProductsSum();
      },
      /* *** prices *** */
      calcProductsPricesFromDiscountChange () {
        for (let v of this.newOrder.products) {
          v.priceSelling = (v.price - ((v.price * this.newOrder.percentDiscount) / 100)); //* v.quantity;
          v.priceTotal = v.priceSelling * v.quantity;
        }
        this.calcPriceProductsSum();
      },
      calcProductsPricesFromProductsChange () {
        for (let v of this.newOrder.products) {
          v.priceTotal = v.priceSelling * v.quantity;
          v.priceTotalWithoutDiscount = v.price * v.quantity;
        }
        this.calcPriceProductsSum();
      },

      calcPriceProductsSum () {
        this.newOrder.priceProductsSum = this.newOrder.products.reduce((accumulator, el) => accumulator + el.priceTotal, 0);
        this.newOrder.priceProductsSumWithoutDiscount = this.newOrder.products.reduce((accumulator, el) => accumulator + el.priceTotalWithoutDiscount, 0);
        this.calcPriceTotal();
      },

      checkPriceShipping () {
        if (!this.newOrder.country
            || !this.newOrder.carrier
            || !this.newOrder.payment
        ) {
          this.newOrder.priceShipping = 0;
          this.calcPriceTotal();
          return;
        }

        const carrierPayment = this.newOrder.carrier.payments
            .find(el => el.payment === this.newOrder.payment._id && el.country === this.newOrder.country._id);

        if (!carrierPayment) {
          this.newOrder.priceShipping = 0;
          this.calcPriceTotal();
          return;
        }

        this.newOrder.priceShippingWithoutDiscount = carrierPayment.price;
        if (this.newOrder.freeShipping) {
          this.newOrder.priceShipping = 0;
          this.calcPriceTotal();
          return;
        }

        this.newOrder.priceShipping = carrierPayment.price;
        this.calcPriceTotal();

      },

      calcPriceTotal () {
        this.newOrder.priceTotal = this.newOrder.priceShipping + this.newOrder.priceProductsSum;
        this.setCurrentOrder(this.newOrder);
      },

      /* *** address *** */
      prepareAddress () {

        this.newOrder.customerId = this.customerIdProp;

        if (this.newOrder.address === 0 || !this.customerMainEmail || !this.customerMainEmail) {
          this.newOrder.customerEmailString = this.newOrder.newAddress.email;
          this.newOrder.customerEmailId = null;
        } else {
          this.newOrder.customerEmailString = this.customerMainEmail.email;
          this.newOrder.customerEmailId = this.customerMainEmail._id;
        }

        if (!this.newOrder.address) return;
        this.newOrder.country = this.COUNTRY(this.newOrder.address.country);
        this.newOrder.dhlPopPoint = this.newOrder.address.dhlPopPoint;
        this.newOrder.inPostPoint = this.newOrder.address.inPostPoint;
        this.newOrder.dpdPickupPoint = this.newOrder.address.dpdPickupPoint;

        this.setCurrentOrder(this.newOrder);
      },
      getDhlPops ({ searchQuery, callback }) {
        if (searchQuery.length < 3) return callback(null, []);

        apiDhl.getDhlPops(searchQuery)
            .then(response => callback(null, response));

      },
      getDpdPops ({ searchQuery, callback }) {
        if (searchQuery.length < 3) return callback(null, []);

        apiDpd.getDpdPops(searchQuery)
            .then(response => callback(null, response));
      },
      getInPostPoints ({ searchQuery, callback }) {
        if (searchQuery.length < 3) return callback(null, []);
        apiInPost.getInPostPoints(searchQuery)
            .then(response => callback(null, response))
            .catch(() => callback(null, []));
      },

      /* *** *** */
      validatePromoCode () {
        apiPromoCodes.getPerCode(this.promoCodeCode)
            .then(response => {
              if (response.errors) return this.errors = response.errors;

              this.addPromoCodeToOrder(response);
            });
      },
      checkIsValidPromoCodeDates (promoCode) {
        const startDateString = promoCode.rule?.dateStart;
        const endDateString = promoCode.rule?.dateEnd;
        if (!startDateString && !endDateString) return true;

        const nowDate = +new Date();
        const startDate = +new Date(`${startDateString} 00:00`);
        const endDate = +new Date(`${endDateString} 23:59`);

        if (startDateString && !endDateString) {
          return nowDate >= startDate;
        }
        if (!startDateString && endDateString) {
          return nowDate < endDate;
        }

        return nowDate >= startDate && nowDate < endDate;
      },
      addPromoCodeToOrder (promoCode) {
        if (!promoCode.rule.byPercent) {
          this.errors = [];
          return this.errors.push({ key: 'Błąd', msg: 'kod nie jest procentowy' });
        }

        if (!this.checkIsValidPromoCodeDates(promoCode)) {
          this.errors = [];
          return this.errors.push({ key: 'Błąd', msg: 'kod jest nieaktywny' });
        }

        this.newOrder.promoCodeCode = promoCode.code;
        this.newOrder.percentDiscount = promoCode.rule.discountAmount;
        this.newOrder.promoCodeRuleId = promoCode.ruleId;
        this.appliedPromoCodeTitle = `${promoCode.rule.title} - ${promoCode.rule.discountAmount}%`;
      },
      removePromoCode () {
        this.newOrder.promoCodeCode = '';
        this.appliedPromoCodeTitle = '';
        this.promoCodeRuleId = null;
      }
    }
  };
</script>

<style scoped lang="scss">
  ._addressRadio {

    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }

    ._inPost {
      color: #ffcd00;
    }

    ._dhlPop {
      color: #ffcc01;
    }

    ._dhl, ._dpdPop, ._dpd {
      color: #d70512;
    }
  }
</style>
