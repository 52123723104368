import axios from 'axios';
import store from '@/store';

export default class {
  baseUrl = '/api/calls';

  /* *** get active current users call *** */
  // getCurrent = () => new Promise(() => {
  //   axios.get(`${this.baseUrl}/current`)
  //     .then(({ data }) => store.dispatch('setCurrentCall', data.currentCall));
  // });
  getCurrent = async () => {
    const { data } = await axios.get(`${this.baseUrl}/current`);
    if(data && data.currentCall) {
      store.dispatch('setCurrentCall', data.currentCall);
    }
    // .then(({ data }) => store.dispatch('setCurrentCall', data.currentCall));
  };

  /* *** start call *** */
  post = async (params) => {
    await axios.post(this.baseUrl, params)
      .then(({ data }) => store.dispatch('setCurrentCall', data.currentCall));
  };
  /* *** stop call *** */
  patch = async (_id) => {
    await axios.patch(`${this.baseUrl}/${_id}`)
      .then(({ data }) => store.dispatch('setCurrentCall', data.currentCall));
  };
    /* *** toggle hold call *** */
    toggleHold = async (_id) => {
        await axios.patch(`${this.baseUrl}/toggle-hold/${_id}`)
            .then(({ data }) => store.dispatch('setCurrentCall', data.currentCall));
    };
  /* *** finish and summary call *** */
  put = async (_id, params) => {
    const { data } = await axios.put(`${this.baseUrl}/${_id}`, params);
    store.dispatch('clearCurrentCall');
    if (data && data.localOrderExternalId) {
      store.dispatch('setLocalOrderExternalId', data.localOrderExternalId);
    }
  };
}
