/* *** Polyfills *** */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

/* *** Vuetify *** */
import vuetify from '@/plugins/vuetify'; // path to vuetify export

/* *** moment *** */
// import moment from 'moment';

// Vue.prototype.moment = moment;
const moment = require('moment');
Vue.use(require('vue-moment'), {
  moment
});

/* *** Datetime picker *** */
import { Datetime } from 'vue-datetime';

Vue.use(Datetime);
import 'vue-datetime/dist/vue-datetime.css';

Vue.component('DateTimePicker', Datetime);

/* *** Treeselect *** */
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

Vue.component('Treeselect', Treeselect);

/* *** luxon *** *//*
import { Settings } from 'luxon';
//set to display dates for English language
Settings.defaultLocale = 'pl';
Settings.defaultLocale = 'pl';
import VueLuxon from "vue-luxon";
Vue.use(VueLuxon);
Vue.use(VueLuxon, {
  input: {
    zone: "utc",
    format: "iso"
  },
});*/
/* *** socket.io *** */
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';

let socket = null;
/** Socket IO Client - Store in Vuex State for use in components */
if (process.env.NODE_ENV === 'development') {
  socket = io('http://localhost:3001', { transports: ['websocket'] }); // todo
} else {
  socket = io('/', { transports: ['polling'] });
}
Vue.use(VueSocketIOExt, socket);
store.dispatch('assignSocket', socket);
// store.dispatch('assignSocket', Vue.prototype.$socket.client);

/** Check for auth token on refresh and set authorization header for incoming requests */
import { setAuthToken } from './utils/authToken';

if (localStorage.authToken) {
  setAuthToken(localStorage.authToken);
} else {
  setAuthToken(null);
}

/* *** Axios *** */
import axios from 'axios';

/** Axios Request Intercept */
axios.interceptors.request.use(
  (config) => config,
  (err) => Promise.reject(err)
);

/** Axios Response Intercept */
axios.interceptors.response.use(
  (response) => response,
  (err) => {
    // if (err.toString() === 'Cancel') {
    //   return Promise.reject({});
    // }
    if (err && err.response && err.response.status === 401) {
      localStorage.removeItem('authToken');
      store.dispatch('toggleAuthState', false);
      router.push({
        name: 'Login',
        params: { message: 'Session has expired, please login again' }
      });
    }
    return Promise.reject(err);
  }
);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
