import { render, staticRenderFns } from "./AdminStocks.vue?vue&type=template&id=28de607b&scoped=true&"
import script from "./AdminStocks.vue?vue&type=script&lang=js&"
export * from "./AdminStocks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28de607b",
  null
  
)

export default component.exports