<template>
  <div>
    <!-- admin actions    -->
    <v-card
        class="d-flex justify-space-between align-center py-1 px-3 mb-3"
        v-if="AUTH_USER.accountType===3">

      <h4>akcje admina</h4>
      <div class="d-flex">
        <v-btn
            @click.prevent="removeCustomerAssigned('assigned')"
            x-small
            class="warning darken-1 mr-2"
            dark
            v-if="!!customer.assigned"
        >
          usuń z "mój rekord"
        </v-btn>
        <v-btn
            @click.prevent="removeCustomerAssigned('owner')"
            x-small
            class=" darken-1 "
            dark
            v-if="!!customer.owner"
        >
          usuń z portfela
        </v-btn>
      </div>
    </v-card>

    <!-- customer data   -->
    <v-card
        class="d-flex justify-space-between align-center py-1 px-3 mb-2"
    >
      <div class="d-flex align-center">
        <h5
            class="white-space-nowrap mr-3"
            :class="{'red--text':customer.disabled, 'blue--text':!customer.disabled}"
        >
          {{ customer.firstName }}
          {{ customer.lastName }}
        </h5>

        <span class="mr-3"
              v-if="customerMainEmail"
        >
          {{ customerMainEmail.email }}
        </span>

        <span class="mr-3"
              v-if="customerMainPhone"
        >
          {{ customerMainPhone.phone }}
        </span>
      </div>

      <span v-if="customer.owner">
        w portfelu u <b>{{ customerOwner }}</b>
      </span>

      <div>
        <v-btn
            @click.prevent="showCustomerSegmentOverlay()"
            x-small
            class="blue-grey darken-1 mr-2"
            dark
            :loading="loadingSegment"
        >
          segment
        </v-btn>

        <v-btn
            @click.prevent="setIsVisibleCustomerOrderedProductOverlay(true)"
            x-small
            class="blue-grey darken-1 mr-2"
            dark
        >
          historia zamówień
        </v-btn>

        <v-btn
            @click.prevent="showPollData(true)"
            x-small
            class="blue-grey darken-1 mr-2"
            dark
            :loading="loaderCustomersPoll"
        >
          Dane z ankeity
        </v-btn>
        
        <v-btn
            @click.prevent="showCareClubData()"
            x-small
            class="care-club-btn"
            :loading="loadingCareClub"
        >
          Care Club
        </v-btn>
      </div>
    </v-card>

    <!-- call action   -->
    <v-card
        class="d-flex justify-space-between align-center py-1 px-3"
    >
      <div class="d-flex align-center justify-end">
        <strong class="mr-3">kampania</strong>
        <v-combobox
            v-if="CAMPAIGNS"
            v-model="selectedCampaign"
            :items="campaignsForSelect"
            item-text="title"
            item-value="_id"
            deletable-chips
            :rules="notEmptyFieldRules"
            :full-width="false"
            class="mt-0 mr-3 mb-n4 pt-1"
            :readonly="!!customer.owner"
        />
        <v-btn
            @click.prevent="makeCall()"
            x-small
            class="green accent-4"
            :dark="!IS_CURRENT_CALL_IN_PROGRESS"
            :disabled="IS_CURRENT_CALL_IN_PROGRESS || loadingCallBtn"
            :loading="loadingCallBtn"
        >
          zadzwoń
        </v-btn>
      </div>
      <div>
        <v-btn
            @click.prevent="setIsVisibleCustomerStatusChangerOverlay(true)"
            x-small
            class="orange darken-3 mr-3"
            :dark="!IS_CURRENT_CALL_IN_PROGRESS"
            :disabled="IS_CURRENT_CALL_IN_PROGRESS"
        >
          zmień status
        </v-btn>
        <v-btn
            v-if="customerOwner === null"
            @click.prevent="setCustomerOwned()"
            x-small
            class="primary lighten-3"
            :loading="loadingOwningCustomer"
            :disabled="loadingOwningCustomer"
        >
          dodaj do portfela
        </v-btn>
      </div>
    </v-card>

    <CustomerBaseStatus :customer="customer"/>

    <CustomerOrderedProductOverlay
        :is-visible="isVisibleCustomerOrderedProductOverlay"
        :set-is-visible="setIsVisibleCustomerOrderedProductOverlay"
        :customer="customer"
    />
    <CustomerSegmentOverlay
        :is-visible="isVisibleCustomerSegmentOverlay"
        :set-is-visible="setIsVisibleCustomerSegmentOverlay"
        :customer="customer"
    />
    <CustomerStatusChangerOverlay
        :is-visible="isVisibleCustomerStatusChangerOverlay"
        :set-is-visible="setIsVisibleCustomerStatusChangerOverlay"
        :customer="customer"
        :campaign="campaign"
        :parent-order-id="parentOrderId"
    />
    <CustomerCareClubModal
        :is-visible="isVisibleCustomerCareClub"
        :set-is-visible="setIsVisibleCustomerCareClub"
        :customer-id="customerId"/>
    
    <CustomerPollModal
        :is-visible="isVisiblePoll"
        :set-is-visible="setIsVisiblePollOverlay"
        :customer-id="customerId"/>

    <v-snackbar
        v-model="hasErrors"
        :multi-line="true"
    >
      <p v-for="(error, index) in errors" :key="`error${error.key}`"
         :class="{'mb-0': index===errors.length-1}">
        {{ error.key }} - {{ error.msg }}
      </p>

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="errors = []"
        >
          zamknij
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import CustomersMixin from '@/mixins/CustomersMixin';
  import StatusesMixin from '@/mixins/StatusesMixin';
  import CustomerOrderedProductOverlay from '@/components/CustomerOrderedProductOverlay';
  import CustomerSegmentOverlay from '@/components/CustomerSegmentOverlay';
  import CustomerCareClubModal from '@/components/CustomerCareClubModal';
  import { mapGetters } from 'vuex';
  import ErrorsMixin from '@/mixins/ErrorsMixin';
  import ApiCalls from '@/api/Calls';
  import CustomerStatusChangerOverlay from '@/components/CustomerStatusChangerOverlay';
  import CampaignsMixin from '@/mixins/CampaignsMixin';
  import FormValidatorMixin from '@/mixins/FormValidatorMixin';
  import ApiCustomers from '@/api/Customers';
  import CustomerBaseStatus from '@/components/CustomerBaseStatus';
  import CustomerPollModal from '@/components/CustomerPollModal';

  const apiCustomers = new ApiCustomers;
  const apiCalls = new ApiCalls();
  export default { // todo customer calls history
    name: 'CustomerBaseActions',
    mixins: [
      ErrorsMixin,
      CustomersMixin,
      StatusesMixin,
      FormValidatorMixin,
      CampaignsMixin
    ],
    components: {
      CustomerSegmentOverlay,
      CustomerOrderedProductOverlay,
      CustomerStatusChangerOverlay,
      CustomerCareClubModal,
      CustomerBaseStatus,
      CustomerPollModal,
    },
    props: {
      customer: {
        type: Object,
        required: false
      },
      campaign: {
        type: Object,
        required: false
      },
      parentOrderId: {
        type: String,
        required: false
      },
    },
    data: () => ({
      isVisibleCustomerSegmentOverlay: false,
      isVisibleCustomerOrderedProductOverlay: false,
      isVisibleCustomerStatusChangerOverlay: false,
      isVisibleCustomerCareClub: false,
      selectedCampaign: null,
      loadingCallBtn: false,
      loadingCareClub: false,
      loadingOwningCustomer: false,
      loadingSegment: false,
      isVisiblePoll: false,
      loaderCustomersPoll: false
    }),
    computed: {
      ...mapGetters([
        'IS_CURRENT_CALL_IN_PROGRESS',
        'AUTH_USER',
        'CURRENT_CALL',
        'USER',
      ]),
      customerStatuses () {
        if (!this.customer || !this.customer.statuses) return [];
        return [...this.customer.statuses].reverse();
      },

      /* *** *** */
      campaignsForSelect () {
        return this.CAMPAIGNS.filter(el => !el.isHidden);
      },

      /* *** *** */
      customerOwner () {
        if (!this.customer?.owner) {
          return null;
        }

        if (typeof this.customer.owner === 'string') {
          return this.USER(this.customer?.owner)?.email || 'nieznany użytkownik';
        }

        return this.customer.owner;
      }
    },
    watch: {
      campaign () {
        this.selectedCampaign = this.campaign;
      }
    },
    mounted () {
      this.selectedCampaign = this.campaign;
      if (!!this.customer.owner) {
        const campaign = this.CAMPAIGNS.find(({ uuid }) => uuid === 'Owner');
        this.selectedCampaign = campaign;
      }
    },
    methods: {
      showCustomerOrderedProducts () {

      },
      setIsVisibleCustomerOrderedProductOverlay (val = false) {
        this.isVisibleCustomerOrderedProductOverlay = val;
      },
      setIsVisiblePollOverlay (val = false) {
        this.isVisiblePoll = val;
      },
      setIsVisibleCustomerSegmentOverlay (val = false) {
        this.isVisibleCustomerSegmentOverlay = val;
      },
      setIsVisibleCustomerStatusChangerOverlay (val = false) {
        this.isVisibleCustomerStatusChangerOverlay = val;
      },
      setIsVisibleCustomerCareClub (val = false) {
        this.isVisibleCustomerCareClub = val;
      },
      makeCall () {
        if (!this.customerMainPhone || !this.customerMainPhone._id) {
          this.errors.push({
            key: 'błąd',
            msg: 'najpierw wybierz i zapisz numer klienta'
          });
          return;
        }
        if (this.CURRENT_CALL) {
          this.errors.push({
            key: 'błąd',
            msg: 'najpierw zakończ poprzednią rozmowę'
          });
          return;
        }

        const orderId = this.$route.params.orderId;
        let params = {
          campaignExternalId: this.selectedCampaign && this.selectedCampaign.uuid ? this.selectedCampaign.uuid : this.campaign.uuid,// todo backend
          customer: this.customer._id,
          customerPhone: this.customerMainPhone._id,
        };
        if (orderId) params.order = orderId;
        this.loadingCallBtn = true;
        apiCalls.post(params)
            .finally(() => this.loadingCallBtn = false);
      },

      removeCustomerAssigned (type) {
        console.log(type);
        apiCustomers.updateCustomerAssigned(this.customerId, type);
      },

      showCareClubData () {
        this.loadingCareClub = true;
        const customerId = this.customerId;
        if (!customerId) return;
        this.getCustomerCareClubData(this.customerId).then(() => {
          this.setIsVisibleCustomerCareClub(true);
          this.loadingCareClub = false;
        }).finally(() => this.loadingCareClub = false);
      },
      showPollData () {
        this.loaderCustomersPoll = true;
        const customerId = this.customerId;
        if (!customerId) return;
        this.getCustomerPollData(this.customerId).then(() => {
          this.setIsVisiblePollOverlay(true);
          this.loaderCustomersPoll = false;
        }).finally(() => this.loaderCustomersPoll = false);
      },
      showCustomerSegmentOverlay () {
        this.loadingSegment = true;
        const customerId = this.customerId;
        if (!customerId) return;
        this.getCustomerSegment(this.customerId).then(() => {
          this.setIsVisibleCustomerSegmentOverlay(true);
          this.loadingSegment = false;
        }).finally(() => this.loadingSegment = false);
      },

      setCustomerOwned () {
        this.loadingOwningCustomer = true;
        apiCustomers.setCustomerOwner(this.customerId).then(() => {
          this.loadingOwningCustomer = false;
        });
      }
    }
  };
</script>

<style scoped lang="scss">
  .care-club-btn {
    background: #fadc99 !important;
    color: #b66d00;
  }
</style>
